import React from 'react';
import { Link } from 'react-router-dom';
import Image from './common/image';
import Anchor from './common/anchor';
import Socials from './common/socials';

const Footer = ({ isGlobal }) => {
	const isLessThanXXL = window.matchMedia('(max-width: 1399.98px)').matches;
	const socials = [
		{
			name: 'main twitter account',
			className: 'fab fa-twitter',
			link: 'https://twitter.com/BcnGarden',
		},
		{
			name: 'discord',
			className: 'fab fa-discord',
			link: 'https://discord.io/bemfoundation',
		},
		{
			name: 'instagram',
			className: 'fab fa-instagram',
			link: 'https://www.instagram.com/bcngarden/',
		},
		{
			name: 'telegram channel',
			className: 'fab fa-telegram-plane',
			link: 'https://t.me/bcngarden',
		},
		{
			name: 'youtube channel',
			className: 'fab fa-youtube',
			link: 'https://www.youtube.com/@bcngarden',
		},
	];

	if (!isGlobal) return null;

	return (
		<footer style={{ paddingTop: isLessThanXXL ? '0' : '6rem' }}>
			<div className='my-footer position-relative'>
				<div>
					<Image
						fileName='home/footerGreen.png'
						className='position-absolute top-0 start-50 translate-middle d-none d-md-block'
					/>
				</div>
				<div className='container py-5'>
					<div className='row mx-sm-5 align-items-center'>
						<div className='col-md-3 text-center'>
							<Link to='/'>
								<Image
									fileName='logo-alt.png'
									className='w-75'
								/>
							</Link>
						</div>

						<div className='col-md-3 text-center mt-4'>
							<div className='mb-2'>
								<Anchor
									className={'text-white'}
									href={'https://bemfoundation.com'}
									content={'\u2022 BEM FOUNDATION'}
								/>
							</div>
							<div className='mb-2'>
								<Anchor
									className={'text-white'}
									href={'https://bcntoken.com'}
									content={'\u2022 BCN TOKEN'}
								/>
							</div>
							<div className='mb-2'>
								<Anchor
									className={'text-white'}
									href={'https://timtoken.io'}
									content={'\u2022 TIM TOKEN'}
								/>
							</div>
						</div>

						<div className='col-md-3 text-center mt-4'>
							<h4 className='mb-3'>Usefull Links:</h4>
							<div className='mb-2'>
								<Anchor
									className={'text-white'}
									href={
										'https://sunswap.com/#/home?tokenAddress=TAoA331n3iKDkR62kAZ4H2n3vNL7y3d8x9&type=swap'
									}
									content={'\u2022 Buy BCN'}
								/>
							</div>
							<div>
								<Anchor
									className={'text-white'}
									href={
										'https://sunswap.com/#/home?tokenAddress=TQH4JFjzGnWswNeaCxFJi3pSqmsvTXULY8&type=swap'
									}
									content={'\u2022 Buy TIM'}
								/>
							</div>
						</div>

						<div className='col-md-3 text-center mt-4'>
							<h4 className='mb-3'>Social Networks</h4>
							<Socials
								data={socials}
								navClass='justify-content-center'
								iconsClass={'socialIcons me-2'}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='my-footer-bottom py-1'>
				<div className='container'>
					<div className='row'>
						<div className='col-12 text-center'>
							<span>
								bcngarden.io © 2023. All Rights Reserved.
							</span>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
