import React, { useState } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { IdleTimerProvider } from 'react-idle-timer';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import config from './config';
import generateRoutes, { getIsGlobal } from './routes/appRoutes';
import MainContext from './context/mainContext';
import http from './services/httpService';
import useInitData from './hooks/useInitData';
import useUserData from './hooks/useUserData';
import useWatchPrice from './hooks/useWatchPrice';
import useAllDashboardResources from './hooks/useAllDashboardResources';
// import ScrollToTopBtn from './components/common/scrollToTopBtn';
import ScrollToTop from './components/common/scrollToTop';
import UnderMaintenance from './components/underMaintenance';
import './App.css';
import NavBar from './components/navbar';
import Footer from './components/footer';

function App() {
	const { protectedRoutes, routes } = generateRoutes();

	const location = useLocation();
	const isGlobal = getIsGlobal(location.pathname);

	const [initData, setInitData] = useInitData();
	const [userData, setUserData] = useUserData();
	const [UIData, setUIData] = useState({
		dashboardSpacer: config.dashboardSpacer,
		dashboardSpacerMD: config.dashboardSpacerMD,
	});
	// const [dashboardBlogs] = useAllDashboardBlogs();
	const dashboardBlogs = [];

	const [resources] = useAllDashboardResources();

	const handleUserDataChanged = (data) =>
		setUserData((prevData) => ({ ...prevData, ...data }));
	const handleUIDataChanged = (data) =>
		setUIData((prevData) => ({ ...prevData, ...data }));
	const handleInitDataChanged = (data) =>
		setInitData((prevData) => ({ ...prevData, ...data }));

	useWatchPrice(setInitData);

	const handleLogout = () => {
		if (http.getCurrentUser()) http.logout();
	};
	const handleScroll = async (el) => {
		if (window.matchMedia('(max-width: 992px)').matches) {
			const myCollapse = document.getElementById(
				'navbarSupportedContent'
			);
			await new bootstrap.Collapse(myCollapse, { show: false });
		}

		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const myNav = document.getElementById('myNav');
		const yOffset = -myNav.offsetHeight;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
	};

	// console.log('initData :>> ', initData);
	// console.log('userData :>> ', userData);
	// console.log('UIData :>> ', UIData);

	const handleIdle = (event) => {
		// console.log('its idle');
		const user = http.getCurrentUser();
		if (!user) return;
		if (!user.exp || Date.now() > user.exp * 1000) http.logout();
	};

	const handleActive = async (event) => {
		// console.log('its active');
		const user = http.getCurrentUser();
		if (!initData.sessionTime || !user) return;
		if (!user.exp) http.logout();
		if (Date.now() > user.iat * 1000 + config.reauthGap)
			try {
				console.log('calling reauth');
				const { headers } = await http.reauth();
				http.setJwt(headers['x-auth-token']);
			} catch (ex) {
				console.log(ex);
			}
	};

	return (
		<MainContext.Provider
			value={{
				initData,
				userData,
				UIData,
				dashboardBlogs,
				resources,
				onLogout: handleLogout,
				onInitDataChanged: handleInitDataChanged,
				onUserDataChanged: handleUserDataChanged,
				onUIDataChanged: handleUIDataChanged,
				onHashLinkClick: handleScroll,
			}}>
			<ToastContainer position='bottom-right' theme='colored' />
			<IdleTimerProvider
				timeout={60000}
				crossTab={true}
				onIdle={handleIdle}
				onActive={handleActive}>
				<div className='App'>
					{/* <ScrollToTopBtn showBelow={250} /> */}
					{initData.underMaintenance === true ? (
						<UnderMaintenance />
					) : (
						<>
							<ScrollToTop />
							<NavBar isGlobal={isGlobal} />
							<Switch>
								{protectedRoutes}
								{routes}
								<Redirect to='/notFound' />
							</Switch>
							<Footer isGlobal={isGlobal} />
						</>
					)}
				</div>
			</IdleTimerProvider>
		</MainContext.Provider>
	);
}

export default App;
