import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import MainContext from '../../../context/mainContext';
import { capitalize } from 'lodash';
import { getState, getFruit } from '../../../utils/legends';
import { subString } from '../../../utils/helpers';
import Divider from '../../divider';
import Image from '../../common/image';
import CopyBtn from '../../common/copyBtn';
import Anchor from '../../common/anchor';

function MySeeds({ tab }) {
	const { userData, initData } = useContext(MainContext);
	const isLessThanMD = window.matchMedia('(max-width: 767.98px)').matches;
	const { charsDef } = initData;
	const { sprites } = userData;
	const seeds = Object.keys(charsDef);

	return (
		<>
			<Divider />
			<div className='row my-4'>
				<div className='col text-center'>
					<h4 className='fs-1'>My Seeds</h4>
					<h6>Total: {sprites.length} seeds</h6>
				</div>
			</div>

			<div className='container'>
				<div className='row'>
					{seeds.map((s, key) => (
						<div key={key} className='col-md-2 col-4 mb-4'>
							<div className='d-flex flex-column flex-md-row align-items-center mx-3'>
								<Image
									fileName={`icons/seeds/${s.toUpperCase()}.png`}
									height='45px'
									title={`${charsDef[s].name}`}
								/>

								<span className='mx-2 fs-3'>
									{
										sprites.filter(
											(sprite) => sprite.name === s
										).length
									}
								</span>
							</div>
						</div>
					))}
				</div>
			</div>

			<Divider file='08' className='my-3 my-md-5' full />

			{sprites.length > 0 && (
				<>
					<div className='row my-4'>
						{sprites.map((s, key) => (
							<div key={key} className='col-sm-4 mb-4'>
								<div className='card seedsCard'>
									<div className='card-body'>
										<div className='row align-items-center'>
											<div className='col-4 text-center'>
												<Link to={`/seeds/${s.name}`}>
													<Image
														fileName={`chars/${s.name}${s.state}.png`}
														className={`${
															isLessThanMD
																? 'w-75'
																: 'w-50'
														} shadowfilter`}
													/>
												</Link>
											</div>
											<div className='col-8'>
												<div className='d-flex flex-wrap align-items-center'>
													<span>Collection: </span>
													<span className='text-blue'>
														{charsDef[
															s.name
														].contractName.toUpperCase()}{' '}
													</span>
													<Image
														fileName={`icons/seeds/${s.name.toUpperCase()}.png`}
														height={'18px'}
														className='ms-1'
													/>{' '}
												</div>
												<div>
													Name:{' '}
													{s.privateName.toUpperCase()}
												</div>
												<div>
													Group: {capitalize(s.group)}
												</div>
												<div>
													Class:{' '}
													{capitalize(
														charsDef[s.name].class
													)}
												</div>

												<div>
													State:{' '}
													{getState(s.state, s.group)}
												</div>
												<div>Level: {s.level} </div>
												<div>
													Fruit:{' '}
													{capitalize(
														getFruit(s.name)
													)}{' '}
												</div>
												<div>
													Token ID: {s.tokenId}{' '}
												</div>
												<div className='d-flex align-items-center'>
													<span>Is Planted: </span>
													<Image
														fileName={`${s.planted}.png`}
														height={'18px'}
														className='ms-1'
													/>{' '}
												</div>
												<div>
													Contract:{' '}
													<Anchor
														href={`https://tronscan.org/#/token721/${
															charsDef[s.name]
																.contract
														}`}
														target='_blank'
														content={subString(
															charsDef[s.name]
																.contract,
															5,
															3
														)}
														className='text-link-color'
													/>
													<CopyBtn
														content={
															charsDef[s.name]
																.contract
														}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
					<Divider file='01' />
				</>
			)}
		</>
	);
}

export default MySeeds;
