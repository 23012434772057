export default class XP {
	static getLevel(xp) {
		const level = parseInt(Math.cbrt(xp));

		const xpInThisLevel = xp - level ** 3;
		const xpNeeded = (level + 1) ** 3 - level ** 3;
		return {
			level,
			barText: `${parseInt(xpInThisLevel)} / ${xpNeeded}`,
			barScale: xpInThisLevel / xpNeeded,
		};
	}

	static getWarehouseLevel(xp) {
		return parseInt(parseInt(Math.cbrt(xp)) / 10);
	}

	static setRank(n) {
		if (n === 1) return '🥇';
		if (n === 2) return '🥈';
		if (n === 3) return '🥉';
		return n;
	}
}
