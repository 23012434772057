import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import MainContext from '../../context/mainContext';
import useElemSize from '../../hooks/useElemSize';
import Image from '../common/image';
import XP from '../../utils/xp';
import { subString } from './../../utils/helpers';

const Summary = (props) => {
	const { userData, initData } = useContext(MainContext);
	const [ref] = useElemSize('summary');

	return (
		<div
			ref={ref}
			id='userSummary'
			className='card summaryCard mb-3 mb-md-0'>
			<div className='card-body'>
				<div className='text-center mb-2'>
					<Link to='/'>
						<Image
							className='w-75 d-none d-md-block mx-auto'
							fileName={`logo.png`}
						/>
					</Link>
				</div>

				<div className='d-flex align-items-center justify-content-evenly my-3'>
					<div className='d-flex flex-xxl-row flex-column align-items-center'>
						<div>
							<Image
								height={'30px'}
								fileName={`icons/coins/bcn.png`}
								className='mx-1 shadowfilter'
							/>
						</div>
						<div className='text-center'>
							<div>
								<span style={{ fontSize: '14px' }}>
									{initData.bcn_price}
								</span>
								<span
									className='ms-1'
									style={{ fontSize: '9px' }}>
									trx
								</span>
							</div>
							<div style={{ fontSize: '11px' }}>
								($
								{(
									initData.bcn_price / initData.usdt_price
								).toFixed(6)}
								)
							</div>
						</div>
					</div>

					<div className='d-flex flex-xxl-row flex-column align-items-center'>
						<div>
							<Image
								height={'35px'}
								fileName={`icons/coins/tim.png`}
								className='shadowfilter'
							/>
						</div>
						<div className='text-center'>
							<div>
								<span style={{ fontSize: '14px' }}>
									{initData.tim_price}
								</span>
								<span
									className='ms-1'
									style={{ fontSize: '9px' }}>
									trx
								</span>
							</div>
							<div style={{ fontSize: '11px' }}>
								($
								{(
									initData.tim_price / initData.usdt_price
								).toFixed(6)}
								)
							</div>
						</div>
					</div>
				</div>

				<Image className={'w-100 mb-3'} fileName={'divider/03.png'} />
				<div className='d-flex justify-content-center align-items-center'>
					<div className='me-2 position-relative'>
						<div className='position-absolute top-50 start-50 translate-middle'>
							<span className='fs-4 fw-bolder'>
								{XP.getLevel(userData.farmer.totalXP).level}
							</span>
						</div>
						<Image height={'55px'} fileName={`level.png`} />
					</div>
					<div>
						<div>{userData.username}</div>
						<div>{userData.uid}</div>
						<div className='text-break'>{userData.email}</div>
						<div>{subString(userData.wallet)}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Summary;
