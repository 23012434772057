import React from 'react';
import { Link } from 'react-router-dom';
import Image from './common/image';

const NotFound = () => {
	return (
		<main className='container'>
			<div className='row my-5'>
				<div className='col-md-8 offset-md-2 col text-center'>
					<Image className='w-75 py-4' fileName={'404.png'} />
					<p className='fs-4'>
						You weren't supposed to see this... it was meant to be a
						surprise!
					</p>
					<Link className='nav-link' to='/'>
						<button type='button' className='rectBtn'>
							Take me back home!
						</button>
					</Link>
				</div>
			</div>
		</main>
	);
};

export default NotFound;
