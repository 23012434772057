import React from 'react';
import Modal from 'react-bootstrap/Modal';

function ConfirmModal({ onClose, show, title, body, btns = true }) {
	return (
		<Modal centered show={show} onHide={onClose} className='myModal'>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{body}</Modal.Body>
			{btns && (
				<Modal.Footer className='d-flex justify-content-evenly align-items-center'>
					<button onClick={() => onClose(false)} className='rectBtn'>
						Cancel
					</button>
					<button onClick={() => onClose(true)} className='rectBtn'>
						Confirm
					</button>
				</Modal.Footer>
			)}
		</Modal>
	);
}

export default ConfirmModal;
