import React from 'react';
import XP from '../../../utils/xp';
import Image from '../../common/image';

const RankingTable = ({ data }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>#</th>
						<th>User Name</th>
						<th>XP</th>
						<th>Level</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr key={key}>
							<td>
								{d.no < 4 ? (
									<Image
										fileName={`icons/ranks/0${d.no}.png`}
										height='25px'
									/>
								) : (
									d.no
								)}
							</td>
							<td>{d.username}</td>
							<td>{parseInt(d.totalXP * 10) / 10}</td>
							<td>{XP.getLevel(d.totalXP).level}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default RankingTable;
