import React, { Fragment } from 'react';
import HeaderImage from './../ingredients/headerImage';

const PrivacyPolicy = ({ tab }) => {
	return (
		<Fragment>
			<HeaderImage fileName={`headers/${tab.id}.jpg`} />
			<div className='row'>
				<div className='col-12'>
					<h1 className='text-center'>Privacy Policy </h1>
					<h3 className='mt-5'>Introduction</h3>
					<p>
						In these Market Maker Plan General Terms of Use
						("Terms"), "MMP", "we" and "us" refers MMP.WORLD and we
						own and operate the website https://mmp.world/ ("the
						Site") which acts as a front-end to the decentralized
						MMP ecosystem. These Terms apply to you ("You") as a
						user of the Site and MMP front-end, including all the
						products, services, tools and information made available
						on the Site. Please read these Terms carefully before
						using the Site. These Terms apply to any person
						accessing the Site and by using the Site you agree to be
						bound by them. If you don’t want to be bound by them,
						you should not access the Site. By using the Site in any
						capacity, you agree that you have read and understood
						these Terms.
					</p>
					<h3 className='mt-5'>Disclaimer & Risk Warning:</h3>
					<p>
						Crypto or any Financial Investment has its own risk.
						Crypto Market is highly volatile and have more risks
						than any other financial markets. Plan your investment
						wisely and take only the risks that are affordable to
						you. Even though the business model is genuine and
						stable, you have to understand that the market is
						controlled by the traders and users and it's not
						controlled by the founders or administrators of MMP
						ecosystem.
					</p>
					<p>
						By participating in any of the activities of MMP
						projects, you agree to take the risks and understand and
						accept that the founders or administrators or developers
						or any of the team members of Market Maker Plan are not
						responsible for any of the losses you may incur. You
						also accept and agree that the MMP.world or its founders
						or administrators or developers or any of the team
						members cannot be held liable (legal or illegal) for any
						of the losses you may incur by participating in the MMP
						projects. MMP and all its services are provided in as-is
						basis. If you do not accept this disclaimer, please do
						not participate in any of the services provided by MMP
						ecosystem.
					</p>
					<p>
						We do not guarantee that the site will be free of
						problems. This site has been programmed by human
						resources, there may be errors. But the BEM Foundation
						support team is constantly reviewing and updating.
					</p>
					<h3 className='mt-5'>Third-Party Links</h3>
					<p>
						The Site may contain hyperlinks or references to third
						party websites. Any such hyperlinks or references are
						provided for your information and convenience only. We
						have no control over third party websites and accept no
						legal responsibility for any content, material or
						information contained in them. The display of any
						hyperlink and reference to any third-party website does
						not mean that we endorse that third party's website,
						products or services. Your use of a third-party site may
						be governed by the terms and conditions of that
						third-party site.
					</p>

					<p>
						Your privacy is very important to us. Simply stated, our
						policy is to collect no personally identifiable
						information about you when you visit our website unless
						you actively choose to make such information available
						to us. This Privacy Statement generally describes how we
						collect, use and/or share information gathered by our
						websites.
					</p>
					<h3 className='mt-5'>Collection of Personal Data</h3>
					<p>
						when visiting our Site, some information is collected
						electronically or by technology. For example, when
						accessing the Internet, each computer is assigned a
						unique identifier called an IP address that identifies
						where information is coming from or where information
						should be sent.
					</p>
					<p>
						Along with your IP address, we collect information
						regarding which pages you visit on our site, from which
						page you came to our site, and the software you use to
						visit our site and its configuration. We might use your
						IP address to help diagnose problems with our server,
						and to administer our Site.
					</p>
					<p>
						We might use information from your IP address to help
						identify you and gather broad demographic information.
						None of that information is associated with you as an
						individual.
					</p>
					<h3 className='mt-5'>Usage of Personal Data</h3>
					<p>
						We may utilize Personal Data collected through
						aggregation services such as our BemChain project and
						MMP ecosystem to:
					</p>
					<ul>
						<li>
							consolidate your financial account information at
							one online location, understand what product or
							service maybe of interest to you, and present you
							with offers.
						</li>

						<li>
							develop and provide MMP facilities, products or
							services (whether made available by us or through
							us), including but not limited to:
						</li>

						<li>
							execute investments, MMP project, commercial or
							other transactions and clearing or reporting on
							these transactions;
						</li>

						<li>
							carry out research, planning and statistical
							analysis; or
						</li>

						<li>
							curate analytics information for the purposes of
							developing or improving our products, services,
							security, service quality, advertising strategies,
							identifying usage trends and determining the
							effectiveness of promotional campaigns;
						</li>

						<li>
							assess and process applications, instructions or
							requests from you or our customers; to allow you to
							apply for products or services (e.g., to prequalify
							for a new account, apply for a DEFI or Liquidity
							investment etc.) and evaluate your eligibility for
							such products or services;
						</li>

						<li>
							perform risk control, fraud detection and
							prevention, to comply with laws and regulations, and
							to comply with other legal process and law
							enforcement requirements;
						</li>

						<li>
							verify your identity and/or location (or the
							identity or location of your representative or
							agent) in order to allow access to your accounts,
							conduct online transactions and to maintain measures
							aimed at preventing fraud and protecting the
							security of accounts and Personal Data;
						</li>

						<li>
							allow you to utilize features within our Site by
							granting us access to information from your device
							such as contact lists, or geo-location when you
							request certain services;
						</li>
						<li>
							respond to your inquiries and fulfill your requests;
						</li>

						<li>
							inform you about important information regarding the
							Site, products or services for which you apply or
							may be interested in applying for, or in which you
							are already enrolled, changes to terms, conditions,
							and policies and/or other administrative
							information;
						</li>

						<li>
							deliver marketing communications that we believe may
							be of interest to you, including, ads or offers
							tailored to you,
						</li>

						<li> personalize your experience on the Site;</li>

						<li>
							allow you to participate in surveys and other forms
							of market research, sweepstakes, contests and
							similar promotions and to administer these
							activities.
						</li>
					</ul>
					<p>
						Some of these activities have additional rules, which
						may contain additional information about how Personal
						Data is used and shared:
					</p>
					<ul>
						<li>
							Information collected through our social media
							channels and other online interactions with you to
							assist in verifying your identity and account
							status. We may combine this online information with
							information collected from offline sources or
							information we already have.
						</li>
					</ul>
					<h3 className='mt-5'>Personal Data That We Collect</h3>

					<p>
						MMP considers "Personal Data", any personally
						identifiable information such as information you provide
						via forms, surveys, applications or other online fields
						including name, postal or email addresses, telephone,
						fax or mobile numbers, or account numbers.
					</p>

					<p>
						Some examples of Personal Data that we may collect are:
					</p>

					<p>
						personal particulars (e.g., name, contact details,
						residential address, date of birth, identity
						card/passport details, and/or education details);
					</p>

					<ul>
						<li>specimen signature(s);</li>
						<li>
							financial details (e.g. income, expenses, and/or
							credit history);
						</li>
						<li>
							images and voice recordings of our conversations
							with you;
						</li>
						<li>
							business information (e.g., assets, creditors and
							nature of business activities);
						</li>
						<li>
							employment details (e.g., occupation, directorships
							and other positions held, employment history,
							salary, and/or benefits);
						</li>
						<li>tax and insurance information;</li>
						<li>
							information about your risk profile, investments,
							investment objectives, knowledge and experience
							and/or business interests and assets;
						</li>
						<li>
							personal opinions made known to us (e.g., feedback
							or responses to surveys); and/or
						</li>
						<li>
							information obtained from mobile devices with your
							consent (e.g. device location and information).
						</li>
					</ul>
					<h3 className='mt-5'>Cookies and Related Technologies</h3>
					<p>
						Our Site might also use cookies to deliver content
						specific to your interests. A cookie is a collection of
						information stored on your computer that usually
						includes a username and the current date and time or
						other user specific information that helps us tailor our
						websites to your interests. Your web browser (Safari,
						Firefox, Explorer, etc.) allows you to be warned when
						cookies are being placed on your computer or to refuse
						all cookies. However, not accepting or disabling cookies
						from our Site may prevent you from accessing certain
						parts of our websites.
					</p>

					<p>
						A pixel tag, also known as a web beacon, is an invisible
						tag placed on certain pages of our web site but not on
						your computer. Pixel tags are usually used in
						conjunction with cookies and are used to monitor the
						behavior of users visiting the web site.
					</p>

					<p>
						You may set up your web browser to block cookies which
						will in turn disable the pixel tags from monitoring your
						web site visit. You may also remove cookies stored from
						your computer or mobile device. However, if you do block
						cookies and pixel tags, you may not be able to use
						certain features and functions of our web sites.
					</p>
				</div>
			</div>
		</Fragment>
	);
};

export default PrivacyPolicy;
