import React from 'react';
import { Redirect } from 'react-router-dom';
import useBlogHome from '../hooks/useBlogHome';
import BlogTemplate from './blogTemplate';
import Preloader from './common/preloader';
import { StringToJSX } from './common/stringToJSX';
import Blogs from './home/blogs';

function Blog(props) {
	const _id = props.match.params._id;
	const [blog] = useBlogHome(_id);

	if (!blog) return <Preloader />;
	if (!_id || blog === 'notFound') return <Redirect to={'/'} />;
	return (
		<>
			<BlogTemplate
				title={blog.title}
				description={blog.description}
				content={<StringToJSX domString={blog.content} />}
				imageLink={blog.imageLink}
				_id={blog._id}
				author={blog.author.profile.fullName}
				date={blog.date}
			/>
			<div className='container'>
				<hr />
			</div>
			<Blogs />
		</>
	);
}

export default Blog;
