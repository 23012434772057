import React from 'react';
import Anchor from '../common/anchor';
import BcnToken3d from '../models_3d/BcnToken3d/index';

function BCN(props) {
	return (
		<div className='homeSection bcn my-4'>
			<div className='container'>
				<div className='row align-items-center py-5'>
					<div className='col-md-6 px-3'>
						<h1>USE BCN TOKEN</h1>
						<p>
							The BCN Token is an essential currency within the
							BEM Foundation ecosystem, providing a common
							currency across all BEM projects. This creates a
							cycle that encourages new holders, traders, and
							transactions. You can be a part of this cycle by
							becoming a BCN Token holder. The BEM Foundation
							ecosystem is an intelligent platform that promotes
							the development of the BCN Token. Given the limited
							number of tokens available and the multiple BEM
							projects underway, the BCN Token is expected to have
							a significant growth in the market. Additionally,
							BCN Token is one of the two primary currencies used
							in BCN Garden, further solidifying its value and
							utility.
						</p>
						<Anchor
							className='rectBtn'
							href='https://bcntoken.com'
							content={<span>Read more</span>}
						/>
					</div>
					<div className='col-md-6 text-center mt-4'>
						<BcnToken3d />
					</div>
				</div>
			</div>
		</div>
	);
}

export default BCN;
