import React from 'react';
import Image from './common/image';

function Divider({ file = '05', className = 'mb-3', full = false }) {
	return (
		<div className={`row ${className}`}>
			<div
				className={`${
					full ? 'col-12' : 'col-md-6 offset-md-3 col'
				} text-center`}>
				<Image className='w-100' fileName={`divider/${file}.png`} />
			</div>
		</div>
	);
}

export default Divider;
