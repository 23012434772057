import React, { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Countdown from 'react-countdown';
import QRCode from 'react-qr-code';
import MainContext from '../../../../context/mainContext';
import socket from '../../../../services/socketService';
import http from '../../../../services/httpService';
import { numberWithCommas } from '../../../../utils/helpers';
import TopupForm from './topupForm';
import Divider from '../../../divider';
import ConfirmModal from '../../ingredients/confirmModal';
import CopyBtn from '../../../common/copyBtn';
import Spinner from '../../../common/spinner';

const TopupBGWallet = ({ history, tab }) => {
	const { initData, userData, onUserDataChanged } = useContext(MainContext);
	const [isLoading, setIsLoading] = useState(false);
	const [expired, setExpired] = useState(false);
	const [currentToken, setCurrentToken] = useState(
		userData.hasActivePaymentRequest
			? initData.topupOptions.find(
					(o) => o.id === userData.paymentRequests[0].token
			  )
			: initData.topupOptions.find((o) => o.checked)
	);
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [currentFormData, setCurrentFormData] = useState(null);

	useEffect(() => {
		if (userData.hasActivePaymentRequest) {
			const handleUserDataPong = (data) => {
				onUserDataChanged(data.userData);
				toast[data.type](data.message);
				history.replace('/dashboard');
			};

			socket.socket.once('userDataPong', handleUserDataPong);

			return () => {
				socket.socket.off('userDataPong', handleUserDataPong);
			};
		}
	}, [userData, onUserDataChanged, history]);

	const handleModalShow = (formData) => {
		setCurrentFormData(formData);
		const markup = userData.hasActivePaymentRequest ? (
			<div>Are you sure you want to cancel the current payment?</div>
		) : (
			<>
				<div className='fs-3'>
					Deposit Amount:{' '}
					<b>
						{numberWithCommas(formData.amount)}{' '}
						{formData.token.toUpperCase()}
					</b>
				</div>
				<div className='fs-5 mt-3'>Important Note:</div>
				<ul>
					<li>
						Make sure to complete your payment before timer runs
						out.
					</li>
					<li>Do not send funds from exchanges.</li>
					<li>
						Please do not send digital assets other than{' '}
						<b>{formData.token.toUpperCase()}</b> to this address,
						otherwise the assets will not be recoverable.
					</li>
				</ul>
			</>
		);

		setModalBody(markup);
		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response && !userData.hasActivePaymentRequest)
			handleRequestSubmit();
		if (response && userData.hasActivePaymentRequest) handleCancel();
	};

	const handleRequestSubmit = async () => {
		try {
			setIsLoading(true);
			const { data } = await http.requestTopup({
				amount: currentFormData.amount * 1e6,
				token: currentFormData.token,
			});
			onUserDataChanged(data);
			toast.info('Request submitted!');
			socket.submitSocket(
				userData._id,
				userData.email,
				userData.username,
				userData.wallet
			);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	const onRequestTimerCompleted = () => {
		toast.info('Payment time expired');
		setExpired(true);
		setTimeout(async () => {
			try {
				const { data } = await http.getMe();
				onUserDataChanged(data);
			} catch (ex) {
				console.log(ex);
				if (ex && ex.response && ex.response.data)
					return toast.error(ex.response.data);
				if (ex && ex.message) return toast.error(ex.message);
			} finally {
				setExpired(false);
			}
		}, 15000);
	};

	const handleCancel = async () => {
		try {
			setIsLoading(true);
			const { data } = await http.cancelTopup({
				_id: userData.paymentRequests[0]._id,
			});
			onUserDataChanged(data);
			toast.info('Request cancelled');
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	if (
		userData.hasActivePaymentRequest &&
		initData.paymentDuration +
			new Date(userData.paymentRequests[0].date).getTime() <
			Date.now()
	)
		http.getMe().then((res) => {
			onUserDataChanged(res.data);
			setModalShow(false);
		});

	return (
		<>
			<Divider />
			<div className='row'>
				<div className='col text-center'>
					<h4 className='fs-1'>Top Up BG-Wallet</h4>
				</div>
			</div>
			<div className='row align-items-center'>
				{userData.hasActivePaymentRequest && (
					<div className='col-md-4 col-12 text-center'>
						<p>
							<Countdown
								className='fs-4 text-my-danger'
								date={
									initData.paymentDuration +
									new Date(
										userData.paymentRequests[0].date
									).getTime()
								}
								onComplete={onRequestTimerCompleted}
							/>
						</p>
						<QRCode
							value={userData.tronAccount.address}
							level={'Q'}
							size={256}
						/>
						{expired ? (
							<div className='d-flex align-items-center justify-content-center text-my-danger mt-4'>
								<i className='me-3 fa-2x fas fa-exclamation-circle'></i>
								<span className='fs-4'>
									Payment Time Is Expired!
								</span>
							</div>
						) : (
							<>
								<p className='mt-4'>
									{userData.tronAccount.address}
									<CopyBtn
										content={userData.tronAccount.address}
									/>
								</p>
								<p className='mt-4 fs-4 text-link-color'>
									Amount:{' '}
									{numberWithCommas(
										userData.paymentRequests[0].amount / 1e6
									)}{' '}
									{userData.paymentRequests[0].token.toUpperCase()}
								</p>
								<button
									onClick={handleModalShow}
									className='rectBtn'>
									{isLoading ? (
										<Spinner content={'Cancelling...'} />
									) : (
										<span>Cancel</span>
									)}
								</button>
							</>
						)}
					</div>
				)}
				<div
					className={`col-md-6 offset-md-${
						userData.hasActivePaymentRequest ? 1 : 3
					} col-12`}>
					<TopupForm
						options={initData.topupOptions}
						currentToken={currentToken}
						setCurrentToken={setCurrentToken}
						onSubmit={handleModalShow}
						min={currentToken.minTopup / 1e6}
						currentAmount={
							userData.hasActivePaymentRequest
								? userData.paymentRequests[0].amount / 1e6
								: null
						}
						isLoading={isLoading}
						step={10}
						hasActivePaymentRequest={
							userData.hasActivePaymentRequest
						}
					/>
				</div>
			</div>
			<div className='row'>
				{userData.hasActivePaymentRequest && (
					<div className='col-sm-6 offset-sm-3 col-12 text-center'>
						<h4 className='mb-3'>Deposit Terms:</h4>
						<div>
							<i className='fas fa-check me-1'></i>
							Make sure to complete your payment before timer runs
							out.
						</div>
						<div>
							<i className='fas fa-check me-1'></i>
							Do not send funds from exchanges.
						</div>
						<div>
							<i className='fas fa-check me-1'></i>
							Please do not send digital assets other than{' '}
							{userData.paymentRequests[0].token.toUpperCase()} to
							this address, otherwise the assets will not be
							recoverable.
						</div>
					</div>
				)}
			</div>
			<Divider file='08' className='my-4' full={true} />
			<div className='row'>
				<div className='col-lg-12'>
					<h4 className='mb-4'>Cryptocurrency Top Up Disclaimer:</h4>
					<ul>
						<li>
							Please note that only make your deposit within the
							specified time, only deposits received before the
							time expires will be credited to your account. (that
							is, before {initData.paymentDuration / (60 * 1000)}{' '}
							minutes)
						</li>
						<li>
							Note that the amount must be deposited according to
							the initial request. The amounts more or less than
							requested amount will not be credited to your
							account.
						</li>
						<li>
							If the deposit is made after the specified time, or
							if the requested amount is different from the
							deposited amount, the experts will not be able to
							recognize your transaction and the amount will not
							be deposited into your account and will not be
							returned to your wallet.
						</li>
						<li>
							If you want to change the amount registered for
							deposit, you should wait until the time expires (
							{initData.paymentDuration / (60 * 1000)} minutes)
							and then request a deposit again.
						</li>
					</ul>
				</div>
			</div>
			<ConfirmModal
				show={modalShow}
				onClose={handleModalClose}
				title={'Top Up BG-Wallet'}
				body={modalBody}
			/>
		</>
	);
};

export default TopupBGWallet;
