import React from 'react';
import Countdown from 'react-countdown';
import Joi from 'joi-browser';
import Form from '../common/form';
import Spinner from '../common/spinner';

class RegisterConfirmForm extends Form {
	state = {
		data: {
			vcCode: '',
		},
		errors: {},
	};

	schema = {
		vcCode: Joi.string()
			.length(6)
			.regex(/^[0-9]*$/, '"Only digits are allowed"')
			.required()
			.label('Verification Code'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);
	doEmailRequest = () => this.props.onEmailRequest();

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.errors !== this.props.errors) {
			const state = { ...this.state };
			state.errors = this.props.errors;
			this.setState(state);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const {
			isLoading,
			counterDate,
			isEmailAllowed,
			onRequestTimerCompleted,
			isEmailBtnLoading,
		} = this.props;

		return (
			<form className='row mb-5' onSubmit={this.handleSubmit}>
				<div className='col-md-6 offset-md-3 col-12 text-start'>
					<div className='mb-4'>
						{this.renderInputGroupBtnEnd({
							name: 'vcCode',
							label: 'Verification Code',
							type: 'text',
							btnClass: `rectBtn`,
							btnText: isEmailBtnLoading ? (
								<Spinner content={'Submitting...'} />
							) : isEmailAllowed ? (
								<span>Resend Code</span>
							) : (
								<Countdown
									date={counterDate}
									onComplete={onRequestTimerCompleted}
								/>
							),
							btnDisable:
								!isEmailAllowed || isEmailBtnLoading === true,
							btnHandler: this.doEmailRequest,
							formText: (
								<span className='text-my-primary'>
									Receive <b>Verification Code</b> in your
									email. This code remains valid for <b>10</b>{' '}
									minutes.
								</span>
							),
						})}
					</div>
					<div className='text-center'>
						{this.renderButton(
							<span>Submit</span>,
							`rectBtn`,
							isLoading && <Spinner content={'Submitting...'} />
						)}
					</div>
				</div>
			</form>
		);
	}
}

export default RegisterConfirmForm;
