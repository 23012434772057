import React from 'react';

const Input = ({
	name,
	label,
	error,
	customClass = '',
	labelClass,
	formText,
	formTextClass = '',
	...rest
}) => {
	return (
		<div className={`form-group ${customClass}`}>
			<label className={labelClass} htmlFor={name}>
				{label}
			</label>
			<input {...rest} name={name} id={name} className='form-control' />
			{error && <div className='alert alert-danger'>{error}</div>}
			{formText && (
				<div className={`form-text ${formTextClass}`}>{formText}</div>
			)}
		</div>
	);
};

export default Input;
