import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import MainContext from './../../context/mainContext';
import http from '../../services/httpService';
import Preloader from './../common/preloader';
import BlogCard from './blogs/blogCard';
import { Link } from 'react-router-dom';

function Blogs(props) {
	useDocumentTitle('BCN GARDEN - Blogs');
	const { blogs, userData, onRemoveBlog, onUserDataChanged } =
		useContext(MainContext);
	const [isLoading, setIsLoading] = useState(false);

	const handleDelete = async (_id) => {
		try {
			setIsLoading(true);
			const { data } = await http.deleteBlog({ _id });
			toast.success(data.message);
			onRemoveBlog(_id);
			onUserDataChanged({ blogs: data.blogs });
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.data) toast.error(ex.response.data);
		} finally {
			setIsLoading(false);
		}
	};

	if (!blogs || !userData._id) return <Preloader />;
	return (
		<div className='container'>
			<div className='row'>
				<div className='col-12'>
					<Link to={'/blogs/create'} className={'btn btn-my-navy'}>
						<i className='me-2 fas fa-plus'></i>New Blog
					</Link>
				</div>
			</div>
			<div className='row my-5'>
				{blogs
					.sort((a, b) => (a.date > b.date ? -1 : 1))
					.map((b, key) => (
						<div key={key} className='col-sm-3 mb-4'>
							<BlogCard
								isLoading={isLoading}
								onDelete={handleDelete}
								{...props}
								blog={b}
							/>
						</div>
					))}
			</div>
		</div>
	);
}

export default Blogs;
