import React from 'react';
import Joi from 'joi-browser';
import Countdown from 'react-countdown';
import Form from './../common/form';
import Spinner from './../common/spinner';

class ResetForm extends Form {
	state = {
		data: {
			email: '',
			password: '',
			password_confirmation: '',
			vcCode: '',
		},
		errors: {},
		matched: false,
		ul: false,
		alhpanumer: false,
		length: false,
	};

	schema = {
		email: Joi.string().email().required().label('Email'),
		password: Joi.string().required().min(8).max(26).label('Password'),
		password_confirmation: Joi.string()
			.required()
			.min(8)
			.max(26)
			.label('Confirm password'),
		vcCode: Joi.string()
			.length(6)
			.regex(/^[0-9]*$/, '"Only digits are allowed"')
			.required()
			.label('Verification Code'),
	};

	checkPassword = ({ currentTarget: text }) => {
		const hasAlpha = (str) => /[a-zA-Z- ]+/.test(str);
		const hasNum = (str) => /[0-9]+/.test(str);

		const textLength = text.value.length;
		const length = textLength >= 8 && textLength <= 26 ? true : false;
		const ul =
			text.value.toUpperCase() !== text.value &&
			text.value.toLowerCase() !== text.value
				? true
				: false;
		const alhpanumer = hasAlpha(text.value) && hasNum(text.value);
		this.setState({ length, ul, alhpanumer });
	};

	checkPasswordsMatched = ({ currentTarget }) => {
		const id = currentTarget.id;
		const targetID =
			id === 'password' ? 'password_confirmation' : 'password';
		const text = currentTarget.value;
		const target = this.state.data[targetID];
		this.setState({ matched: text === target });
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.errors !== this.props.errors) {
			const state = { ...this.state };
			state.errors = this.props.errors;
			this.setState(state);
		}
	}

	doSubmit = () => this.props.onSubmit(this.state.data);
	doEmailRequest = () => {
		if (this.state.errors.email) return;
		this.props.onEmailRequest(this.state.data.email);
	};

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const {
			isLoading,
			counterDate,
			isEmailAllowed,
			onRequestTimerCompleted,
			isEmailBtnLoading,
		} = this.props;
		const { matched, ul, alhpanumer, length } = this.state;
		return (
			<form
				className='text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='mb-3'>
					{this.renderInput({
						name: 'email',
						label: 'Email',
						type: 'text',
					})}
				</div>
				<div className='mb-3'>
					{this.renderInputGroupBtnEnd({
						name: 'password',
						label: 'New Login Password',
						type: 'password',
						btnClass: `rectBtn`,
						btnText: <i className='fas fa-eye-slash'></i>,
						btnHandler: this.handleEyeClicked,
						formText: (
							<div className='d-flex justify-content-between mx-2'>
								<div
									className={`text-${
										ul ? 'my-success' : 'my-danger'
									}`}>
									<i
										className={`fas fa-${
											ul ? 'check' : 'times'
										} me-2`}></i>
									Upper & Lower Case
								</div>
								<div
									className={`text-${
										alhpanumer ? 'my-success' : 'my-danger'
									}`}>
									<i
										className={`fas fa-${
											alhpanumer ? 'check' : 'times'
										} me-2`}></i>
									AlphaNumeric
								</div>
								<div
									className={`text-${
										length ? 'my-success' : 'my-danger'
									}`}>
									<i
										className={`fas fa-${
											length ? 'check' : 'times'
										} me-2`}></i>
									Length (8to24)
								</div>
							</div>
						),
						extraHandler: (e) => {
							this.checkPassword(e);
							this.checkPasswordsMatched(e);
						},
					})}
				</div>
				<div className='mb-3'>
					{this.renderInputGroupBtnEnd({
						name: 'password_confirmation',
						label: 'Confirm New Login Password',
						type: 'password',
						btnClass: `rectBtn`,
						btnText: <i className='fas fa-eye-slash'></i>,
						btnHandler: this.handleEyeClicked,
						formText: (
							<div className='d-flex justify-content-between mx-2'>
								<div
									className={`text-${
										matched ? 'my-success' : 'my-danger'
									}`}>
									<i
										className={`fas fa-${
											matched ? 'check' : 'times'
										} me-2`}></i>
									Passwords Matched
								</div>
							</div>
						),
						extraHandler: this.checkPasswordsMatched,
					})}
				</div>
				<div className='mb-3'>
					{this.renderInputGroupBtnEnd({
						name: 'vcCode',
						label: 'Verification Code',
						type: 'text',
						btnClass: `rectBtn`,
						btnText: isEmailBtnLoading ? (
							<Spinner content={'Submitting...'} />
						) : isEmailAllowed ? (
							<span>Send Code</span>
						) : (
							<Countdown
								date={counterDate}
								onComplete={onRequestTimerCompleted}
							/>
						),
						btnDisable:
							!isEmailAllowed || isEmailBtnLoading === true,
						btnHandler: this.doEmailRequest,
						formText: (
							<span className='text-my-success'>
								Receive <b>Verification Code</b> in your email.
								This code remains valid for <b>10</b> minutes.
							</span>
						),
					})}
				</div>
				<div className='mb-3 text-center'>
					{this.renderButton(
						<span>Submit</span>,
						`rectBtn`,
						isLoading && <Spinner content={'Submitting...'} />
					)}
				</div>
			</form>
		);
	}
}

export default ResetForm;
