import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';
import Spinner from '../../../common/spinner';

class VerifyCodeForm extends Form {
	state = {
		data: {
			token: '',
		},
		errors: {},
	};

	schema = {
		token: Joi.string()
			.length(6)
			.regex(/^[0-9]*$/, '"Only digits are allowed"')
			.required()
			.label('Verify Code'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading } = this.props;
		return (
			<form
				className='text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className=''>
					{this.renderInput({
						name: 'token',
						label: 'Enter the authentication code',
						type: 'text',
						formText:
							'For changing the setting, please verify the authentication code.',
					})}
				</div>
				<div className='mb-3 text-center'>
					{this.renderButton(
						<span>Submit</span>,
						'rectBtn',
						isLoading && <Spinner content={'Submitting...'} />
					)}
				</div>
			</form>
		);
	}
}

export default VerifyCodeForm;
