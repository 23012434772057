import Image from '../common/image';
import Benefits from './benefits';

const crowdfunding = {
	path: '/crowdfunding',
	name: 'Crowdfunding',
	title: 'Crowdfunding',
	content: (
		<>
			<div className='container pt-5'>
				<div className='row'>
					<div className='col-12'>
						<div className='row mb-5'>
							<div className='col-12'>
								<h2>What is crowdfunding?</h2>
								<p>
									Crowdfunding is funding a project or venture
									by raising money from many people. It is a
									common way for startups to raise money and
									is usually used by entrepreneurs, inventors,
									musicians, filmmakers, artists, and
									charities also used this method. <br />
									This method was first used by a British rock
									band named Marillion in 1997. The band did
									not have enough money to do its tour in the
									United States, so they reached out to their
									fans and gathered 60 thousand dollars to do
									their tour. This method became a successful
									example for companies and startups. br/
									Crowdfunding usually takes place on online
									platforms for a specific period to reach a
									certain goal. Projects try to attract
									investors through marketing techniques, such
									as public relations, social media, and
									network marketing.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Benefits />

			<div className='container pb-5'>
				<div className='row mt-5'>
					<div className='col-12'>
						<h2>Types of crowdfunding</h2>
						<p>
							There are four main types of crowdfunding, each
							targeting a different investor/donor audience.
						</p>
					</div>
				</div>

				<div className='row mb-3 align-items-center'>
					<div className='col-md-1'>
						<Image
							height={'80px'}
							fileName={`blogs/icons/financialAssistance.svg`}
						/>
					</div>
					<div className='col-md-11'>
						<h4>Financial help</h4>
						<p>
							Financial-help-crowdfunding is a type of
							crowdfunding in which the donors to a company,
							campaign, or person do not expect anything in
							return. Let's say you've started a campaign to buy
							new equipment for your company. People that donate
							to you do it to support the development of your
							business and expect nothing in return from you.
						</p>
					</div>
				</div>
				<div className='row mb-3 align-items-center'>
					<div className='col-md-1'>
						<Image
							height={'80px'}
							fileName={`blogs/icons/debt.svg`}
						/>
					</div>
					<div className='col-md-11'>
						<h4>Debt</h4>
						<p>
							Debt help is a sort of peer-to-peer loan, which is
							considered a type of crowdfunding. Debt-based
							crowdfunding is a loan provided by a bank and must
							be paid back in a specific window with its interest.
						</p>
					</div>
				</div>
				<div className='row mb-3 align-items-center'>
					<div className='col-md-1'>
						<Image
							height={'80px'}
							fileName={`blogs/icons/reward.svg`}
						/>
					</div>
					<div className='col-md-11'>
						<h4>Reward</h4>
						<p>
							The reward is when the donors receive something in
							return for their donation. Rewards differ based on
							donations to encourage more participation. The donor
							may receive a gift (like a T-shirt), product, or
							service at discounted prices.
						</p>
					</div>
				</div>
				<div className='row mb-3 align-items-center'>
					<div className='col-md-1'>
						<Image
							height={'80px'}
							fileName={`blogs/icons/equity.svg`}
						/>
					</div>
					<div className='col-md-11'>
						<h4>Stockholder income</h4>
						<p>
							Some crowdfundings allow the supporters to own a
							portion of the company they've supported.
							Stockholder income crowdfunding allows small
							businesses and startups to raise money by giving the
							supporter a portion of their company's stocks. This
							type of help is a sort of investment in which the
							supporters will receive stocks for the money they
							invest
						</p>
					</div>
				</div>
			</div>
		</>
	),
};

export default crowdfunding;
