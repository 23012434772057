import React from 'react';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import http from '../../services/httpService';
import Form from '../common/form';
import Spinner from '../common/spinner';

class RegisterForm extends Form {
	state = {
		data: {
			invitee: this.props.invitee || '',
			email: '',
			password: '',
			password_confirmation: '',
			username: '',
			wallet: '',
		},
		errors: {},
		matched: false,
		ul: false,
		alhpanumer: false,
		length: false,
		isCheckBtnLoading: false,
		isCheckBtnDisabled: !this.props.invitee,
		idStatus: this.props.invitee ? 1 : 0,
		afterCheckMessage: '',
	};

	classesByIDStatus = [
		{
			class: 'text-my-primary',
			icon: 'info',
			text: 'Without any invitee ID, you will be placed under root user.',
		},
		{
			class: 'text-my-danger',
			icon: 'times',
			text: 'Press Check button to examine the entered ID.',
		},
		{
			class: 'text-my-primary',
			icon: 'info',
			text: 'The given invitee ID is not valid, you will be placed under root user.',
		},
		{ class: 'text-my-success', icon: 'check', text: 'Valid invitee ID.' },
	];

	schema = {
		invitee: Joi.string()
			.allow('')
			.alphanum()
			.length(10)
			.lowercase()
			.label('Referral ID'),
		email: Joi.string().email().required().label('Email'),
		password: Joi.string().required().min(8).max(26).label('Password'),
		password_confirmation: Joi.string()
			.required()
			.min(8)
			.max(26)
			.label('Confirm password'),
		username: Joi.string().required().min(5).max(12).label('Username'),
		wallet: Joi.string().required().label('Tron Wallet'),
	};

	doSubmit = () =>
		this.props.onSubmit({
			...this.state.data,
			...{ idStatus: this.state.idStatus },
		});

	checkIDHandler = async () => {
		const { invitee } = this.state.data;
		// if (!invitee) return;

		try {
			this.setState({ isCheckBtnLoading: true });
			const { data } = await http.checkID({
				invitee: invitee.toLowerCase(),
			});
			const errors = { ...this.state.errors };
			delete errors.invitee;
			this.setState({
				errors,
				afterCheckMessage: data.message,
				idStatus: data.idStatus,
			});
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 400) {
				const data = ex.response.data;
				return this.setState({
					afterCheckMessage: data,
					idStatus: 0,
				});
			}
			if (ex && ex.response && ex.response.data) {
				this.setState({ idStatus: 0 });
				return toast.error(ex.response.data);
			}
			if (ex && ex.message) {
				this.setState({ idStatus: 0 });
				return toast.error(ex.message);
			}
		} finally {
			this.setState({ isCheckBtnLoading: false });
		}
	};

	onInviteeChanged = ({ currentTarget: text }) =>
		this.setState({
			idStatus: text.value ? 1 : 0,
			isCheckBtnDisabled: !!this.validateProperty({
				name: 'invitee',
				value: text.value,
			}),
		});

	checkPassword = ({ currentTarget: text }) => {
		const hasAlpha = (str) => /[a-zA-Z- ]+/.test(str);
		const hasNum = (str) => /[0-9]+/.test(str);

		const textLength = text.value.length;
		const length = textLength >= 8 && textLength <= 26 ? true : false;
		const ul =
			text.value.toUpperCase() !== text.value &&
			text.value.toLowerCase() !== text.value
				? true
				: false;
		const alhpanumer = hasAlpha(text.value) && hasNum(text.value);
		this.setState({ length, ul, alhpanumer });
	};

	checkPasswordsMatched = ({ currentTarget }) => {
		const id = currentTarget.id;
		const targetID =
			id === 'password' ? 'password_confirmation' : 'password';
		const text = currentTarget.value;
		const target = this.state.data[targetID];
		this.setState({ matched: text === target });
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.errors !== this.props.errors) {
			const state = { ...this.state };
			state.errors = this.props.errors;
			this.setState(state);
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading } = this.props;
		const {
			errors,
			matched,
			ul,
			alhpanumer,
			length,
			idStatus,
			isCheckBtnLoading,
			isCheckBtnDisabled,
		} = this.state;

		return (
			<form
				className='row'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='col-md-6'>
					<div className='mb-3'>
						{this.renderInput({
							name: 'email',
							label: 'Email',
							type: 'email',
						})}
					</div>

					<div className='mb-3'>
						{this.renderInputGroupBtnEnd({
							name: 'password',
							label: 'Password',
							type: 'password',
							btnClass: `rectBtn`,
							btnText: <i className='fas fa-eye-slash'></i>,
							btnHandler: this.handleEyeClicked,
							formText: (
								<div className='row mx-2'>
									<div className='col'>
										<div
											className={`text-${
												alhpanumer
													? 'my-success'
													: 'my-danger'
											}`}>
											<i
												className={`fas fa-${
													alhpanumer
														? 'check'
														: 'times'
												} me-1`}></i>
											AlphaNumeric
										</div>
										<div
											className={`text-${
												length
													? 'my-success'
													: 'my-danger'
											}`}>
											<i
												className={`fas fa-${
													length ? 'check' : 'times'
												} me-1`}></i>
											Length (8to24)
										</div>
									</div>

									<div className='col'>
										<div
											className={`text-${
												ul ? 'my-success' : 'my-danger'
											}`}>
											<i
												className={`fas fa-${
													ul ? 'check' : 'times'
												} me-1`}></i>
											Upper & Lower Case
										</div>
									</div>
								</div>
							),
							extraHandler: (e) => {
								this.checkPassword(e);
								this.checkPasswordsMatched(e);
							},
						})}
					</div>

					<div className='mb-3'>
						{this.renderInputGroupBtnEnd({
							name: 'password_confirmation',
							label: 'Confirm Password',
							type: 'password',
							btnClass: `rectBtn`,
							btnText: <i className='fas fa-eye-slash'></i>,
							btnHandler: this.handleEyeClicked,
							formText: (
								<div className='d-flex justify-content-between mx-2'>
									<div
										className={`text-${
											matched ? 'my-success' : 'my-danger'
										}`}>
										<i
											className={`fas fa-${
												matched ? 'check' : 'times'
											} me-1`}></i>
										Passwords Matching
									</div>
								</div>
							),
							extraHandler: this.checkPasswordsMatched,
						})}
					</div>
				</div>
				<div className='col-md-6'>
					<div className='mb-3'>
						{this.renderInputGroupBtnEnd({
							name: 'invitee',
							label: 'Invitee ID',
							type: 'text',
							btnClass: `rectBtn`,
							extraHandler: this.onInviteeChanged,
							btnText: isCheckBtnLoading ? (
								<Spinner content={'Checking...'} />
							) : (
								'Check'
							),
							btnDisable:
								isCheckBtnDisabled ||
								idStatus === 0 ||
								isCheckBtnLoading ||
								isLoading,
							btnHandler: this.checkIDHandler,
							formText: !errors.invitee && (
								<div
									className={`mx-2 ${this.classesByIDStatus[idStatus].class}`}>
									<i
										className={`fas fa-${this.classesByIDStatus[idStatus].icon} me-1`}></i>
									<span>
										{this.classesByIDStatus[idStatus].text}
									</span>{' '}
								</div>
							),
						})}
					</div>
					<div className='mb-4'>
						{this.renderInput({
							name: 'wallet',
							label: 'Tron Wallet address',
							type: 'text',
						})}
					</div>
					<div className='mb-4'>
						{this.renderInput({
							name: 'username',
							label: 'Username',
							type: 'text',
						})}
					</div>
				</div>

				<div className='text-center'>
					{this.renderButton(
						'Register',
						`rectBtn`,
						isLoading && <Spinner content={'Registering...'} />
					)}
				</div>
			</form>
		);
	}
}

export default RegisterForm;
