import React, { useContext } from 'react';
import MainContext from '../../context/mainContext';
import { Redirect, Switch, Link } from 'react-router-dom';
import Summary from './summary';
import CrumbsComp from './crumbsComp';
import AlertBox from '../common/alertBox';
import ProtectedRoute from '../common/protectedRoute';
import tabs from './tabs.json';
import ComingSoon from '../comingSoon';

const MainPanel = () => {
	const { userData, UIData } = useContext(MainContext);

	const isLessThanMD = window.matchMedia('(max-width: 767.98px)').matches;
	const height = UIData.crumbsComp
		? UIData.crumbsComp.height + UIData.dashboardSpacer
		: 0 + UIData.dashboardSpacer;

	const routes = getComponents();
	const redirects = [];
	for (const i in tabs) {
		const tab = tabs[i];
		const hasChildren = tab.children && tab.children.length > 0;
		if (!tab.toBeShown || !hasChildren) continue;
		const firstActiveChildIndex = tab.children.findIndex(
			(c) => c.toBeShown === true
		);
		redirects.push(
			<Redirect
				key={i}
				from={`/dashboard/${tab.id}`}
				to={`/dashboard/${tab.id}/${tab.children[firstActiveChildIndex].id}`}
			/>
		);
	}

	return (
		<Switch>
			{routes.map(({ path, Component, tab, crumbs }, key) => (
				<ProtectedRoute
					exact
					path={path}
					key={key}
					render={(props) => (
						<>
							<div className='d-block d-md-none'>
								<Summary />
							</div>
							<main className='card contentsCard mb-2'>
								<CrumbsComp crumbs={crumbs} />
								<div
									className='card-body contentsBody mt-0 pt-0 mb-md-3 me-md-2'
									style={
										isLessThanMD
											? null
											: {
													overflowX: 'hidden',
													overflowY: 'scroll',
													height: `calc(100vh - ${height}px)`,
											  }
									}>
									{getUserAlerts(userData)}
									<Component {...props} tab={tab} />
								</div>
							</main>
						</>
					)}
				/>
			))}
			{redirects}
			<Redirect from='/dashboard' to='/dashboard/home' />
			<Redirect to='/notFound' />
		</Switch>
	);
};

const getComponents = () => {
	const routes = [];
	for (let i = 0; i < tabs.length; i++) {
		const tab = tabs[i];
		if (!tab.toBeShown || tab.link) continue;
		const hasChildren = tab.children && tab.children.length > 0;
		if (!hasChildren) {
			const route = {
				tab,
				path: `/dashboard/${tab.id}`,
				name: tab.name,
				crumbs: [tab.name],
				Component: tab.active
					? require(`./tabs/${tab.id}`).default
					: ComingSoon,
			};
			routes.push(route);
		} else {
			for (const j in tab.children) {
				const child = tab.children[j];
				if (!child.toBeShown) continue;
				const route = {
					tab: child,
					path: `/dashboard/${tab.id}/${child.id}`,
					name: child.name,
					crumbs: [tab.name, child.name],
					Component: child.active
						? require(`./tabs/${tab.id}/${
								child.template || child.id
						  }`).default
						: ComingSoon,
				};
				routes.push(route);
			}
		}
	}
	return routes;
};

const getUserAlerts = (user) => {
	return (
		<>
			{user.emailStatus === 'Pending' && (
				<Link to='/dashboard/myProfile/profile'>
					<AlertBox
						extraClass='myImages'
						type={'danger'}
						content={`Email is not confirmed. some features are not active. please check your inbox and confirm your email.`}
					/>
				</Link>
			)}
			{!user.email && (
				<AlertBox
					extraClass='myImages'
					type={'danger'}
					content={`Email is not confirmed. some features are deactive. please check your inbox and confirm your email.`}
				/>
			)}
		</>
	);
};

export default MainPanel;
