import React from 'react';
import Joi from 'joi-browser';
import Form from '../common/form';
import Spinner from '../common/spinner';

class OTPForm extends Form {
	state = {
		data: { token: '' },
		errors: {},
	};

	schema = {
		token: Joi.string()
			.length(6)
			.regex(/^[0-9]*$/, '"Only digits are allowed"')
			.required()
			.label('Verify Code'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading } = this.props;
		return (
			<form onSubmit={this.handleSubmit}>
				<div className='mb-3'>
					{this.renderInput({
						name: 'token',
						label: 'Enter the 6 digits authentication code',
						type: 'text',
					})}
				</div>
				<div className='text-center'>
					{this.renderButton(
						<span>Login</span>,
						'rectBtn',
						isLoading && <Spinner content={'Loging in...'} />
					)}
				</div>
			</form>
		);
	}
}

export default OTPForm;
