import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import MainContext from '../../../context/mainContext';
import http from '../../../services/httpService';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import jsonToFormData from '../../../utils/jsonToFormData';
import ResourceForm from './resourceForm';
import Preloader from '../../common/preloader';

function AddResource(props) {
	useDocumentTitle('BCN GARDEN - Add Resource');

	const { onResetResources, initData, onUserDataChanged } =
		useContext(MainContext);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (formState) => {
		try {
			const { data, images } = formState;

			const formData = jsonToFormData({
				title: data.title,
				link: data.link,
				type: data.type,
			});
			formData.append(
				'img',
				images.img.length > 0 ? images.img[0].file : ''
			);
			setIsLoading(true);
			const response = await http.addResource(formData);
			onUserDataChanged({ resources: response.data.resources });
			onResetResources();
			props.history.push(`/resources`);
		} catch (ex) {
			console.log(ex);
			if (ex.response && (ex.response.status === 400 || 403)) {
				console.log(ex.response);
				toast.error(ex.response.data);
			}
		} finally {
			setIsLoading(false);
		}
	};

	if (!initData.resourcesConfig) return <Preloader />;
	return (
		<div className='container py-5'>
			<div className='row'>
				<h1 className='text-center mb-3'>Add New Resource</h1>
				<div className='card myCards'>
					<div className='card-body'>
						<ResourceForm
							titleConfig={initData.resourcesConfig.title}
							types={initData.resourcesConfig.types}
							maxFileSize={initData.resourcesConfig.maxFileSize}
							acceptType={initData.resourcesConfig.acceptType}
							resolutionWidth={initData.resourcesConfig.width}
							resolutionHeight={initData.resourcesConfig.height}
							onSubmit={handleSubmit}
							isLoading={isLoading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddResource;
