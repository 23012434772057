import React from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import Image from './common/image';

const UnderMaintenance = () => {
	useDocumentTitle('BCN GARDEN - Under Maintenance');
	return (
		<main className='container'>
			<div className='row my-5'>
				<div className='col-md-8 offset-md-2 col text-center'>
					<Image
						className={'w-75'}
						fileName={`underMaintenance.png`}
					/>
					<h1>Maintenance</h1>
					<p className='text-danger'>
						<b>503 - Service is currently unavailable!</b>
					</p>
					<h5>We will come back online shortly.</h5>
				</div>
			</div>
		</main>
	);
};

export default UnderMaintenance;
