import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import MainContext from '../../../context/mainContext';
import Divider from '../../divider';
import useShop from '../../../hooks/useShop';
import Preloader from '../../common/preloader';
import Image from '../../common/image';
import ConfirmModal from '../ingredients/confirmModal';
import http from '../../../services/httpService';
import Spinner from './../../common/spinner';

const AmordadShop = ({ history }) => {
	const { userData, onUserDataChanged } = useContext(MainContext);
	const [isLoading, setIsLoading] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [modalBtns, setModalBtns] = useState(false);
	const [seed, setSeed] = useState(undefined);
	const [shop] = useShop();
	if (!shop) return <Preloader />;

	const isLessThanMD = window.matchMedia('(max-width: 767.98px)').matches;
	const { charsDef, buyCurrency, mintingPrice } = shop;
	const chars = Object.keys(charsDef);

	const handleModalShow = (seed) => {
		setSeed(seed);
		const trxCost =
			mintingPrice + (buyCurrency === 'trx' ? charsDef[seed].price : 0);
		const timCost = buyCurrency === 'tim' ? charsDef[seed].trc20Price : 0;
		const isTRXEnough = userData.trx.available >= trxCost;
		const isTIMEnough = userData.tim.available >= timCost;
		setModalBtns(isTRXEnough && isTIMEnough);
		setModalBody(
			<>
				<div className='row align-items-center'>
					<div className='col-3 text-center'>
						<Image
							fileName={`chars/${seed}001.png`}
							className={`${
								isLessThanMD ? 'w-75' : 'w-50'
							} shadowfilter`}
						/>
					</div>
					<div className='col-9'>
						<div>Purchasing {seed.toUpperCase()}</div>
						<div>
							Price:{' '}
							{buyCurrency === 'tim'
								? charsDef[seed].trc20Price / 1e6
								: charsDef[seed].price / 1e6}{' '}
							{buyCurrency.toUpperCase()}
						</div>
						<div>Minting Fee: {mintingPrice / 1e6} TRX</div>
					</div>
				</div>

				{(!isTRXEnough || !isTIMEnough) && (
					<>
						<hr />
						<div className='text-my-danger fs-5 my-2 text-center'>
							<Image fileName={'warning.png'} />
							{!isTRXEnough && (
								<div>TRX Balance is not enough!</div>
							)}
							{!isTIMEnough && (
								<div>TIM Balance is not enough!</div>
							)}
						</div>
					</>
				)}
			</>
		);

		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response) handleBuy();
	};

	const handleBuy = async () => {
		try {
			setIsLoading(true);
			const { data } = await http.buy({ seed });
			toast.success(`You successfully purchased ${data.privateName}`);
			onUserDataChanged(data.user);
			history.replace('/dashboard');
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
			setSeed(undefined);
		}
	};

	return (
		<>
			<Divider />

			<div className='row my-4'>
				<div className='col text-center'>
					<h4 className='fs-1'>Amordad Shop</h4>
				</div>
			</div>

			<div className='row my-5'>
				<div className='offset-md-3 col-md-2 col-4 text-center'>
					<div className='d-flex justify-content-center align-items-center'>
						<Image
							className='me-2'
							fileName={'icons/coins/trx.png'}
							height={'30px'}
						/>
						<span className='fs-4 '>
							{userData.trx.available / 1e6} TRX
						</span>
					</div>
				</div>
				<div className='col-md-2 col-4 text-center'>
					<div className='d-flex justify-content-center align-items-center'>
						<Image
							className='me-2'
							fileName={'icons/coins/bcn.png'}
							height={'30px'}
						/>
						<span className='fs-4'>
							{userData.bcn.available / 1e6} BCN
						</span>
					</div>
				</div>
				<div className='col-md-2 col-4 text-center align-items-center'>
					<div className='d-flex justify-content-center align-items-center'>
						<Image
							className='me-2'
							fileName={'icons/coins/tim.png'}
							height={'30px'}
						/>
						<span className='fs-4'>
							{userData.tim.available / 1e6} TIM
						</span>
					</div>
				</div>
			</div>

			<div className='container'>
				<div className='row'>
					{chars.map((s, key) => (
						<div key={key} className='col-sm-3 mb-4'>
							<div className='card seedsCard'>
								<div className='card-header text-center'>
									<Link to={`/seeds/${s}`}>
										<Image
											fileName={`chars/${s}001.png`}
											height='75px'
											className='shadowfilter mb-3'
										/>
									</Link>
									<div className='fs-5'>
										{charsDef[s].name}
									</div>
								</div>
								<div className='card-body text-center'>
									<div>
										Total Supply: {charsDef[s].limitSupply}
									</div>
									<div>
										Minted Amount: {charsDef[s].totalSupply}
									</div>
									<div>
										Price:{' '}
										{buyCurrency === 'tim'
											? charsDef[s].trc20Price / 1e6
											: charsDef[s].price / 1e6}{' '}
										{buyCurrency.toUpperCase()}
									</div>
									<button
										onClick={() => handleModalShow(s)}
										className='rectBtn mt-2'
										disabled={isLoading}>
										{isLoading ? (
											<Spinner
												content={'Purchasing...'}
											/>
										) : (
											<span>Purchase</span>
										)}
									</button>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			<Divider file='07' className='mt-5' />
			<ConfirmModal
				show={modalShow}
				onClose={handleModalClose}
				title={'Amoradad Shop'}
				body={modalBody}
				btns={modalBtns}
			/>
		</>
	);
};

export default AmordadShop;
