const queryString = require('query-string');

export function subString(str, j = 6, a = 4, char = '*') {
	const b = str.length - a;
	let chars = '';
	for (let i = 0; i < j; i++) chars += char;
	return `${str.substring(0, a)}${chars}${str.substring(b)}`;
}

export function getFaltten(treeStructure) {
	let flatten = (children, extractChildren) =>
		Array.prototype.concat.apply(
			children,
			children.map((x) =>
				flatten(extractChildren(x) || [], extractChildren)
			)
		);

	let extractChildren = (x) => x.children;

	let flat = flatten(extractChildren(treeStructure), extractChildren);
	return flat;
}

export function getReferralFromUrl(url = window.location.href) {
	if (!url.includes('?')) return '';
	const uid = url.split(/[?]/)[1].slice(0, 24);
	if (uid) return uid;
	return '';
}

export function getQueries() {
	const parsed = queryString.parse(window.location.search);
	return parsed;
}

export function roundUpMinutes(date) {
	let myDate = new Date(date);
	myDate.setHours(myDate.getHours() + Math.ceil(myDate.getMinutes() / 60));
	myDate.setMinutes(0, 0, 0); // Resets also seconds and milliseconds
	return myDate;
}

export function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
