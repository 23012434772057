import React from 'react';
import Image from './../common/image';

function Hero(props) {
	return (
		<div
			id='carouselExampleIndicators'
			className='carousel slide homeSection hero'
			data-bs-ride='true'>
			<div className='carousel-indicators'>
				<button
					type='button'
					data-bs-target='#carouselExampleIndicators'
					data-bs-slide-to='0'
					className='active'
					aria-current='true'
					aria-label='Slide 1'></button>
				<button
					type='button'
					data-bs-target='#carouselExampleIndicators'
					data-bs-slide-to='1'
					aria-label='Slide 2'></button>
				<button
					type='button'
					data-bs-target='#carouselExampleIndicators'
					data-bs-slide-to='2'
					aria-label='Slide 3'></button>
				<button
					type='button'
					data-bs-target='#carouselExampleIndicators'
					data-bs-slide-to='3'
					aria-label='Slide 4'></button>
				<button
					type='button'
					data-bs-target='#carouselExampleIndicators'
					data-bs-slide-to='4'
					aria-label='Slide 5'></button>
			</div>
			<div className='carousel-inner'>
				<div className='carousel-item active' data-bs-interval='10000'>
					<Image fileName='home/hero/01.jpg' className='w-100' />
				</div>
				<div className='carousel-item' data-bs-interval='10000'>
					<Image fileName='home/hero/02.jpg' className='w-100' />
				</div>
				<div className='carousel-item' data-bs-interval='10000'>
					<Image fileName='home/hero/03.jpg' className='w-100' />
				</div>
				<div className='carousel-item' data-bs-interval='10000'>
					<Image fileName='home/hero/04.jpg' className='w-100' />
				</div>
				<div className='carousel-item' data-bs-interval='10000'>
					<Image fileName='home/hero/05.jpg' className='w-100' />
				</div>
			</div>
			<button
				className='carousel-control-prev'
				type='button'
				data-bs-target='#carouselExampleIndicators'
				data-bs-slide='prev'>
				<span
					className='carousel-control-prev-icon'
					aria-hidden='true'></span>
				<span className='visually-hidden'>Previous</span>
			</button>
			<button
				className='carousel-control-next'
				type='button'
				data-bs-target='#carouselExampleIndicators'
				data-bs-slide='next'>
				<span
					className='carousel-control-next-icon'
					aria-hidden='true'></span>
				<span className='visually-hidden'>Next</span>
			</button>
		</div>
	);
}

export default Hero;
