import React, { useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import MainContext from '../context/mainContext';
import Preloader from './common/preloader';
import Image from './common/image';
import CopyBtn from './common/copyBtn';
import Anchor from './common/anchor';
import { getState } from '../utils/legends';
import Divider from './divider';
import EcoModal from './dashboard/ecoModal';

function Seeds({ match }) {
	const { initData } = useContext(MainContext);
	if (!initData.charsDef) return <Preloader />;

	const { charsDef } = initData;
	const names = Object.keys(charsDef);
	const { name } = match.params;
	if (!name || !names.includes(name))
		return <Redirect to={`/seeds/${names[0]}`} />;
	const seed = charsDef[name];

	const states = [];
	for (let i = 0; i < (seed.group === 'legends' ? 5 : 3); i++)
		states.push(`00${i + 1}`);

	return (
		<>
			<Image fileName='seedsHeader.jpg' className='w-100' />
			<div className='container text-dark-brown'>
				<div className='row my-5'>
					<div className='col text-center'>
						<div className='d-flex align-items-center justify-content-center'>
							<Image
								height='35px'
								fileName='leaf.png'
								className='me-4'
							/>
							<div className='fs-3'>BCN GARDEN SEEDS</div>
						</div>
						<div className='fs-1'>
							<b>{seed.name.toUpperCase()}</b>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col'>
						<div className='mx-0 mx-md-5 d-flex flex-wrap align-items-center justify-content-evenly'>
							{states.map((s, key) => (
								<div
									className='text-center mx-5 mb-4'
									key={key}>
									<Image
										height={
											Number(s) <
											(seed.group === 'legends' ? 3 : 2)
												? '80px'
												: '200px'
										}
										fileName={`chars/${name}${s}.png`}
									/>
									<div className='text-center mt-3 fs-4'>
										{getState(s, seed.group)}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<Divider file='08' className='my-5' />
				<div className='row'>
					<div className='col-md-8 offset-md-2 col-12 text-break'>
						<div className='fs-4 mb-3'>
							<b>Introduction:</b>
						</div>
						{seed.introduction.map((i, key) => (
							<p key={key}>{i}</p>
						))}
						<p>
							Smart Contract:{' '}
							<Anchor
								href={`https://tronscan.org/#/token721/${seed.contract}`}
								target='_blank'
								content={seed.contract}
							/>
							<CopyBtn content={seed.contract} />
						</p>
						<EcoModal seed={seed} />
					</div>
				</div>
				<Divider file='08' className='my-5' />
				<div className='row mb-5'>
					<div className='col'>
						<div className='card seedsCard '>
							<div className='card-body'>
								<div className='text-center fs-2 my-4'>
									<b>Other Seeds</b>
								</div>
								<div className='row'>
									{names.map((n, key) => (
										<div
											key={key}
											className='col-md-2 col-4 mb-4 text-center'>
											<Link
												className=' mb-4'
												to={`/seeds/${n}`}>
												<Image
													className={
														name === n
															? 'shadowfilter'
															: 'graySeed'
													}
													height={'70px'}
													fileName={`chars/${n}001.png`}
												/>
												<div className='text-center mt-3 fs-6 text-link-color'>
													{n.toUpperCase()}
												</div>
											</Link>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Seeds;
