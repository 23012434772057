import React from 'react';

const Select = ({ name, label, options, error, formText, ...rest }) => {
	return (
		<div className='form-group'>
			<label htmlFor={name}>{label}</label>
			{formText && <div className='form-text mb-1'>{formText}</div>}
			<select name={name} id={name} {...rest} className='form-select'>
				<option value='' />
				{options.map((option, index) => (
					<option key={index} value={option}>
						{option}
					</option>
				))}
			</select>
			{error && <div className='alert alert-danger h6 p-2'>{error}</div>}
		</div>
	);
};

export default Select;
