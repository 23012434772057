import React from 'react';
import Anchor from '../common/anchor';
import Image from '../common/image';
import TimToken3d from '../models_3d/TimToken3d/index';

function TIM(props) {
	return (
		<div className='tim homeSection my-5 position-relative'>
			<div className='position-absolute top-50 start-0 translate-middle-y d-none d-xl-block'>
				{/* <Image fileName='home/timSpace.png' className='visible' /> */}
				<div
					style={{ minWidth: '400px', transform: 'rotateZ(-12deg)' }}>
					<TimToken3d />
				</div>
			</div>
			<div className='position-absolute bottom-0 end-0'>
				<Image
					fileName='home/khotan.png'
					className='w-100 d-none d-xl-block'
				/>
			</div>
			<div className='container-fluid'>
				<div className='row'>
					<div className='offset-xl-3 col-xl-5 mt-4 py-5'>
						<h1>
							<b>USE TIM TOKEN</b>
						</h1>
						<p>
							Programmable blockchains and smart contracts have
							revolutionized the way we think about data and
							security. However, due to their siloed nature, they
							often lack access to external data sources. This is
							where the TIM Token comes in, providing an easy way
							to inject external data into the blockchain and
							smart contracts. This enables developers to harness
							the vast amounts of data available on the WEB 2.0
							and build a whole new range of powerful DApps
							without compromising on security. <br />
							The TIM Token is unique in that it combines the
							power of blockchain with artificial intelligence
							capabilities, particularly in the field of
							agriculture. By implementing this technology in
							phase 1 of the game, everyone will be able to access
							this possibility, making it easier to build more
							useful and impactful DApps that benefit society as a
							whole. With the TIM Token, the possibilities for
							innovation are truly endless.
						</p>
						<Anchor
							className='rectBtn'
							href='https://timtoken.io'
							content={<span>Read more</span>}
						/>
					</div>
					<div className='offset-2 col-8 text-center d-block d-xl-none mb-5'>
						<TimToken3d />
					</div>
				</div>
			</div>
		</div>
	);
}

export default TIM;
