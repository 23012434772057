import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';
import Spinner from '../../../common/spinner';

class ProfileForm extends Form {
	state = {
		data: {
			wallet: this.props.wallet,
			username: this.props.username,
			uid: this.props.uid,
			email: this.props.email,
			fullName: this.props.fullName || '',
			country: this.props.country || '',
			nationalID: this.props.nationalID || '',
			phoneNumber: this.props.phoneNumber || '',
			password: this.props.password || '',
		},
		errors: {},
		dial_code: '+',
	};

	schema = {
		wallet: Joi.string().required().label('Wallet'),
		username: Joi.string().required().label('Username'),
		uid: Joi.string().required().label('BCN GARDEN UID'),
		email: Joi.string().email().required().label('Email'),
		fullName: Joi.string().required().label('Full Name'),
		country: Joi.string().required().label('Country'),
		nationalID: Joi.string().required().label('National ID / Passport No.'),
		phoneNumber: Joi.number().required().label('Phone Number'),
		password: Joi.string().required().label('Password'),
	};

	countryChanged = ({ currentTarget: text }) => {
		const { countries } = this.props;
		const country = countries.find((c) => c.name === text.value);
		this.setState({ dial_code: country.dial_code });
	};

	doSubmit = () =>
		this.props.onSubmit({
			...this.state.data,
			...{ dial_code: this.state.dial_code },
		});

	componentDidMount() {
		if (this.props.country) {
			const { countries } = this.props;
			const country = countries.find(
				(c) => c.name === this.props.country
			);
			this.setState({ dial_code: country.dial_code });
		}
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading, countries } = this.props;
		const { dial_code } = this.state;
		return (
			<form
				className='row text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='col-md-6 col-12 mb-3'>
					{this.renderInput({
						name: 'wallet',
						label: 'Wallet',
						type: 'text',
						disabled: true,
					})}
				</div>
				<div className='col-md-6 col-12 mb-3'>
					{this.renderInput({
						name: 'username',
						label: 'Username',
						type: 'text',
						disabled: true,
					})}
				</div>
				<div className='col-md-6 col-12 mb-3'>
					{this.renderInput({
						name: 'uid',
						label: 'BCN GARDEN ID',
						type: 'text',
						disabled: true,
					})}
				</div>
				<div className='col-md-6 col-12 mb-3'>
					{this.renderInput({
						name: 'email',
						label: 'Email',
						type: 'email',
						disabled: true,
					})}
				</div>
				<div className='col-md-6 col-12 mb-3'>
					{this.renderInput({
						name: 'fullName',
						label: 'Full Name',
						type: 'text',
					})}
				</div>
				<div className='col-md-6 col-12 mb-3'>
					{this.renderSelect({
						name: 'country',
						label: 'Country',
						options: countries.map((c) => c.name),
						extraHandler: this.countryChanged,
					})}
				</div>
				<div className='col-md-6 col-12 mb-3'>
					{this.renderInputGroup({
						name: 'phoneNumber',
						label: 'Phone Number',
						type: 'text',
						spanText: dial_code,
					})}
				</div>
				<div className='col-md-6 col-12 mb-3'>
					{this.renderInput({
						name: 'nationalID',
						label: 'National ID / Passport No.',
						type: 'text',
					})}
				</div>
				<div className='col-12 mb-3'>
					{this.renderInputGroupBtnEnd({
						name: 'password',
						label: 'Password',
						type: 'password',
						btnClass: `rectBtn`,
						btnText: <i className='fas fa-eye-slash'></i>,
						btnHandler: this.handleEyeClicked,
					})}
				</div>
				<div className='col-12 text-center'>
					{this.renderButton(
						<span>Update Profile</span>,
						`rectBtn`,
						isLoading && <Spinner content={'Updating...'} />
					)}
				</div>
			</form>
		);
	}
}

export default ProfileForm;
