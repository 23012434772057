import React from 'react';
import PropTypes from 'prop-types';
import useImage from '../../hooks/useImage';

const Image = ({ fileName, alt, className = '', ...rest }) => {
	const { loading, error, image } = useImage(fileName);

	if (error) return alt ? alt : '';

	return (
		<>
			{loading ? (
				loading
			) : (
				<img
					loading='lazy'
					className={className}
					src={image}
					alt={alt ? alt : fileName}
					type={fileName.split('.').slice(-1)[0]}
					{...rest}
				/>
			)}
		</>
	);
};

Image.propTypes = {
	fileName: PropTypes.string.isRequired,
	alt: PropTypes.string,
	className: PropTypes.string,
};

export default Image;
