const getState = (state, group) => {
	if (group === 'legends')
		switch (state) {
			case '001':
				return 'Seed';
			case '002':
				return 'Sapling';
			case '002w':
				return 'ُSapling, Withered';
			case '003':
				return 'Middle Age';
			case '003w':
				return 'Middle Age, Withered';
			case '004':
				return 'Mature';
			case '004w':
				return 'Mature, Withered';
			case '005':
				return 'Mature with Fruits';
			default:
				return '';
		}
	if (group === 'babies')
		switch (state) {
			case '001':
				return 'Seed';
			case '002':
				return 'Baby, Grown Up';
			case '002w':
				return 'Baby, Withered';
			case '003':
				return 'Baby with Fruits';
			default:
				return '';
		}
};

const getFruit = (name) => {
	switch (name) {
		case 'dwarf':
			return 'apple';
		case 'bdwarf':
			return 'apple';
		case 'hawnthorn':
			return 'sunberry';
		case 'bhawnthorn':
			return 'sunberry';
		case 'tfg':
			return 'pear';
		case 'btfg':
			return 'pear';
		case 'kerala':
			return 'banana';
		case 'bkerala':
			return 'banana';
		case 'khotan':
			return 'walnut';
		case 'bkhotan':
			return 'walnut';
		case 'pitaya':
			return 'dragonfruit';
		case 'bpitaya':
			return 'dragonfruit';
		default:
			return '';
	}
};

export { getState, getFruit };
