import React from 'react';
import Image from './../../common/image';

const HeaderImage = ({ fileName, shadeColor }) => {
	return (
		<div className='row'>
			<div className='col'>
				<div className='headerImageWrapper mb-4'>
					<Image
						className={`myImages headerImage w-100`}
						fileName={fileName}
					/>
					<Image
						className={`myImages headerImageShadow w-100 d-none d-md-block`}
						fileName={'headers/raw.jpg'}
					/>
				</div>
			</div>
		</div>
	);
};

export default HeaderImage;
