import React from 'react';

class GardenLight extends React.Component {
	componentDidMount() {
		window.location = '/light';
	}

	render() {
		return null;
	}
}

export default GardenLight;
