import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Joi from 'joi-browser';
import ImageUploading from 'react-images-uploading';
import DropArea from './dropArea';
import Input from './input';
import Select from './select';
import TextArea from './textArea';
import InputGroupBtnEnd from './inputGroupBtnEnd';
import InputGroup from './inputGroup';
import Radio from './radio';
import Spinner from './spinner';
import MyEditor from './editor';
import { capitalize } from 'lodash';

class Form extends Component {
	state = {
		data: {},
		errors: {},
	};

	validate = () => {
		const options = { abortEarly: false };
		const { error } = Joi.validate(this.state.data, this.schema, options);
		if (!error) return null;

		const errors = {};
		for (let item of error.details) errors[item.path[0]] = item.message;
		return errors;
	};

	validateProperty = ({ name, value }) => {
		const obj = { [name]: value };
		const schema = { [name]: this.schema[name] };
		const { error } = Joi.validate(obj, schema);
		return error ? error.details[0].message : null;
	};

	handleSubmit = (e) => {
		e.preventDefault();

		const errors = this.validate();
		this.setState({ errors: errors || {} });
		if (errors) return;

		this.doSubmit();
	};

	handleChange = ({ currentTarget: input }) => {
		const errors = { ...this.state.errors };
		const errorMessage = this.validateProperty(input);
		if (errorMessage) errors[input.name] = errorMessage;
		else delete errors[input.name];

		const data = { ...this.state.data };
		data[input.name] = input.value;

		if (input.type === 'checkbox') {
			const checkedNodes = document.querySelectorAll(
				'.form-check-input:checked'
			);
			const checkedValues = [];
			for (let i = 0; i < checkedNodes.length; i++)
				if (checkedNodes[i].name === input.name)
					checkedValues.push(checkedNodes[i].value);

			data[input.name] = checkedValues.length === 0 ? '' : checkedValues;
		}

		this.setState({ data, errors });
	};

	handleImageChange = (imageList, addUpdateIndex) => {
		const images = { ...this.state.images };
		images[this.state.currentImageName] = imageList;

		const data = { ...this.state.data };
		data[this.state.currentImageName] = imageList[0].file.name;

		this.setState({ images, data });
	};

	handleEditorChange = (raw, markup, name) => {
		const errors = { ...this.state.errors };
		let hasError = true;
		for (let i = 0; i < raw.blocks.length; i++)
			if (!!raw.blocks[i].text.trim()) {
				hasError = false;
				break;
			}
		if (hasError) errors[name] = `"${capitalize(name)}" can not be empty.`;
		else delete errors[name];

		const data = { ...this.state.data };
		data[name] = hasError ? '' : markup;

		this.setState({ data, errors });
	};

	handleEyeClicked = ({ currentTarget }) => {
		const inputElement = currentTarget.parentElement.children[0];
		const iconElement = currentTarget.parentElement.children[1].children[0];

		inputElement.type =
			inputElement.type === 'password' ? 'text' : 'password';

		iconElement.className =
			inputElement.type === 'password'
				? 'fas fa-eye-slash'
				: 'fas fa-eye';
	};

	renderButton(label, className, disabledLabel) {
		const disabledStatus = disabledLabel ? true : this.validate();
		return (
			<button disabled={disabledStatus} className={className}>
				{disabledLabel ? disabledLabel : label}
			</button>
		);
	}

	renderSelect({
		name,
		label,
		options,
		formText,
		disabled = false,
		extraHandler = undefined,
	}) {
		const { data, errors } = this.state;

		return (
			<Select
				name={name}
				value={data[name]}
				label={label}
				formText={formText}
				disabled={disabled}
				options={options}
				onChange={(e) => {
					this.handleChange(e);
					if (extraHandler) extraHandler(e);
				}}
				error={errors[name]}
			/>
		);
	}

	renderTextArea({
		name,
		label,
		rows = 3,
		formText,
		extraHandler = undefined,
		...rest
	}) {
		const { data, errors } = this.state;

		return (
			<TextArea
				rows={rows}
				name={name}
				value={data[name]}
				label={label}
				formText={formText}
				{...rest}
				onChange={(e) => {
					this.handleChange(e);
					if (extraHandler) extraHandler(e);
				}}
				error={errors[name]}
			/>
		);
	}

	renderInput({ name, type = 'text', extraHandler = undefined, ...rest }) {
		const { data, errors } = this.state;

		return (
			<Input
				type={type}
				name={name}
				autoComplete='off'
				autoCorrect='off'
				{...rest}
				value={data[name]}
				onChange={(e) => {
					this.handleChange(e);
					if (extraHandler) extraHandler(e);
				}}
				error={errors[name]}
			/>
		);
	}

	renderRadioInput({
		name,
		id,
		label,
		labelClass,
		extraHandler = undefined,
		...rest
	}) {
		return (
			<Radio
				name={name}
				id={id}
				label={label}
				labelClass={labelClass}
				{...rest}
				onChange={(e) => {
					this.handleChange(e);
					if (extraHandler) extraHandler(e);
				}}
			/>
		);
	}

	renderInputGroupBtnEnd({
		name,
		btnClass,
		btnText,
		btnHandler,
		btnDisable,
		type = 'text',
		extraHandler = undefined,
		...rest
	}) {
		const { data, errors } = this.state;

		return (
			<InputGroupBtnEnd
				type={type}
				name={name}
				btnClass={btnClass}
				btnText={btnText}
				btnHandler={btnHandler}
				btnDisable={btnDisable}
				autoComplete='off'
				autoCorrect='off'
				{...rest}
				value={data[name]}
				onChange={(e) => {
					this.handleChange(e);
					if (extraHandler) extraHandler(e);
				}}
				error={errors[name]}
			/>
		);
	}

	renderInputGroup({
		name,
		spanText,
		type = 'text',
		extraHandler = undefined,
		...rest
	}) {
		const { data, errors } = this.state;

		return (
			<InputGroup
				type={type}
				name={name}
				spanText={spanText}
				autoComplete='off'
				autoCorrect='off'
				{...rest}
				value={data[name]}
				onChange={(e) => {
					this.handleChange(e);
					if (extraHandler) extraHandler(e);
				}}
				error={errors[name]}
			/>
		);
	}

	renderEditor({ name, markup }) {
		const { errors } = this.state;

		return (
			<MyEditor
				name={name}
				markup={markup}
				onChange={this.handleEditorChange}
				error={errors[name]}
			/>
		);
	}

	renderFileUpload({
		name,
		label,
		maxFileSize,
		acceptType,
		formText,
		style,
		defaultImage,
		...rest
	}) {
		const handleChange = ({ currentTarget }) => {
			if (currentTarget.id)
				this.setState({ currentImageName: currentTarget.id });
		};
		return (
			<div id={name} onChange={handleChange} className='form-group'>
				<label>{label}</label>
				{formText && <div className='form-text mb-2'>{formText}</div>}
				<ImageUploading
					multiple={false}
					value={this.state.images[name]}
					onChange={this.handleImageChange}
					maxNumber={1}
					maxFileSize={maxFileSize}
					acceptType={acceptType}
					{...rest}
					dataURLKey='data_url'>
					{({
						imageList,
						onImageUpload,
						onImageRemoveAll,
						onImageUpdate,
						onImageRemove,
						isDragging,
						dragProps,
						errors,
					}) => (
						<div className='upload__image-wrapper'>
							<div className='mb-3' {...dragProps}>
								{imageList.length === 0 &&
									(defaultImage ? (
										<img
											onClick={onImageUpdate}
											src={defaultImage}
											alt=''
											className={style}
										/>
									) : (
										<DropArea
											dropAreaType={style}
											isDragging={isDragging}
											onClick={onImageUpload}
											{...dragProps}
										/>
									))}
								{imageList.map((image, index) => (
									<img
										key={index}
										onClick={onImageUpdate}
										src={image.data_url}
										alt=''
										className={style}
									/>
								))}
							</div>
							{errors && (
								<div className='alert alert-danger text-center'>
									{errors.maxNumber && (
										<span>
											Number of selected images exceeds to{' '}
											{1}
										</span>
									)}
									{errors.acceptType && (
										<span>
											Your selected file type is not
											allowed. Allowed types are:{' '}
											{acceptType.join('/')}
										</span>
									)}
									{errors.maxFileSize && (
										<span>
											File size must be {'<='}{' '}
											{maxFileSize / 1024 ** 2} MB
										</span>
									)}
									{errors.resolution && (
										<span>
											Selected file does not match the
											allowed resolution
										</span>
									)}
								</div>
							)}
						</div>
					)}
				</ImageUploading>
			</div>
		);
	}

	//specially for mmp.world
	renderSubmitBtnWithSPass({
		hasSP,
		isLoading,
		text,
		loadingText,
		divClass,
	}) {
		return (
			<>
				<div className={divClass}>
					{this.renderInputGroupBtnEnd({
						name: 'securityPassword',
						label: 'Security Password',
						type: 'password',
						btnClass: `btn btn-my-navy`,
						btnText: <i className='fas fa-eye-slash'></i>,
						btnHandler: this.handleEyeClicked,
						formText: (
							<Link
								className='btn btn-link'
								to={'/dashboard/myProfile/securityPassword'}>
								Forgot password?
							</Link>
						),
					})}
				</div>
				<div className={`${divClass} text-center`}>
					{hasSP ? (
						this.renderButton(
							text,
							`btn btn-lg btn-my-navy`,
							isLoading && <Spinner content={loadingText} />
						)
					) : (
						<Link
							className='text-danger fs-5'
							to={'/dashboard/myProfile/securityPassword'}>
							<em>Security Password</em> is not set, please set
							your <em>Security Password</em> and try again.
						</Link>
					)}
				</div>
			</>
		);
	}
}

export default Form;
