import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useShop() {
	const [shop, setShop] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();

	useEffect(() => {
		const allowed = ['/dashboard/amordadShop', '/dashboard/amordadshop'];

		async function getShop() {
			try {
				if (
					!http.getCurrentUser() ||
					shop ||
					!allowed.includes(pathname)
				)
					return;
				const { data } = await http.getShop();
				if (isMounted()) setShop(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		getShop();
	}, [isMounted, shop, pathname]);
	return [shop, setShop];
}
