import React from 'react';
import useDocumentTitle from '../../hooks/useDocumentTitle';

function TicketsContent(props) {
	useDocumentTitle('BCN GARDEN - TicketsContent');
	const { email, uid, ticket, wallet } = props.location.state;

	return (
		<div className='container'>
			<div className='row my-3'>
				<div className='col-12 text-center'>
					<h2>TicketsContent</h2>
					<hr />
				</div>
			</div>

			<div className='row my-5'>
				<div className='col-md-8 offset-md-2 col-12'>
					<p>
						<b>Email:</b> {email}
					</p>
					<p>
						<b>UID:</b> {uid}
					</p>
					<p>
						<b>Temporary MMP wallet:</b> {wallet}
					</p>
					<p>
						<b>Date:</b> {new Date(ticket.date).toLocaleString()}
					</p>
					<p>
						<b>Department:</b> <q>{ticket.department}</q>
					</p>
					<p>
						<b>Priority:</b> <q>{ticket.priority}</q>
					</p>
					<p>
						<b>Title:</b> <q>{ticket.title}</q>
					</p>
					<p>
						<b>Content:</b> <q>{ticket.content}</q>
					</p>
				</div>
			</div>
		</div>
	);
}

export default TicketsContent;
