import React, { Fragment } from 'react';
import Joi from 'joi-browser';
import Form from '../../common/form';
import Spinner from '../../common/spinner';

class ResourceForm extends Form {
	state = {
		data: {
			title: '',
			link: '',
			type: '',
			img: '',
		},
		errors: {},
		images: { img: [] },
		currentImageName: '',
		defaultImage: { img: null },
	};

	schema = {
		title: Joi.string()
			.min(this.props.titleConfig.min)
			.max(this.props.titleConfig.max)
			.regex(
				/^[a-zA-Z0-9- ]*$/,
				'Alphanumerics, dash and space characters.'
			)
			.required()
			.label('Title'),
		link: Joi.string().required().label('Link'),
		type: Joi.string()
			.valid(...this.props.types)
			.required()
			.label('Type'),
		img: Joi.string().required().label('Img'),
	};

	doSubmit = () => this.props.onSubmit(this.state);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const {
			isLoading,
			maxFileSize,
			acceptType,
			types,
			resolutionWidth,
			resolutionHeight,
			titleConfig,
		} = this.props;

		return (
			<Fragment>
				<small>
					<span className='text-danger'>*</span> Required fields
				</small>
				<form className='mt-3' onSubmit={this.handleSubmit}>
					<div className='row align-items-center'>
						<div className='col-md-6'>
							<div className='mb-4'>
								{this.renderInput({
									name: 'title',
									label: (
										<span>
											Title{' '}
											<span className='text-danger'>
												*
											</span>
										</span>
									),
									type: 'text',
									formText: `Title must be alphanumeric, minimum ${titleConfig.min} and maximum ${titleConfig.max} in length.`,
								})}
							</div>

							<div className='mb-4'>
								{this.renderInput({
									name: 'link',
									label: (
										<span>
											Link{' '}
											<span className='text-danger'>
												*
											</span>
										</span>
									),
									type: 'text',
									formText: 'Download link of the resource',
								})}
							</div>

							<div className='mb-4'>
								{this.renderSelect({
									name: 'type',
									label: (
										<span>
											Resource Type{' '}
											<span className='text-danger'>
												*
											</span>
										</span>
									),
									options: types,
								})}
							</div>
						</div>
						<div className='col-md-6'>
							<div className='mb-4'>
								{this.renderFileUpload({
									name: 'img',
									label: (
										<span>
											Resource Image{' '}
											<span className='text-danger'>
												*
											</span>
										</span>
									),
									maxFileSize,
									acceptType,
									formText: (
										<div>
											<span>
												Supported types are:{' '}
												{acceptType.join(', ')}
											</span>{' '}
											<br />
											<span>
												Max file size:{' '}
												{maxFileSize / 1024 ** 2} MB
											</span>{' '}
											<br />
											<span>
												Absolute Resolution:{' '}
												{resolutionWidth} x{' '}
												{resolutionHeight} (px)
											</span>{' '}
										</div>
									),

									style: 'item',
									defaultImage: this.state.defaultImage.img,
									resolutionType: 'absolute',
									resolutionWidth,
									resolutionHeight,
								})}
							</div>
						</div>
					</div>

					<div className='text-center mt-3'>
						<div>
							{this.renderButton(
								'Save resource',
								'btn btn-lg btn-my-navy',
								isLoading && (
									<Spinner content={'Saving resource...'} />
								)
							)}
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

export default ResourceForm;
