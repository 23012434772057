import React from 'react';
import { Redirect } from 'react-router-dom';
import useDocumentTitle from '../../hooks/useDocumentTitle';

function KYC(props) {
	useDocumentTitle('BCN GARDEN - KYC');
	return <Redirect to={'/comingSoon'} />;
}

export default KYC;
