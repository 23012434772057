import React from 'react';
import Divider from '../divider';
import Image from '../common/image';
import BcnGarden3d from '../models_3d/BcnGarden3d/index';

function About(props) {
	return (
		<>
			<div
				id='about'
				className='about homeSection mb-0 position-relative'>
				<div className='position-absolute top-50 translate-middle d-none d-lg-block'>
					<Image fileName='home/about.png' className='w-100' />
				</div>
				<div className='container-fluid'>
					<div className='row align-items-center'>
						<div className='offset-xl-3 col-xl-5 offset-lg-5 col-lg-6 mt-4 py-lg-5'>
							<div className='d-flex align-items-center mb-4'>
								<Image fileName='leaf.png' className='me-4' />
								<h1>About BCN GARDEN</h1>
							</div>

							<p className='px-3'>
								BCN Garden is a unique decentralized
								collectibles application built on the Tron
								blockchain. It strives to foster a symbiotic
								relationship between the worlds of gaming,
								blockchain technology, and philanthropy. At its
								core, BCN Garden is an engaging and immersive
								platform that offers a fun-filled experience for
								players. However, it also serves as an
								educational tool that raises awareness about
								pressing global issues, such as climate change
								and poverty. By highlighting the importance of
								compassion, hard work, and unders tanding, BCN
								Garden aims to inspire positive change and make
								a meaningful impact in the world.
							</p>
						</div>
						<div className='col-xl-4 col-12 py-lg-1 py-5'>
							<BcnGarden3d />
						</div>
					</div>
				</div>
			</div>
			<div className='container-fluid'>
				<Divider />
			</div>
		</>
	);
}

export default About;
