import axios from 'axios';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import config from '../config';

class Http {
	constructor(baseURL) {
		this.tokenKey = 'authToken';
		this.baseURL = baseURL;
		this.init_url = '/init';
		this.auth_url = '/auth';
		this.otp_url = '/auth/otp';
		this.admin_url = '/admin';
		this.farmers_url = '/farmers';
		this.legends_url = '/legends';
		this.users_url = '/users';
		this.wallet_url = '/wallet';
		this.blogs_url = '/blogs';
		this.resources_url = '/resources';
		this.contests_url = '/contests';

		axios.interceptors.request.use((config) => {
			config.headers['x-auth-token'] = this.getJwt();
			return config;
		});
		axios.interceptors.response.use(null, (error) => {
			const expectedError =
				error.response &&
				error.response.status >= 400 &&
				error.response.status < 500;

			const errorTooMany =
				error.response && error.response.status === 429;
			if (errorTooMany)
				toast.error('Too many requests, please try again later!');

			const authError = error.response && error.response.status === 401;
			if (authError) this.logout();

			if (!expectedError) console.error('An unexpected error occurred!');

			return Promise.reject(error);
		});
	}

	// Utils *****************************************************

	setJwt(jwt) {
		localStorage.setItem(this.tokenKey, jwt);
	}

	getJwt() {
		return localStorage.getItem(this.tokenKey);
	}

	removeJwt() {
		localStorage.removeItem(this.tokenKey);
	}

	getCurrentUser() {
		try {
			const token = localStorage.getItem(this.tokenKey);
			const user = jwtDecode(token);
			return user;
		} catch (ex) {}
	}

	request(url, method, data) {
		return axios.request({ baseURL: this.baseURL, url, method, data });
	}

	// Methods *****************************************************

	// init ***********************

	init() {
		return this.request(`${this.init_url}/main`);
	}

	getShop() {
		return this.request(`${this.init_url}/shop`);
	}

	// auth ***********************

	logout() {
		this.removeJwt();
		window.location = '/';
	}

	login(data) {
		return this.request(this.auth_url, 'post', data);
	}

	loginOTP(data) {
		return this.request(`${this.auth_url}/loginotp`, 'post', data);
	}

	checkID(data) {
		return this.request(`${this.auth_url}/checkID`, 'post', data);
	}

	registerActivation(data) {
		return this.request(
			`${this.auth_url}/registerActivation`,
			'post',
			data
		);
	}

	resendVC(data) {
		return this.request(`${this.auth_url}/resendVC`, 'post', data);
	}

	register(data) {
		return this.request(`${this.auth_url}/register`, 'post', data);
	}

	reauth() {
		return this.request(`${this.auth_url}/reauth`);
	}

	confirm(data) {
		return this.request(`${this.auth_url}/confirm`, 'post', data);
	}

	resetP(data) {
		return this.request(`${this.auth_url}/resetP`, 'post', data);
	}

	requestPR_VC(data) {
		return this.request(`${this.auth_url}/requestPR_VC`, 'post', data);
	}

	// otp ***********************

	generateOTP(data) {
		return this.request(`${this.otp_url}/generate`, 'post', data);
	}

	verifyOTP(data) {
		return this.request(`${this.otp_url}/verify`, 'post', data);
	}

	disableOTP(data) {
		return this.request(`${this.otp_url}/disable`, 'post', data);
	}

	enableOTP(data) {
		return this.request(`${this.otp_url}/enable`, 'post', data);
	}

	// admin ***********************

	getWithdrawReqs() {
		return this.request(`${this.admin_url}/getWithdrawReqs`);
	}

	getTickets() {
		return this.request(`${this.admin_url}/getTickets`);
	}

	closeTicket(data) {
		return this.request(`${this.admin_url}/closeTicket`, 'post', data);
	}

	// users ***********************

	updateProfile(data) {
		return this.request(`${this.users_url}/updateProfile`, 'post', data);
	}

	requestP_VC() {
		return this.request(`${this.users_url}/requestP_VC`);
	}

	updateP(data) {
		return this.request(`${this.users_url}/updateP`, 'post', data);
	}

	// farmer ***********************

	getMe() {
		return this.request(`${this.farmers_url}/me`);
	}

	getRanking() {
		return this.request(`${this.farmers_url}/rankings`);
	}

	purchase(data) {
		return this.request(`${this.farmers_url}/purchase`, 'post', data);
	}

	submitTicket(data) {
		return this.request(`${this.farmers_url}/submitTicket`, 'post', data);
	}

	// legends ***********************

	buy(data) {
		return this.request(`${this.legends_url}/buy`, 'post', data);
	}

	// wallet ***********************

	requestTopup(data) {
		return this.request(`${this.wallet_url}/requestTopup`, 'post', data);
	}

	cancelTopup(data) {
		return this.request(`${this.wallet_url}/cancelTopup`, 'post', data);
	}

	checkWallet(data) {
		return this.request(`${this.wallet_url}/checkWallet`, 'post', data);
	}

	withdrawRequest(data) {
		return this.request(`${this.wallet_url}/withdrawRequest`, 'post', data);
	}

	cancelWithdrawRequest(data) {
		return this.request(
			`${this.wallet_url}/cancelWithdrawRequest`,
			'post',
			data
		);
	}

	// blogs ***********************

	getAllBlogs() {
		return this.request(`${this.blogs_url}/all`);
	}

	createBlog(data) {
		return this.request(`${this.blogs_url}/create`, 'post', data);
	}

	getBlog(_id) {
		return this.request(`${this.blogs_url}/blog/${_id}`);
	}

	getBlogHome(_id) {
		return this.request(`${this.blogs_url}/blogHome/${_id}`);
	}

	getBlogDashboard(_id) {
		return this.request(`${this.blogs_url}/blogDashboard/${_id}`);
	}

	deleteBlog(data) {
		return this.request(`${this.blogs_url}/delete`, 'post', data);
	}

	editBlogImg(data, _id) {
		return this.request(`${this.blogs_url}/editImg/${_id}`, 'post', data);
	}

	editBlog(data, _id) {
		return this.request(`${this.blogs_url}/edit/${_id}`, 'post', data);
	}

	getAllHomeBlogs() {
		return this.request(`${this.blogs_url}/allHome`);
	}

	getAllDashboardBlogs() {
		return this.request(`${this.blogs_url}/allDashboard`);
	}

	// resources ***********************

	getAllResources() {
		return this.request(`${this.resources_url}/all`);
	}

	addResource(data) {
		return this.request(`${this.resources_url}/add`, 'post', data);
	}

	deleteResource(data) {
		return this.request(`${this.resources_url}/delete`, 'post', data);
	}

	getAllDashboardResources() {
		return this.request(`${this.resources_url}/allDashboardResources`);
	}
}

const http = new Http(config.server);
export default http;
