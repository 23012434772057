import React from 'react';

class Garden extends React.Component {
	componentDidMount() {
		window.location = '/garden';
	}

	render() {
		return null;
	}
}

export default Garden;
