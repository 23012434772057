import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';
import { numberWithCommas } from '../../../../utils/helpers';
import Spinner from '../../../common/spinner';
import http from '../../../../services/httpService';
import { toast } from 'react-toastify';

class WithdrawForm extends Form {
	state = {
		data: {
			amount: this.props.min,
			token: this.props.options.find((o) => o.checked).id,
			wallet: '',
			password: '',
		},
		errors: {},
		isCheckBtnLoading: false,
		isWalletValid: false,
		afterCheckMessage: 'A valid Tron address is required.',
	};

	schema = {
		token: Joi.string()
			.valid(...this.props.options.map((n) => n.id))
			.required()
			.label('Token'),
		amount: Joi.number()
			.min(this.props.min)
			.max(Math.min(this.props.balance, this.props.max))
			.multiple(this.props.step)
			.required()
			.label('Amount')
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case 'any.empty':
							err.message = 'Amount should not be empty!';
							break;
						case 'number.min':
							err.message = `Invalid amount, minimum amount is ${
								this.props.min
							} ${this.props.currentToken.id.toUpperCase()}.`;
							break;
						case 'number.max':
							err.message = `Invalid amount, maximum amount is ${Math.min(
								this.props.balance,
								this.props.max
							)} ${this.props.currentToken.id.toUpperCase()}.`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		wallet: Joi.string().required().label('Wallet'),
		password: Joi.string().required().label('Password'),
	};

	setSchema = () => {
		const { token } = this.state.data;
		const { min, max, step } = this.props.options.find(
			(o) => o.id === token
		);
		const balance = this.props.balances[token].available;
		this.schema.amount = Joi.number()
			.min(min / 1e6)
			.max(Math.min(balance / 1e6, max / 1e6))
			.multiple(step / 1e6)
			.required()
			.label('Amount')
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case 'any.empty':
							err.message = 'Amount should not be empty!';
							break;
						case 'number.min':
							err.message = `Invalid amount, minimum amount is ${
								min / 1e6
							} ${token.toUpperCase()}.`;
							break;
						case 'number.max':
							err.message = `Invalid amount, maximum amount is ${Math.min(
								balance / 1e6,
								max / 1e6
							)} ${token.toUpperCase()}.`;
							break;
						default:
							break;
					}
				});
				return errors;
			});
	};

	checkWalletHandler = async () => {
		const { wallet } = this.state.data;
		if (!wallet) return;

		try {
			this.setState({ isCheckBtnLoading: true });
			const { data } = await http.checkWallet({ wallet });
			const errors = { ...this.state.errors };
			delete errors.wallet;
			this.setState({
				errors,
				afterCheckMessage: data.message,
				isWalletValid: true,
			});
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 400) {
				const data = ex.response.data;
				this.setState({
					afterCheckMessage: data.message,
					isWalletValid: false,
				});
			}
			if (ex && ex.response && ex.response.data) {
				this.setState({ isWalletValid: false });
				return toast.error(ex.response.data);
			}
			if (ex && ex.message) {
				this.setState({ isWalletValid: false });
				return toast.error(ex.message);
			}
		} finally {
			this.setState({ isCheckBtnLoading: false });
		}
	};

	onWalletChanged = ({ currentTarget: wallet }) => {
		if (wallet.value)
			this.setState({
				afterCheckMessage:
					'Press the Check button to examine the entered wallet.',
				isWalletValid: false,
			});
		else
			this.setState({
				afterCheckMessage: 'A valid Tron address is required.',
				isWalletValid: false,
			});
	};

	doSubmit = () =>
		this.props.onSubmit({
			...this.state.data,
			...{ isWalletValid: this.state.isWalletValid },
		});

	optionChanged = ({ currentTarget: option }) => {
		const currentToken = this.props.options.find(
			(o) => o.id === option.value
		);
		this.props.setCurrentToken(currentToken);
		const state = { ...this.state };
		state.data.amount = currentToken.min / 1e6;
		state.data.token = option.value;
		this.setState(state);
		this.setSchema();
	};

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	componentDidMount() {
		this.setSchema();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.errors !== this.props.errors) {
			const state = { ...this.state };
			state.errors = this.props.errors;
			this.setState(state);
		}
		// if (prevProps.currentToken.id !== this.props.currentToken.id)
	}

	render() {
		const {
			isLoading,
			options,
			min,
			max,
			step,
			fee,
			currentToken,
			balance,
		} = this.props;
		const { isWalletValid, afterCheckMessage, isCheckBtnLoading } =
			this.state;
		const token = currentToken.id.toUpperCase();
		return (
			<form className='text-start' onSubmit={this.handleSubmit}>
				<div className='mb-4'>
					<p>Select Token:</p>
					<div className='d-flex justify-content-between'>
						{options.map((o, key) =>
							this.renderRadioInput({
								key,
								name: 'token',
								id: o.id,
								label: o.label,
								checked: o.checked,
								disabled: o.disabled,
								extraHandler: this.optionChanged,
							})
						)}
					</div>
				</div>
				<div className='mb-4'>
					{this.renderInput({
						name: 'amount',
						label: `Enter Amount (${token})`,
						type: 'number',
						min,
						max: Math.min(balance, max),
						step,
						formTextClass: 'text-main',
						formText: (
							<div className='d-flex flex-wrap justify-content-between'>
								<div>
									BG-Wallet Balance:{' '}
									<b>{numberWithCommas(balance)}</b> {token}
								</div>
								<div>
									Minimum amount is:{' '}
									<b>{numberWithCommas(min)}</b> {token}
								</div>
								<div>
									Maximum amount is:{' '}
									<b>
										{numberWithCommas(
											Math.min(balance, max)
										)}
									</b>{' '}
									{token}
								</div>
								<div>
									Withdrawal fee is:{' '}
									<b>{numberWithCommas(fee)}</b> {token}
								</div>
							</div>
						),
					})}
				</div>
				<div className='mb-4'>
					{this.renderInputGroupBtnEnd({
						name: 'wallet',
						label: 'Wallet Address',
						type: 'text',
						btnClass: `rectBtn`,
						extraHandler: this.onWalletChanged,
						btnText: isCheckBtnLoading ? (
							<Spinner content={'Checking...'} />
						) : (
							'Check'
						),
						btnDisable: isCheckBtnLoading || isLoading,
						btnHandler: this.checkWalletHandler,
						formText: (
							<div
								className={`text-my-${
									isWalletValid ? 'success' : 'danger'
								}`}>
								<span>{afterCheckMessage}</span>{' '}
								<i
									className={`fas fa-${
										isWalletValid ? 'check' : 'times'
									} me-2`}></i>
							</div>
						),
					})}
				</div>
				<div className='col-12 mb-3'>
					{this.renderInputGroupBtnEnd({
						name: 'password',
						label: 'Password',
						type: 'password',
						btnClass: `rectBtn`,
						btnText: <i className='fas fa-eye-slash'></i>,
						btnHandler: this.handleEyeClicked,
					})}
				</div>
				<div className='mb-4 text-center'>
					{this.renderButton(
						<span>Withdraw Request</span>,
						`rectBtn`,
						isLoading && <Spinner content={'Requesting...'} />
					)}
				</div>
			</form>
		);
	}
}

export default WithdrawForm;
