import React, { Fragment, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import MainContext from '../../../context/mainContext';
import http from '../../../services/httpService';
import HeaderImage from '../ingredients/headerImage';
import { TextCard } from '../ingredients/cards';
import Image from '../../common/image';
import CustomerSupportForm from './customerSupportForm';

const News = ({ tab }) => {
	const { userData, initData, onUserDataChanged } = useContext(MainContext);

	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.submitTicket(formData);
			toast.success(data.message);
			onUserDataChanged(data.data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Fragment>
			<HeaderImage fileName={`headers/${tab.id}.jpg`} />
			<TextCard
				titleClass={''}
				title={''}
				text={
					<>
						<div className='row'>
							<div className='col'>
								{userData.isTicketOpen ? (
									<div className='my-5 text-center'>
										<Image
											fileName={'warning.png'}
											className='mb-4'
										/>
										<h4>
											You Have an open ticket already.
										</h4>
										<h5>please check your email.</h5>
									</div>
								) : (
									<CustomerSupportForm
										department={
											initData.ticketsConfig.department
										}
										priority={
											initData.ticketsConfig.priority
										}
										titleConfig={
											initData.ticketsConfig.title
										}
										contentConfig={
											initData.ticketsConfig.content
										}
										hasSP={userData.hasSP}
										onSubmit={handleSubmit}
										isLoading={isLoading}
									/>
								)}
							</div>
						</div>
					</>
				}
			/>
		</Fragment>
	);
};

export default News;
