import React, { Fragment } from 'react';

const Accordion = ({ data, contentClass, buttonClass, ...rest }) => {
	return (
		<div
			{...rest}
			className='accordion accordion-flush'
			id='accordionFlush'>
			<div className={`accordion-item`}>
				{data.map((d, key) => (
					<Fragment key={key}>
						<h2
							className='accordion-header'
							id={`flush-heading-${d.id}`}>
							<button
								className={`accordion-button ${buttonClass} collapsed`}
								type='button'
								data-bs-toggle='collapse'
								data-bs-target={`#flush-collapse-${d.id}`}
								aria-expanded='false'
								aria-controls={`flush-collapse-${d.id}`}>
								{d.title}
							</button>
						</h2>
						<div
							id={`flush-collapse-${d.id}`}
							className='accordion-collapse collapse'
							aria-labelledby={`flush-heading-${d.id}`}>
							<div
								className={`accordion-body ${
									contentClass ? contentClass : ''
								}`}
								style={{ fontSize: '13px' }}>
								{d.content}
							</div>
						</div>
					</Fragment>
				))}
			</div>
		</div>
	);
};

export default Accordion;
