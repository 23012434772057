import { useEffect, useState } from 'react';

export default function useScreenWidth() {
	const getCurrentWidth = () => window.innerWidth;
	const [screenWidth, setScreenWidth] = useState(getCurrentWidth());

	useEffect(() => {
		const updateWidth = () => {
			setScreenWidth(getCurrentWidth());
		};

		window.addEventListener('resize', updateWidth);

		return () => {
			window.removeEventListener('resize', updateWidth);
		};
	}, [screenWidth]);

	return screenWidth;
}
