import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import MainContext from '../../../../context/mainContext';
import http from '../../../../services/httpService';
import Divider from '../../../divider';
import ChangeLoginPasswordForm from './changeLoginPasswordForm';

const ChangeLoginPassword = ({ history, tab }) => {
	const { userData, initData, onUserDataChanged } = useContext(MainContext);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [isEmailBtnLoading, setIsEmailBtnLoading] = useState(false);
	const isEmailRequestAllowed =
		Date.now() > userData.lastEmail + initData.emailGap;
	const counterDate = userData.lastEmail + initData.emailGap;

	const handlePUpdate = async (formData) => {
		if (formData.password !== formData.password_confirmation)
			return setErrors({
				password_confirmation: 'Passwords do not match',
			});
		try {
			const { password, vcCode } = formData;
			setIsLoading(true);
			const { data } = await http.updateP({
				password,
				vcCode,
			});
			setErrors({});
			toast.success(data.message);
			onUserDataChanged(data.data);
			setTimeout(() => history.replace('/dashboard'), 1000);
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 400) {
				const data = ex.response.data;
				if (typeof data === 'object') setErrors(data);
			}
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleEmailBtnClick = async () => {
		try {
			setIsEmailBtnLoading(true);
			const { data } = await http.requestP_VC();
			onUserDataChanged(data);
			toast.info('Eamil sent, please check your inbox');
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsEmailBtnLoading(false);
		}
	};

	const onRequestTimerCompleted = async () => {
		try {
			const { data } = await http.getMe();
			onUserDataChanged(data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	return (
		<>
			<Divider />
			<div className='row my-4'>
				<div className='col text-center'>
					<h4 className='fs-1'>Change Login Password</h4>
				</div>
			</div>
			<div className='row mt-4'>
				<div className='col-sm-4 offset-sm-4 col-12'>
					<p>
						Password must be strong. At least one upper case
						alphabet. At least one lower case alphabet. At least one
						digit. Minimum 8 and maximum 26 in length.
					</p>
					<ChangeLoginPasswordForm
						errors={errors}
						isLoading={isLoading}
						onSubmit={handlePUpdate}
						isEmailAllowed={isEmailRequestAllowed}
						counterDate={counterDate}
						onRequestTimerCompleted={onRequestTimerCompleted}
						isEmailBtnLoading={isEmailBtnLoading}
						onEmailRequest={handleEmailBtnClick}
					/>
				</div>
			</div>
			<Divider file='04' className='mt-5' />
		</>
	);
};

export default ChangeLoginPassword;
