import React from 'react';
import Image from './../common/image';

function Slogan(props) {
	return (
		<div className='container'>
			<div className='row align-items-center'>
				<div className='col-md-9 mt-4'>
					<Image className={'w-100'} fileName={'home/slogan.png'} />
				</div>
			</div>
		</div>
	);
}

export default Slogan;
