import { capitalize } from 'lodash';
import React from 'react';

const XPTable = ({ data }) => {
	return (
		<div className='table-responsive'>
			<table className='table table-sm myTable text-center'>
				<thead>
					<tr>
						<th>#</th>
						<th>Date</th>
						<th>Amount</th>
						<th>Description</th>
					</tr>
				</thead>
				<tbody>
					{data.map((d, key) => (
						<tr key={key}>
							<td>{d.no}</td>
							<td>{new Date(d.date).toLocaleString()}</td>
							<td>{parseInt(d.amount * 100) / 100}</td>
							<td>{capitalize(d.action)}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default XPTable;
