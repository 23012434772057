import React, { useContext } from 'react';
import MainContext from './../../context/mainContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { roundUpMinutes } from '../../utils/helpers';
import useWithdrawals from './../../hooks/useWithdrawals';
import Preloader from './../common/preloader';

function WithdrawRequests(props) {
	useDocumentTitle('BCN GARDEN - Withdraw Requests');
	const { initData } = useContext(MainContext);
	const [withdrawals] = useWithdrawals();
	if (!withdrawals) return <Preloader />;

	const { result, balance } = withdrawals;
	const users = result.sort((a, b) =>
		a.request.date > b.request.date ? 1 : -1
	);

	const total = users.reduce((a, b) => a + b.request.amount, 0);
	const isEnough = balance > total;

	return (
		<div className='container'>
			<div className='row my-3'>
				<div className='col-12 text-center'>
					<h2>Withdraw Requests</h2>
					<hr />
				</div>
			</div>
			<div className='row my-3'>
				<div className='col-12 text-center'>
					<h5 className='text-purple'>
						Hot Wallet Balance: <b>{balance / 1e6}</b> USDT
					</h5>
					<h5 className={`text-${isEnough ? 'success' : 'danger'}`}>
						Total Requests: <b>{total / 1e6}</b> USDT
					</h5>
				</div>
			</div>
			<div className='row my-5'>
				<div className='col-12 text-center'>
					<div className='table-responsive'>
						<table className='table table-sm myTable text-center'>
							<thead>
								<tr>
									<th>#</th>
									<th>UID</th>
									<th>Email</th>
									<th>Request Date</th>
									<th>Send Date</th>
									<th>Amount (USDT)</th>
								</tr>
							</thead>
							<tbody>
								{users.map((user, key) => (
									<tr key={key}>
										<td>{Number(key) + 1}</td>
										<td>{user.uid}</td>
										<td>{user.email}</td>
										<td>
											{new Date(
												user.request.date
											).toLocaleString()}
										</td>
										<td>
											{new Date(
												new Date(
													roundUpMinutes(
														user.request.date
													)
												).getTime() +
													initData.withdrawGap
											).toLocaleString()}
										</td>
										<td>{user.request.amount / 1e6}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default WithdrawRequests;
