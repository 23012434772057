const mmpworld = {
	image: 'mmpworld.jpg',
	path: '/mmpworld',
	name: 'mmpworld',
	title: (
		<span>
			The Market Maker Plans <br />
			MMP WORLD
		</span>
	),
	description: 'The MMP WORLD project is one of the projects of the BEM...',
	content: (
		<div className='container py-5'>
			<div id='mmp_main' className='row'>
				<div className='col-12'>
					<h2>MMP WORLD</h2>
					<p>
						The MMP WORLD project is one of the projects of the BEM
						Foundation that started its activity on August 12th,
						2022, under the laws of the BEM Foundation. This project
						will work on crowdfunding and market maker platforms,
						and its goal is market making for the projects of the
						BEM Foundation.
					</p>
					<p>
						Crowdfunding's goal is to collect money from a large
						number of people. In the crowdfunding of the MMP WORLD
						project, the reward model is used, and people will
						receive a reward for investing. The bigger their role in
						funding the projects and fundraising, the bigger their
						reward!
					</p>
					<p>
						The BINARY plan, the strongest marketing plan in the
						world, is used for the fundraising plan of the MMP WORLD
						project, which is based on progression and will speed up
						development. With this method, the foundation also
						ensures that all investors are thoroughly informed by
						the project's financial managers. This is on top of the
						activities of the project on social media.
					</p>
					<p>
						The raised funds will be used for market making of
						ready-for-work projects of BEM Foundation.
					</p>
					<p>
						What sets THE MMP PROJECT apart from other investment
						projects is that the BEM Foundation does not act as an
						investor or trader.
					</p>
					<p>
						The fund required for the creation and presentation of
						projects is provided from the personal budget of
						foundation managers.
					</p>
					<p>
						No trade is conducted with the funds because trading
						other currencies are very risky, and there has never
						been a successful trading project anywhere in the world.
						It is simply not possible and is doomed to fail.
					</p>
				</div>
			</div>

			<div id='mmp_projects' className='row pt-5'>
				<div className='col-12'>
					<h2>MMP WORLD Project</h2>
					<p>
						MMP WORLD offers comprehensive financial services and
						helps users to fund more ways to earn money.
					</p>
					<p>
						To start Its work in the market-making field, the MMP
						WORLD project has started by market making for BCN
						token, which is one of the ten ready projects of the BEM
						Foundation.
					</p>

					<p>
						<b>The financial system of MMP WORLD</b>
					</p>
					<ul>
						<li> Injecting funds to the market of BCN</li>
						<li> Trading in the market BCN</li>
						<li> Attracting holders for the BCN market</li>
						<li>
							Injecting funds into the liquidity pool of the BCN
							market
						</li>
						<li> Attracting traders for the BCN market</li>
						<li>
							{' '}
							Initializing the ready projects for the BCN market
						</li>
						<li> New listings for the BCN token</li>
						<li>
							Launching an ad system and airdrop system for the
							BCN market
						</li>
					</ul>
					<p>
						Raised funds will be used in different ways to develop
						the BCN token.
					</p>
					<p>
						<b>Buy BCN Token</b>
					</p>
					<p>
						A portion of the funds will be used to buy BCNs. This is
						initially done to provide financial support for the MMP
						WORLD project. Then it is continued to profit from the
						token's price surge (the price increases because of the
						project itself) to pay the monthly interests of the
						investors and reward the assisting managers.
					</p>
					<p>
						<b>Financial turnover and shaping the market</b>
					</p>
					<p>
						One of the requirements of a crypto market is the
						increasing number of transactions in any given 24 hours.
						This will enable traders to analyze the currency and
						conduct trading based on that.
					</p>
					<p>
						Part of the raised funds will constantly be used for
						trading BCN/TRX and BCN/USDT currency pairs.
					</p>
					<p>
						<b>Limiting the fluctuation range of the price</b>
					</p>
					<p>
						The funds will be used to keep the price at a specified
						range. When major sells or buys are taking place, market
						makers will conduct an opposite of the major sell or buy
						to maintain order in the market and prevent any fears or
						worries. This stabilizes the price. This operation will
						constantly be repeated, and its range will only change
						with an increase in the price. It is only done to
						prevent any changes in the market trend because of the
						actions of one or a few holders.
					</p>
					<p>
						<b>Increasing the inventory of the liquidity pool</b>
					</p>
					<p>
						Part of the funds will give depth to the market and
						increase the market liquidity. This fund will be used in
						two ways. First, the token will be bought with half of
						the funds, and the other half will be converted to the
						base currency. Both will be injected into the liquidity
						pool in equal amounts. This will increase the
						possibility of liquidity, and price impact will be
						reduced in trades.
					</p>
					<p>
						<b>Advertising to attract more holders and traders</b>
					</p>
					<p>
						The advertising process is one of the most effective
						ways to know the active people in the cryptocurrency
						market. Effective presence in different gatherings,
						campaigns, sponsoring, presence in different social
						media, cryptocurrency media, and creating technical
						content for source websites are all very effective in
						the development of a currency. Of course, it is very
						expensive to be active in these fields. Part of the
						raised funds will be used for social media-based
						development to significantly affect all the projects.
						More investors and traders are attracted each day.
					</p>
					<p>
						<b>Development</b>
					</p>
					<p>
						Another important element in developing a currency has
						it in different exchanges. Being listed on different
						exchanges will attract more holders and traders to the
						currency market, making it more legitimate and credible.
						Other than the need for a market-making budget, being
						listed in exchanges will need an initial fee.
					</p>
					<p>
						Part of the funds will be used to get listed on ranked
						exchanges and credible networks related to
						cryptocurrencies.
					</p>
					<p>
						<b>Note:</b> <br />
						<em>
							MMP WORLD project will not use the funds to pay the
							interests of the investors, reward assisting
							managers, or serve its own interests. After the
							development and expansion of any project, and then
							paying the interests of investors and rewarding the
							staff, the project will take the remaining profit,
							and part of it will be used for developing other
							projects.
						</em>
					</p>
				</div>
			</div>

			<div id='mmp_goals' className='row pt-5'>
				<div className='col-12'>
					<h2>MMP WORLD Goals</h2>
					<p>
						Start of the first market maker project for globalizing
						the BCN token
					</p>
					<ul>
						<li>
							Listing in the top 10 exchanges of the Coin Market
							Cap
						</li>
						<li> Price: + $ 0.10</li>
						<li> Market cap: + $ 50.000.000 </li>
						<li> 24 hours trading volume: + $ 5.000.000</li>
						<li> Liquidity pool: + $ 1.000.000</li>
						<li> Holders: + 2M</li>
						<li> Telegram member: + 500K</li>
						<li> Twitter: + 1M</li>
					</ul>
				</div>
			</div>
		</div>
	),
};

export default mmpworld;
