import React from 'react';
import { SpinnerInfinity } from 'spinners-react';

const Preloader = () => {
	return (
		<div className='vh-100 d-flex justify-content-center align-items-center'>
			<SpinnerInfinity
				size={90}
				thickness={180}
				speed={100}
				color='#008105'
				secondaryColor='#73471395'
			/>
		</div>
	);
};

export default Preloader;
