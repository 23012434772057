import React, { useContext } from 'react';
import Countdown from 'react-countdown';
// import ReactCardFlip from 'react-card-flip';
import { Link } from 'react-router-dom';
import styles from '../../../css/dashboard/ingredients.module.css';
import { DoughnutChart } from '../../common/charts';
import Image from '../../common/image';
import MainContext from './../../../context/mainContext';
import { numberWithCommas } from './../../../utils/helpers';
import ListGroup from './../../common/listGroup';
import Pagination from './../../common/pagination';
import BalanceChart from './balanceChart';
import BalanceTable from './balanceTable';
import BinaryTable from './binaryTable';
import CMCTable from './cmcTable';
import DownlinesTable from './downlinesTable';
import GoProTable from './goProTable';
import HistoryTable from './historyTable';
import IncomeChart from './incomeChart';
import IncomeTable from './incomeTable';
import InvestmentHistoryTable from './investmentHistoryTable';
import SellHistoryTable from './sellHistoryTable';
import TransactionsTable from './transactionsTable';
import TransferHistoryTable from './transferHistoryTable';
import UsersChart from './usersChart';
import WalletTransactiosTable from './walletTransactionsTable';
import WithdrawHistoryTable from './withdrawHistoryTable';

const NormalCard = ({ content }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>{content}</div>
		</div>
	);
};

const TextCard = ({ titleClass = '', title = '', text }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				<div className=''>
					<h4 className={titleClass}>{title}</h4>
					<div>{text}</div>
				</div>
			</div>
		</div>
	);
};

const WalletCard = ({ wallet, title, amount, type, bcn_usdt_rate }) => {
	const InnerComp = (
		<div className={`${styles.walletImageWrapper}`}>
			<div className={`${styles.walletImage}`}>
				<div
					className={`mb-4 card ${styles.walletCards} ${styles[wallet]}`}>
					<div className={`card-body`}>
						<Image
							height={'50px'}
							fileName={`wallets/icons/${wallet}.svg`}
						/>
						<div className='mt-2 fs-6'>{title} Wallet</div>
					</div>

					<hr className='mx-2 my-0' />
					<div className={`card-img-bottom`}>
						<div className='py-1 px-2 text-end me-2'>
							<b className='fs-4'>
								{numberWithCommas(Number(amount.toFixed(2)))}{' '}
								USDT
							</b>
							<div style={{ fontSize: '12px' }}>
								{numberWithCommas(
									Number((amount * bcn_usdt_rate).toFixed(2))
								)}{' '}
								BCN
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Image
				className={`myImages ${styles.walletImageShadow} w-100 d-none d-md-block`}
				fileName={`wallets/${wallet}.jpg`}
			/> */}
		</div>
	);
	return type === 'main' || type === 'voucher' ? (
		<Link to={`/dashboard/walletHistory/${wallet}History`}>
			{InnerComp}
		</Link>
	) : (
		InnerComp
	);
};

const CMCCard = ({ data }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				<h4 className='text-center'>
					{' '}
					<Image height={'40'} fileName={'icons/cmc.png'} /> Latest
					Prices
				</h4>
				<CMCTable tickers={data} />
			</div>
		</div>
	);
};

const CryptoDepositHistoryCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				{data.length > 0 ? (
					<>
						<HistoryTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</>
				) : (
					<div className='text-center'>
						<Image height={'180px'} fileName={'icons/noData.png'} />
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const WalletHistoryCard = ({
	name,
	balance,
	bcn_balance,
	data,
	image,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				<h4 className='d-flex align-items-center justify-content-center fs-1 mt-5 mb-3'>
					<Image
						className={'me-3'}
						height={'90px'}
						fileName={`icons/menu/${image}.svg`}
					/>
					<span>{name} Wallet</span>
				</h4>
				<div className='text-center fs-4'>
					Current Balance: {balance}
				</div>
				<div className='text-center'>({bcn_balance} BCN)</div>
				<hr className='my-4' />
				{data.length > 0 ? (
					<div className='mb-5'>
						<WalletTransactiosTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</div>
				) : (
					<div className='text-center mb-5'>
						<Image height={'180px'} fileName={'icons/noData.png'} />
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const GoProCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
	date,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				<h4 className='text-center fs-1 mt-5 mb-3'>
					Go Pro 1 Tournament
				</h4>

				<div className='text-center'>
					<Countdown
						className='h2'
						onComplete={() => window.location.reload()}
						date={date}
					/>
				</div>

				<hr className='my-4' />
				{data.length > 0 ? (
					<div className='mb-5'>
						<GoProTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</div>
				) : (
					<div className='text-center mb-5'>
						<Image height={'180px'} fileName={'icons/noData.png'} />
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const InvestmentHistoryCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				{data.length > 0 ? (
					<>
						<InvestmentHistoryTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</>
				) : (
					<div className='text-center'>
						<Image height={'180px'} fileName={'icons/noData.png'} />
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const TransferHistoryCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				{data.length > 0 ? (
					<>
						<TransferHistoryTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</>
				) : (
					<div className='text-center'>
						<Image height={'180px'} fileName={'icons/noData.png'} />
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const SellHistoryCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				{data.length > 0 ? (
					<>
						<SellHistoryTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</>
				) : (
					<div className='text-center'>
						<Image height={'180px'} fileName={'icons/noData.png'} />
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const WithdrawHistoryCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				{data.length > 0 ? (
					<>
						<WithdrawHistoryTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</>
				) : (
					<div className='text-center'>
						<Image height={'180px'} fileName={'icons/noData.png'} />
						<h4 className='mt-4'>No Data</h4>
					</div>
				)}
			</div>
		</div>
	);
};

const DownlinesCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
	userTypes,
	selectedUserType,
	sortColumn,
	onTypeSelect,
	onSort,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				<h3 className='text-center my-3'>My Downlines</h3>
				<div className='row '>
					<div className='col-sm-2 mb-3'>
						<h4 className='text-center mb-2'>Filters</h4>
						<ListGroup
							customClass={`${styles.myListGroupItem}`}
							activeClass={styles.myListGroupItemActive}
							items={userTypes}
							selectedItem={selectedUserType}
							onItemSelect={onTypeSelect}
							textProperty={'content'}
						/>
					</div>
					<div className='col'>
						<DownlinesTable
							users={data}
							sortColumn={sortColumn}
							onSort={onSort}
						/>
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
						<p>
							Users Count: <b>{itemsCount}</b>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const BinaryCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
	chartData,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				<h3 className='text-center my-3'>Binary Activity Statistics</h3>
				<hr />
				<div className='row align-items-center'>
					<div className='col-sm-5 mb-3'>
						<BalanceChart data={chartData} />
					</div>
					<div className='col'>
						<BinaryTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
						<p>
							Active Days: <b>{itemsCount}</b>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const IncomeCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
	chartData,
}) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				<div className='row align-items-center'>
					<div className='col-sm-3 mb-3'>
						<DoughnutChart chartData={chartData} />
					</div>
					<div className=' offset-sm-1 col'>
						<IncomeTable data={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const LabelCard = ({ titleClass, title, text }) => {
	return (
		<div className={`mb-4 card myCards`}>
			<div className={`card-body`}>
				<div className=''>
					<h4 className={titleClass}>{title}</h4>
					<p className='text-center'>{text}</p>
				</div>
			</div>
		</div>
	);
};

const CardRight = ({ color, icon, image, title, text }) => {
	return (
		<div className={`mb-4 card ${styles.myCard} ${styles[`${color}BG`]}`}>
			<div className={`card-body`}>
				<div className='nav align-items-center justify-content-between'>
					{icon && (
						<i
							className={`${styles.fade5} ${styles.iconLG} ${icon}`}></i>
					)}
					{image && <Image fileName={image} height={'180px'} />}
					<div className='text-end'>
						<h4>{title}</h4>
						<p>{text}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const CardLeft = ({ color, icon, image, title, text }) => {
	return (
		<div className={`mb-4 card ${styles.myCard} ${styles[`${color}BG`]}`}>
			<div className={`card-body`}>
				<div className='nav align-items-center justify-content-between'>
					<div>
						<h4>{title}</h4>
						<p>{text}</p>
					</div>
					{icon && (
						<i
							className={`${styles.fade5} ${styles.iconLG} ${icon}`}></i>
					)}
					{image && <Image fileName={image} height={'180px'} />}
				</div>
			</div>
		</div>
	);
};

const CardCenter = ({ color, titleColor, title, text }) => {
	return (
		<div className={`mb-4 card ${styles.myCard} ${styles[`${color}BG`]}`}>
			<div className={`card-body`}>
				<div className='text-center'>
					<h4 className={`${styles[titleColor]}`}>
						<b>{title}</b>
					</h4>
					<p>{text}</p>
				</div>
			</div>
		</div>
	);
};

const CardCenterHasTheme = ({ titleColor, title, text }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className={`card-body`}>
				<div className='text-center'>
					<h4 className={`${styles[titleColor]}`}>
						<b>{title}</b>
					</h4>
					<div>{text}</div>
				</div>
			</div>
		</div>
	);
};

const IconCard = ({ color, icon, image, title, text }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className={`card-body`}>
				<div className='nav align-items-center justify-content-between'>
					{icon && (
						<i
							className={`${styles.icon} ${icon} ${styles[color]}`}></i>
					)}
					{image && <Image fileName={image} height={'60'} />}
					<div className='text-end'>
						<h4>{title}</h4>
						<p className={` ${styles[color]}`}>{text}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

const BalanceChartCard = ({ data }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className={`card-body`}>
				<h4 className='text-center'>Weekly Gained Balance</h4>
				<BalanceChart data={data} />
			</div>
		</div>
	);
};

const IncomeChartCard = ({ data }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className={`card-body`}>
				<h4 className='text-center'>My Total Income</h4>
				<IncomeChart incomeData={data} />
			</div>
		</div>
	);
};

const UsersChartCard = ({ data }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className={`card-body`}>
				<h4 className='text-center'>Monthly User Registers</h4>
				<UsersChart data={data} />
			</div>
		</div>
	);
};

const TransactionsCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
	txTypes,
	selcetedTxType,
	handleTypeSelect,
}) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className={`card-body`}>
				<h4 className='text-center'>
					{' '}
					<Image
						height={'50'}
						fileName={'icons/icons8_general_ledger_96px.png'}
					/>{' '}
					Transactions History
				</h4>
				<div className='row '>
					<div className='col-sm-2 mb-3'>
						<h4 className='text-center mb-2'>Filters</h4>
						<ListGroup
							customClass={`${styles.myListGroupItem}`}
							activeClass={styles.myListGroupItemActive}
							items={txTypes}
							selectedItem={selcetedTxType}
							onItemSelect={handleTypeSelect}
							textProperty={'content'}
						/>
					</div>
					<div className='col'>
						<TransactionsTable txs={data} />
						<Pagination
							customClass={`mt-1 ${styles.myPagination}`}
							activeClass={styles.myActive}
							totalCount={itemsCount}
							pageSize={pageSize}
							currentPage={currentPage}
							onPageChange={onPageChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const BalanceCard = ({
	data,
	pageSize,
	currentPage,
	itemsCount,
	onPageChange,
}) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className={`card-body`}>
				<h4 className='text-center'>
					{' '}
					<Image
						height={'50'}
						fileName={'icons/icons8_scales_96px.png'}
					/>{' '}
					Weeky Balance ({itemsCount} weeks of activity)
				</h4>
				<BalanceTable weeks={data} />
				<Pagination
					customClass={`mt-1 ${styles.myPagination}`}
					activeClass={styles.myActive}
					totalCount={itemsCount}
					pageSize={pageSize}
					currentPage={currentPage}
					onPageChange={onPageChange}
				/>
			</div>
		</div>
	);
};

const PoolCard = ({ titleColor, secondColor, pool }) => {
	const { theme } = useContext(MainContext);
	const {
		poolPercentage,
		poolTitle,
		inventory,
		condition,
		members,
		balance,
		percentage,
		rewardReceived,
		completed,
		share,
	} = pool;
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className={`card-body`}>
				<div className='text-center'>
					<h3 className='mb-3'>
						<Image
							height={'45'}
							fileName={'icons/icons8_gold_pot_96px.png'}
							className={'me-2'}
						/>
						{poolTitle} Pool:
						<span className={`${styles[titleColor]}`}>
							{' '}
							<b>{poolPercentage}</b>{' '}
						</span>
						%
					</h3>
					<p>
						Pool Inventory:
						<span className={`${styles[titleColor]}`}>
							{' '}
							{inventory}{' '}
						</span>
						BCN
					</p>
					<p>
						Receipt Condition:
						<span className={`${styles[titleColor]}`}>
							{' '}
							{condition}{' '}
						</span>
						Balance
					</p>
					<p>
						This Month Total Members:
						<span className={`${styles[titleColor]}`}>
							{' '}
							{members}{' '}
						</span>
					</p>
					<div>
						<h4>Your {poolTitle} Pool Progress</h4>
						<div className={`mb-3 ${styles[secondColor]}`}>
							Total Balance:
							<span> {balance} </span>(<span> {percentage} </span>
							%)
						</div>
						<div className='progress'>
							<div
								className='progress-bar progress-bar-striped progress-bar-animated bg-info'
								role='progressbar'
								aria-valuenow={percentage}
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ width: percentage + '%' }}></div>
						</div>
						<p className='mt-3'>
							{poolTitle} Pool Completion:
							<Image
								height={'30'}
								fileName={`icons/${completed}.png`}
							/>
						</p>
						<p>
							{poolTitle} Pool Reward Received:
							<Image
								height={'30'}
								fileName={`icons/${rewardReceived}.png`}
							/>
						</p>
						{!rewardReceived && (
							<p>
								Your Share:
								<span className={`${styles[titleColor]}`}>
									{' '}
									{share}
								</span>
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const GoldenPoolCard = ({ titleColor, balance, percentage }) => {
	const { theme } = useContext(MainContext);
	return (
		<div className={`mb-4 card ${styles[`${theme}Card`]}`}>
			<div className={`card-body`}>
				<div className='text-center'>
					<Image
						height={'100'}
						fileName={'icons/icons8_bitcoin_accepted_96px.png'}
						className={'me-2'}
					/>
					<h3 className='mb-3'>Golden Reward</h3>
					<div>
						<h4 className='mb-3'>Your Golden Reward Progress</h4>
						<div className='progress'>
							<div
								className='progress-bar progress-bar-striped progress-bar-animated bg-success'
								role='progressbar'
								aria-valuenow={percentage}
								aria-valuemin='0'
								aria-valuemax='100'
								style={{ width: percentage + '%' }}></div>
						</div>
						<div className={`mt-3 ${styles[titleColor]}`}>
							Total Balance:
							<span> {balance} </span>(<span> {percentage} </span>
							%)
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export {
	BalanceCard,
	BalanceChartCard,
	BinaryCard,
	CMCCard,
	CardCenter,
	CardCenterHasTheme,
	CardLeft,
	CardRight,
	CryptoDepositHistoryCard,
	DownlinesCard,
	GoProCard,
	GoldenPoolCard,
	IconCard,
	IncomeCard,
	IncomeChartCard,
	InvestmentHistoryCard,
	LabelCard,
	NormalCard,
	PoolCard,
	SellHistoryCard,
	TextCard,
	TransactionsCard,
	TransferHistoryCard,
	UsersChartCard,
	WalletCard,
	WalletHistoryCard,
	WithdrawHistoryCard,
};
