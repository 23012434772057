import React, { Fragment, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import MainContext from '../context/mainContext';
import http from './../services/httpService';
import config from './../config';
import Image from './common/image';

const items = {
	app: {
		navLinks: [
			{ name: 'Home', to: '/' },
			{ name: 'Dashboard', to: '/dashboard' },
			{ name: 'Garden', to: '/garden' },
			{ name: 'Garden (Light)', to: '/light' },
			{ name: 'Seeds', to: '/seeds' },
		],
		hashLinks: [{ name: 'About', to: '/#about' }],
	},
	backOffice: {
		navLinks: [
			{ name: 'Home', to: '/' },
			{ name: 'Blogs', to: '/blogs' },
			{ name: 'Resources', to: '/resources' },
			{ name: 'KYC', to: '/kyc' },
			{ name: 'Withdrawals', to: '/withdrawals' },
			{ name: 'Tickets', to: '/tickets' },
		],
		hashLinks: [],
	},
};

const NavBar = ({ isGlobal }) => {
	const { onLogout, onHashLinkClick } = useContext(MainContext);

	const handleClick = () => {
		if (window.matchMedia('(max-width: 992px)').matches) {
			const myCollapse = document.getElementById(
				'navbarSupportedContent'
			);
			new bootstrap.Collapse(myCollapse, { show: false });
		}
	};

	if (!isGlobal) return null;
	return (
		<nav
			id='myNav'
			className='navbar navbar-expand-lg navbar-light bg my-3 rounded-5 position-relative'>
			<Link
				className='navbar-brand position-absolute top-50 start-0 translate-middle d-none d-lg-block'
				to='/'>
				<Image
					className='d-inline-block align-top'
					fileName={`logo.png`}
					height='85px'
				/>
			</Link>
			<div className='container-fluid mx-lg-4'>
				<Link className='navbar-brand d-block d-lg-none' to='/'>
					<Image
						className='d-inline-block align-top'
						fileName={`logo.png`}
						height='50'
					/>
				</Link>
				<button
					className='navbar-toggler'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarSupportedContent'
					aria-controls='navbarSupportedContent'
					aria-expanded='false'
					aria-label='Toggle navigation'>
					<i className='text-dark-brown fas fa-bars fa-lg'></i>
				</button>
				<div
					className='collapse navbar-collapse'
					id='navbarSupportedContent'>
					<ul className='navbar-nav me-auto mb-2 mb-lg-0'>
						{items[config.domain].navLinks.map((i, key) => (
							<li key={key} className='nav-item'>
								<NavLink
									onClick={handleClick}
									className='nav-link'
									to={i.to}>
									{i.name}
								</NavLink>
							</li>
						))}
						{items[config.domain].hashLinks.map((i, key) => (
							<li key={key} className='nav-item'>
								<NavHashLink
									className='nav-link'
									smooth
									scroll={(el) => onHashLinkClick(el)}
									to={i.to}>
									{i.name}
								</NavHashLink>
							</li>
						))}
					</ul>

					<ul className='navbar-nav align-items-center'>
						{http.getCurrentUser() ? (
							<li className='nav-item'>
								<div className='nav-link'>
									<button
										type='button'
										className={`rectBtn`}
										onClick={onLogout}>
										<div className='d-flex align-items-center'>
											<i className='fa fa-power-off me-1'></i>
											<span>Logout</span>
										</div>
									</button>
								</div>
							</li>
						) : (
							<Fragment>
								<li className='nav-item'>
									<NavLink
										className='nav-link btnLink'
										to='/login'>
										<button
											type='button'
											onClick={handleClick}
											className='rectBtn'>
											<span>Login</span>
										</button>
									</NavLink>
								</li>
								{config.domain === 'app' && (
									<li className='nav-item'>
										<NavLink
											className='nav-link btnLink'
											to='/register'>
											<button
												type='button'
												onClick={handleClick}
												className='rectBtn'>
												<span>Register</span>
											</button>
										</NavLink>
									</li>
								)}
							</Fragment>
						)}
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default NavBar;
