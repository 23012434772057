import { lazy, Suspense } from 'react';
import useScreenWidth from '../../../hooks/useScreenWidth';
const BcnGardenHigh = lazy(() => import('./BcnGardenHigh'));
const BcnGardenLow = lazy(() => import('./BcnGardenLow'));

const BcnGarden3d = ({
	width = '100%',
	height = '100%',
	scale = 6.8,
	zAngle = 0,
	dist = 4,
	fov = 25,
	minVerticalTilt = -10,
	maxVerticalTilt = 10,
	minHorizontalTilt = -20,
	maxHorizontalTilt = 20,
}) => {
	const screenWidth = useScreenWidth();

	return (
		<Suspense fallback={null}>
			{screenWidth > 768 ? (
				<BcnGardenHigh
					width={width}
					height={height}
					scale={scale}
					zAngle={zAngle}
					dist={dist}
					fov={fov}
					minVerticalTilt={minVerticalTilt}
					maxVerticalTilt={maxVerticalTilt}
					minHorizontalTilt={minHorizontalTilt}
					maxHorizontalTilt={maxHorizontalTilt}
				/>
			) : (
				<BcnGardenLow
					width={width}
					height={height}
					scale={scale}
					zAngle={zAngle}
					dist={dist}
					fov={fov}
					minVerticalTilt={minVerticalTilt}
					maxVerticalTilt={maxVerticalTilt}
					minHorizontalTilt={minHorizontalTilt}
					maxHorizontalTilt={maxHorizontalTilt}
				/>
			)}
		</Suspense>
	);
};

export default BcnGarden3d;
