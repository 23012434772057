import { useEffect } from 'react';
import socket from '../services/socketService';

export default function useWatchPrice(setInitData) {
	useEffect(
		() =>
			socket.socket.on('pricePong', (data) =>
				setInitData((prevData) => ({ ...prevData, ...data }))
			),
		[setInitData]
	);
}
