import React, { useContext, useState } from 'react';
import config from '../../../config';
import MainContext from '../../../context/mainContext';
import { paginate } from '../../../utils/paginate';
import Divider from '../../divider';
import XPTable from '../ingredients/xpTable';
import Pagination from '../../common/pagination';
import NoData from '../ingredients/noData';

const XPHistory = ({ tab }) => {
	const { userData } = useContext(MainContext);

	const [currentPage, setCurrentPage] = useState(1);
	const { earnedXP, totalXP } = userData;

	const sorted = earnedXP.sort((a, b) => (a.date > b.date ? -1 : 1));
	for (const i in sorted) sorted[i].no = Number(i) + 1;

	const data = paginate(sorted, currentPage, config.tablePageSize);

	return (
		<>
			<Divider />
			{earnedXP.length > 0 ? (
				<>
					<div className='row mb-4'>
						<div className='col text-center'>
							<h4 className='fs-1'>XP History</h4>
							<h4 className='fs-3'>
								Total Score: {totalXP.toFixed(1)}
							</h4>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-8 offset-md-2'>
							<XPTable data={data} hashColor='#87c8ff' />
							<Pagination
								customClass='mt-1 myPagination'
								activeClass='myActive'
								totalCount={sorted.length}
								pageSize={config.tablePageSize}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</div>
					</div>
				</>
			) : (
				<NoData />
			)}
			<Divider file='07' className='mt-5' />
		</>
	);
};

export default XPHistory;
