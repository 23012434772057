import { useState, useEffect } from 'react';

export default function useIsLoaded() {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		if (document.readyState === 'complete') setIsLoaded(true);
		else window.addEventListener('load', () => setIsLoaded(true));

		return () =>
			window.removeEventListener('load', () => setIsLoaded(true));
	}, []);

	return isLoaded;
}
