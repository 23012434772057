import React from 'react';
import Image from '../../common/image';

function NoData({ className = 'my-5' }) {
	return (
		<div className={`row ${className}`}>
			<div className='col-12 text-center'>
				<Image height={'150px'} fileName={'icons/noData.png'} />
				<h4 className='mt-4'>No Data</h4>
			</div>
		</div>
	);
}

export default NoData;
