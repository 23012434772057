import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Redirect, Link } from 'react-router-dom';
import MainContext from '../../context/mainContext';
import useDocumentTitle from './../../hooks/useDocumentTitle';
import { getQueries } from '../../utils/helpers';
import http from '../../services/httpService';
import LoginForm from './loginForm';
import Image from './../common/image';
import OTPForm from './otpForm';
import Divider from '../divider';

const Login = (props) => {
	useDocumentTitle('BCN GARDEN - Login');

	const { email } = getQueries();
	const { onUserDataChanged } = useContext(MainContext);
	const [isLoading, setIsLoading] = useState(false);
	const [needsOTP, setNeedsOTP] = useState(false);
	const [credentials, setCredentials] = useState(null);

	const { state } = props.location;
	const prevPath = state && state.from ? state.from.pathname : '/';

	const doSubmit = async (formData) => {
		try {
			setIsLoading(true);
			const { data, headers } = await http.login({
				email: formData.email.trim(),
				password: formData.password.trim(),
			});
			if (data === 'otp') {
				setNeedsOTP(true);
				setCredentials({
					email: formData.email.trim(),
					password: formData.password.trim(),
				});
			} else {
				onUserDataChanged(data);
				http.setJwt(headers['x-auth-token']);
			}
			setIsLoading(false);
		} catch (ex) {
			console.log(ex);
			setIsLoading(false);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	const doOTPSubmit = async (formData) => {
		try {
			setIsLoading(true);
			const { data, headers } = await http.loginOTP({
				email: credentials.email.trim(),
				password: credentials.password.trim(),
				token: formData.token,
			});
			onUserDataChanged(data);
			http.setJwt(headers['x-auth-token']);
			setIsLoading(false);
		} catch (ex) {
			console.log(ex);
			setIsLoading(false);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	if (http.getCurrentUser()) return <Redirect to={prevPath} />;
	return (
		<main className='loginPage'>
			<div className='container'>
				<Divider />
				<div className='row'>
					<div
						className='col-md-4 offset-md-4 col-12'
						style={{ paddingTop: '6rem' }}>
						<div className='card loginCard'>
							<div className='card-body'>
								<div className='text-center'>
									<Link to={'/'}>
										<Image
											className={'w-100 my-4 px-5'}
											fileName={'logo.png'}
										/>
									</Link>
									<h1>Login</h1>
								</div>

								{needsOTP ? (
									<OTPForm
										email={email}
										isLoading={isLoading}
										onSubmit={doOTPSubmit}
									/>
								) : (
									<LoginForm
										email={email}
										isLoading={isLoading}
										onSubmit={doSubmit}
									/>
								)}

								<p className='text-center mt-4'>
									Don't you have account? Please{' '}
									<Link to={'/register'}>register</Link> now.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Login;
