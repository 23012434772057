import React from 'react';
import { Redirect } from 'react-router-dom';
import useDocumentTitle from '../hooks/useDocumentTitle';
import config from './../config';
import http from '../services/httpService';
import Hero from './home/hero';
import About from './home/about';
import Slogan from './home/slogan';
import TIM from './home/tim';
import BCN from './home/bcn';

const Home = (props) => {
	useDocumentTitle('BCN GARDEN');

	if (config.domain === 'backOffice') {
		const user = http.getCurrentUser();
		if (user) return <Redirect to={'/blogs'} />;
		else return <Redirect to={'/login'} />;
	}

	return (
		<>
			<Hero />
			<About />
			<Slogan />
			<TIM />
			<BCN />
		</>
	);
};

export default Home;
