import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/common/protectedRoute';

import Login from '../components/auth/login';
import Register from '../components/auth/register';
import PasswordReset from '../components/auth/passwordReset';
import ComingSoon from '../components/comingSoon';
import NotFound from '../components/notFound';
import Home from '../components/home';

import Article from '../components/articles/article';
import articles from './../components/articles/articles';

import Logout from '../components/auth/logout';
import Dashboard from '../components/dashboard';
import Garden from './../components/garden';
import GardenLight from '../components/gardenLight';
import Blog from '../components/blog';
import Seeds from '../components/seeds';

const articleRoutes = articles.map((b) => ({
	path: b.path,
	isGlobal: true,
	exact: true,
	name: b.name,
	articleProps: b,
	Component: Article,
}));

const normalRoutes = [
	{ path: '/register', exact: true, name: 'Register', Component: Register },
	{ path: '/login', exact: true, name: 'Login', Component: Login },
	{
		path: '/passwordReset',
		exact: true,
		name: 'Password Reset',
		Component: PasswordReset,
	},
	{
		path: '/comingSoon',
		exact: true,
		name: 'Coming Soon',
		Component: ComingSoon,
	},
	{
		path: '/seeds/:name',
		isGlobal: true,
		exact: true,
		name: 'Seeds',
		Component: Seeds,
	},
	{
		path: '/seeds',
		isGlobal: true,
		exact: true,
		name: 'Seeds',
		Component: Seeds,
	},
	{
		path: '/blog/:_id',
		isGlobal: true,
		exact: true,
		name: 'Blog',
		Component: Blog,
	},
	{
		path: '/blog',
		isGlobal: true,
		exact: true,
		name: 'Blog',
		Component: Blog,
	},
	{ path: '/notFound', exact: true, name: 'Not Found', Component: NotFound },
	{
		path: ['/', '/home'],
		isGlobal: true,
		exact: true,
		name: 'Home',
		Component: Home,
	},
];

const routes = [...articleRoutes, ...normalRoutes];

const protectedRoutes = [
	{ path: '/logout', exact: true, name: 'Logout', Component: Logout },
	{
		path: '/dashboard',
		exact: false,
		name: 'Dashboard',
		Component: Dashboard,
	},
	{
		path: '/garden',
		exact: true,
		name: 'Garden',
		Component: Garden,
	},
	{
		path: '/light',
		exact: true,
		name: 'Garden Light',
		Component: GardenLight,
	},
];

const generateCrumbs = (props) => {
	const allRoutes = [...protectedRoutes, ...routes];
	const crumbs = allRoutes
		.filter(({ path }) => props.match.path.includes(path))
		.map(({ path, ...rest }) => ({
			path: Object.keys(props.match.params).length
				? Object.keys(props.match.params).reduce(
						(path, param) =>
							path.replace(
								`:${param}`,
								props.match.params[param]
							),
						path
				  )
				: path,
			...rest,
		}))
		.sort((a, b) =>
			a.path.split('/').length > b.path.split('/').length ? 1 : -1
		);
	return crumbs;
};

const renderRoute = (props, Component, articleProps) => {
	const crumbs = generateCrumbs(props);

	return <Component {...props} crumbs={crumbs} articleProps={articleProps} />;
};

const generateRoutes = () => {
	return {
		protectedRoutes: protectedRoutes.map(
			({ path, Component, exact, articleProps }, key) => (
				<ProtectedRoute
					exact={exact}
					path={path}
					key={key}
					render={(props) =>
						renderRoute(props, Component, articleProps)
					}
				/>
			)
		),
		routes: routes.map(({ path, Component, exact, articleProps }, key) => (
			<Route
				exact={exact}
				path={path}
				key={key}
				render={(props) => renderRoute(props, Component, articleProps)}
			/>
		)),
	};
};

export function getIsGlobal(pathname) {
	const pathArray = pathname.split('/');
	const parentPath = `${pathArray[0]}/${pathArray[1]}`;
	const allRoutes = [...protectedRoutes, ...routes];
	const route = allRoutes.find(
		(r) =>
			r.path === parentPath ||
			(Array.isArray(r.path) && r.path.includes(parentPath))
	);
	return route ? route.isGlobal : false;
}

export default generateRoutes;
