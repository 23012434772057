import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';
import Spinner from '../../../common/spinner';

class TopupForm extends Form {
	state = {
		data: {
			amount: this.props.currentAmount || this.props.min,
			token: this.props.options.find((o) => o.checked).id,
		},
		errors: {},
	};

	schema = {
		amount: Joi.number()
			.min(this.props.min)
			.multiple(this.props.step)
			.required()
			.label('Amount')
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case 'any.empty':
							err.message = 'Amount should not be empty!';
							break;
						case 'number.min':
							err.message = `Invalid amount, minimum amount is ${
								this.props.min
							} ${this.props.currentToken.id.toUpperCase()}.`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		token: Joi.string().required().label('Token'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	optionChanged = ({ currentTarget: option }) => {
		const currentToken = this.props.options.find(
			(o) => o.id === option.value
		);
		this.props.setCurrentToken(currentToken);
		const state = { ...this.state };
		state.data.amount = currentToken.minTopup / 1e6;
		state.data.token = option.value;
		this.setState(state);
	};

	render() {
		const {
			isLoading,
			options,
			min,
			step,
			hasActivePaymentRequest,
			currentToken,
		} = this.props;
		return (
			<form className='text-start' onSubmit={this.handleSubmit}>
				<div className='mb-4'>
					<p>Select Token:</p>
					{options.map((o, key) =>
						this.renderRadioInput({
							key,
							name: 'token',
							id: o.id,
							label: o.label,
							checked: hasActivePaymentRequest
								? o.id === currentToken.id
								: o.checked,
							disabled: hasActivePaymentRequest
								? hasActivePaymentRequest
								: o.disabled,
							extraHandler: this.optionChanged,
						})
					)}
				</div>
				<div className='mb-4'>
					{this.renderInput({
						name: 'amount',
						label: `Enter Amount (${currentToken.id.toUpperCase()})`,
						type: 'number',
						min,
						step,
						disabled: hasActivePaymentRequest,
					})}
				</div>
				<div className='mb-4 text-center'>
					{!hasActivePaymentRequest &&
						this.renderButton(
							<span>Request Payment</span>,
							`rectBtn`,
							isLoading && <Spinner content={'Requesting...'} />
						)}
				</div>
			</form>
		);
	}
}

export default TopupForm;
