const marketMaker = {
	image: 'marketmaker.jpg',
	path: '/marketmaker',
	name: 'Market Maker',
	title: 'Market Maker',
	description:
		'A market maker or fluidity provider is a person or legal entity...',
	content: (
		<div className='container py-5'>
			<div className='row'>
				<div className='col-12'>
					<p>
						A market maker or fluidity provider is a person or legal
						entity that constantly buys and sells a currency at the
						rate at which it is sold in the market. By doing this,
						the market maker acts as an opposing side to most
						transactions that take place by retail traders. In other
						words, when a retail trader buys a small currency, the
						market maker sells its currency or vice versa.
					</p>
					<br />
					<p>
						It is to be noted that a market maker always trades
						against the population of retail traders. One of the
						main duties of a market maker is to provide enough
						fluidity for every exchanged asset. The price offered by
						a market maker is merely based on supply and demand.
					</p>
					<br />
					<p>
						Market makers are active in currency exchange markets,
						stock exchanges, and decentralized network protocols.
					</p>
					<br />
					<p>
						Decentralized network protocols are mostly used in the
						cryptocurrency market. Since the crypto market is 24/7
						and has more fluctuations and trend changes than Forex,
						a market maker will use bots and scripts to control it.
						The Strategies of these bots are determined by the
						professionals in this field, and the bots are constantly
						monitored. Most important duties and goals of a market
						maker
					</p>
					<br />
					<p>
						The most important duties and goals of a market maker
						are as follows:
					</p>
					<ol>
						<li>
							increasing liquidity and ensuring that supply and
							demand exist.
						</li>
						<li>
							Controlling prices in a specific range & preventing
							major fluctuations.
						</li>
						<li>
							Reducing price impact by filling the liquidity pool.
						</li>
						<li>
							Reducing the price gap between buying and selling.
						</li>
						<li>
							Other similar goals and duties facilitate trading in
							a crypto market.
						</li>
					</ol>
				</div>
			</div>
		</div>
	),
};

export default marketMaker;
