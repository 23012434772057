import React, { useState, useContext } from 'react';
import MainContext from '../../context/mainContext';
import Modal from 'react-bootstrap/Modal';
import { numberWithCommas } from '../../utils/helpers';

function EcoModal({ seed }) {
	const { initData } = useContext(MainContext);
	const { fruits } = initData;
	console.log('fruits :>> ', fruits);
	console.log('seed :>> ', seed);
	const {
		trc20Price,
		plantingCost,
		wateringCost,

		wateringCostsBefore: before,
		group,
		levelUp,
		fruit,
		fruitCount,
	} = seed;

	const totalWateringBefore =
		group === 'legends'
			? before[0] * 4 +
			  before[1] * 5 +
			  before[2] * 5 +
			  before[3] * 5 +
			  before[4]
			: before * 9;

	const fruitPrice = fruitCount * (fruits[fruit].price / 1e6);
	const fruitingCount = 720 / (levelUp / (60 * 60 * 1000));
	const monthlyIncome = fruitingCount * fruitPrice;
	const totalCostUntillTheStart =
		(trc20Price + totalWateringBefore + plantingCost) / 1e6;

	const netMonthlyIncome = (fruitPrice - wateringCost / 1e6) * fruitingCount;

	const monthlyIncomePercentage = (
		(netMonthlyIncome * 100) /
		totalCostUntillTheStart
	).toFixed(2);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<div
				className='d-flex align-items-center'
				onClick={handleShow}
				style={{ cursor: 'pointer' }}>
				<span className='me-1'>Economical Info</span>
				<i className='far fa-question-circle'></i>
			</div>

			<Modal
				className='text-dark'
				show={show}
				onHide={handleClose}
				centered>
				<Modal.Header closeButton>
					<Modal.Title>{seed.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						Price: <b>{numberWithCommas(trc20Price / 1e6)}</b> TIM
					</div>
					<div>
						Planting cost:{' '}
						<b>{numberWithCommas(plantingCost / 1e6)}</b> TIM
					</div>
					<div>
						Pre-fruiting irrigation cost:{' '}
						<b>{numberWithCommas(totalWateringBefore / 1e6)}</b> TIM
					</div>
					<div>
						Total cost until the start of irrigation:{' '}
						<b>{numberWithCommas(totalCostUntillTheStart)}</b> TIM
					</div>
					<div>
						Monthly income with irrigation cost:
						<b> {numberWithCommas(monthlyIncome)}</b> TIM
					</div>
					<div>
						Net monthly income:{' '}
						<b>{numberWithCommas(netMonthlyIncome)}</b> TIM
					</div>
					<div>
						Monthly income percentage:{' '}
						<b>{numberWithCommas(monthlyIncomePercentage)}%</b>
					</div>
					<h5 className='mt-4'>Note:</h5>
					<ul>
						<li>
							The percentage of profit from selling the fruit of
							each tree is proportional to the added value of its
							NFT. (A tree with a higher NFT added value will have
							a lower profit and vice versa.)
						</li>
						<li>
							The calculation of profit per tree is based on the
							payment and income received in TIM, which will
							increase in proportion to the growth of the TIM
							price.
						</li>
					</ul>
				</Modal.Body>
				<Modal.Footer className='d-flex justify-content-evenly align-items-center'>
					<button onClick={handleClose} className='btn btn-my-navy'>
						Close
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default EcoModal;
