import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import Countdown from 'react-countdown';
import MainContext from '../../../../context/mainContext';
import http from '../../../../services/httpService';
import { numberWithCommas, roundUpMinutes } from '../../../../utils/helpers';
import ConfirmModal from '../../ingredients/confirmModal';
import Divider from '../../../divider';
import WithdrawForm from './withdrawForm';

const WithdrawTokens = ({ tab }) => {
	const { userData, initData, onUserDataChanged } = useContext(MainContext);
	const [modalShow, setModalShow] = useState(false);
	const [modalBody, setModalBody] = useState('');
	const [currentFormData, setCurrentFormData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState(false);

	const [currentToken, setCurrentToken] = useState(
		initData.withdrawOptions.find((o) => o.checked)
	);

	const { withdraw, withdrawOptions } = initData;
	const {
		paymentHistory,
		balances,
		hasActiveWithdrawRequest,
		withdrawRequests,
	} = userData;

	const deposits = paymentHistory.sort((a, b) => (a.date > b.date ? 1 : -1));

	const userHasDeposit = deposits.length > 0;
	const isInTheLegalTime = userHasDeposit
		? Date.now() > withdraw.first + new Date(deposits[0].date).getTime()
		: false;
	const isWithdrawAllowed = userHasDeposit && isInTheLegalTime;

	const requestTime = hasActiveWithdrawRequest
		? new Date(roundUpMinutes(withdrawRequests[0].date)).getTime()
		: 0;
	const counterDate = withdraw.gap + requestTime;

	const handleModalShow = (formData) => {
		const { amount, token, wallet, isWalletValid } = formData;
		const fee = withdrawOptions.find((o) => o.id === token).fee / 1e6;
		if (!isWalletValid) {
			return setErrors({
				wallet: 'Press Check button to examine the Wallet before going further.',
			});
		}
		setCurrentFormData(formData);
		setModalBody(
			<>
				<div>
					Amount: {amount} {token.toUpperCase()}
				</div>
				<div>
					Processing Fee: {fee} {token.toUpperCase()}
				</div>
				<div>
					You will receive:{' '}
					<b className='text-link-color'>
						{amount - fee} {token.toUpperCase()}
					</b>
				</div>
				<div>
					Destination Address: <b>{wallet}</b>
				</div>
				<div>
					Approximate processing time:{' '}
					<b>{initData.withdraw.gap / (60 * 60 * 1000)}</b> hours
				</div>
			</>
		);
		setModalShow(true);
	};

	const handleModalClose = (response) => {
		setModalShow(false);
		if (response) handleWithdraw();
	};

	const handleWithdraw = async () => {
		const { wallet, amount, token, password } = currentFormData;
		try {
			setIsLoading(true);
			const { data } = await http.withdrawRequest({
				wallet,
				amount,
				token,
				password,
			});
			toast.success(data.message);
			onUserDataChanged(data.data);
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
			setCurrentFormData(null);
		}
	};

	return (
		<>
			<Divider />
			<div className='row my-4'>
				<div className='col text-center'>
					<h4 className='fs-1'>Withdraw Tokens</h4>
				</div>
			</div>

			{initData.withdraw.enabled ? (
				<>
					{!userHasDeposit && (
						<div className='row my-5'>
							<div className='col text-center'>
								<p className='fs-2'>
									You have not deposited any tokens yet!
								</p>
								<p className='fs-4'>
									Withdrawal will be active{' '}
									<span className='text-link-color'>
										{withdraw.first / (24 * 60 * 60 * 1000)}
									</span>{' '}
									days after your first deposit.
								</p>
							</div>
						</div>
					)}
					{userHasDeposit && !isInTheLegalTime && (
						<div className='row my-5'>
							<div className='col text-center'>
								<p className='fs-2'>
									Withdrawal will be active in:
								</p>
								<Countdown
									className='h2'
									onComplete={() => window.location.reload()}
									date={
										withdraw.first +
										new Date(deposits[0].date).getTime()
									}
								/>
							</div>
						</div>
					)}
					{isWithdrawAllowed && (
						<div className='row my-5'>
							{hasActiveWithdrawRequest &&
							withdrawRequests.length > 0 ? (
								<div className='col-md-6 offset-md-3 text-center'>
									<div className='fs-2'>
										You have a pending withdraw request:
									</div>
									<div className='fs-5'>
										<div>
											Amount:
											{withdrawRequests[0].amount /
												1e6}{' '}
											{withdrawRequests[0].token.toUpperCase()}
										</div>
										<div>{withdrawRequests[0].wallet}</div>
									</div>
									<div className='fs-4 mt-5'>
										Execution time:
									</div>
									<Countdown
										className='fs-4'
										onComplete={() =>
											window.location.reload()
										}
										date={counterDate}
									/>
								</div>
							) : (
								<div className='col-md-4 offset-md-4 text-center'>
									<WithdrawForm
										options={withdrawOptions}
										balances={balances}
										currentToken={currentToken}
										setCurrentToken={setCurrentToken}
										onSubmit={handleModalShow}
										errors={errors}
										min={currentToken.min / 1e6}
										max={currentToken.max / 1e6}
										step={currentToken.step / 1e6}
										fee={currentToken.fee / 1e6}
										balance={
											balances[currentToken.id]
												.available / 1e6
										}
										isLoading={isLoading}
									/>
								</div>
							)}
						</div>
					)}
				</>
			) : (
				<div className='row my-5'>
					<div className='col text-center'>
						<p className='fs-2'>
							Withdrawal is not active at the moment <br />
							<span className='fs-4'>
								please try again later.
							</span>
						</p>
					</div>
				</div>
			)}

			<Divider file='08' className='my-5' full />

			<div className='row'>
				<div className='col-12 text-start'>
					<h4 className='mb-4'>Withdrawal rules:</h4>
					<ul>
						{withdrawOptions.map((o, key) => (
							<li key={key}>
								{o.id.toUpperCase()} withdrawal fee is{' '}
								<span className='text-link-color'>
									{numberWithCommas(
										withdrawOptions[key].fee / 1e6
									)}{' '}
									{o.id.toUpperCase()}
								</span>
								. Withdrawal is limited to minimum{' '}
								<span className='text-link-color'>
									{numberWithCommas(
										withdrawOptions[key].min / 1e6
									)}{' '}
									{o.id.toUpperCase()}
								</span>{' '}
								and maximum{' '}
								<span className='text-link-color'>
									{numberWithCommas(
										withdrawOptions[key].max / 1e6
									)}{' '}
									{o.id.toUpperCase()}
								</span>{' '}
								in each turn.
							</li>
						))}
						<li>
							Withdrawal will be active{' '}
							<span className='text-link-color'>
								{withdraw.first / (24 * 60 * 60 * 1000)}
							</span>{' '}
							days after your first deposit.
						</li>
						<li>
							Each withdrawal takes{' '}
							<span className='text-link-color'>
								{withdraw.gap / (60 * 60 * 1000)}
							</span>{' '}
							hours.
						</li>
					</ul>
				</div>
			</div>

			<ConfirmModal
				show={modalShow}
				onClose={handleModalClose}
				title={'Withdraw Request'}
				body={modalBody}
			/>
		</>
	);
};

export default WithdrawTokens;
