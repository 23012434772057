import React, { useContext, useState } from 'react';
import MainContext from '../../../context/mainContext';
import { paginate } from '../../../utils/paginate';
import Divider from '../../divider';
import RankingTable from '../ingredients/rankingTable';
import Pagination from '../../common/pagination';
import NoData from '../ingredients/noData';
import useRankings from '../../../hooks/useRankings';
import Preloader from '../../common/preloader';
import Image from '../../common/image';

const Rankings = ({ tab }) => {
	const { userData } = useContext(MainContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [ranks] = useRankings();
	if (!ranks) return <Preloader />;

	for (const i in ranks) ranks[i].no = Number(i) + 1;
	const { username } = userData;
	const myRank = ranks.findIndex((r) => r.username === username) + 1;

	const data = paginate(ranks, currentPage, 15);

	return (
		<>
			<Divider />
			{ranks.length > 0 ? (
				<>
					<div className='row mb-4'>
						<div className='col text-center'>
							<h4 className='fs-1'>Rankings</h4>
							<h4 className='fs-3 mt-4 d-flex align-items-center justify-content-center'>
								<span className='me-3'>My Rank:</span>
								{myRank < 4 ? (
									<Image
										fileName={`icons/ranks/0${myRank}.png`}
										height='45px'
									/>
								) : (
									<span>{myRank}</span>
								)}
							</h4>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-8 offset-md-2'>
							<RankingTable data={data} />
							<Pagination
								customClass='mt-1 myPagination'
								activeClass='myActive'
								totalCount={ranks.length}
								pageSize={15}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</div>
					</div>
				</>
			) : (
				<NoData />
			)}
			<Divider file='07' className='mt-5' />
		</>
	);
};

export default Rankings;
