import React, { useContext } from 'react';
import HeaderImage from './../ingredients/headerImage';
import MainContext from '../../../context/mainContext';
import Preloader from './../../common/preloader';
import Anchor from '../../common/anchor';

function UserResource({ tab }) {
	const { resources, initData } = useContext(MainContext);
	const types = initData.resourcesConfig.types;

	return (
		<>
			<HeaderImage fileName={`headers/${tab.id}.jpg`} />
			{resources ? (
				types.map((type, key) => {
					const resourceArray = resources.filter(
						(r) => r.type === type
					);
					return (
						<div
							key={key}
							className={`mb-4 d-${
								resourceArray.length > 0 ? 'block' : 'none'
							}`}>
							<div className='h2 text-center'>
								<b>{type}</b>:
							</div>
							<hr />
							<div className='row'>
								{resourceArray.map((r, k) => (
									<div key={k} className={'col-sm-2'}>
										<Anchor
											href={r.link}
											content={
												<>
													<img
														loading='lazy'
														src={r.imageLink}
														className='card-img-top'
														alt={r._id}
													/>
													<p className='h5 text-center my-2 text-dark'>
														<b>{r.title}</b>
													</p>
												</>
											}
										/>
									</div>
								))}
							</div>
						</div>
					);
				})
			) : (
				<Preloader />
			)}
		</>
	);
}

export default UserResource;
