import React from 'react';
import Slider from 'react-slick';
import Anchor from '../common/anchor';
import Image from '../common/image';

const ProjectsCarousel = () => {
	const settings = {
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		infinite: true,
		speed: 800,
		slidesToShow: 4,
		slidesToScroll: 4,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const data = [
		{
			fileName: 'blogs/logos/bcn.png',
			href: 'https://bcntoken.com',
		},
		{
			fileName: 'blogs/logos/bemchain.png',
			href: 'https://bemchain.io',
		},
		{
			fileName: 'blogs/logos/bemulator.png',
			href: 'https://bemulator.io',
		},
		{
			fileName: 'blogs/logos/bempire.png',
			href: 'https://bemscan.io',
		},
		{
			fileName: 'blogs/logos/bem.png',
			href: 'https://bemtoken.io',
		},
		{
			fileName: 'blogs/logos/bemgames.png',
			href: 'https://bemgames.io',
		},
		{
			fileName: 'blogs/logos/bemex.png',
			href: 'https://bemex.io',
		},
		{
			fileName: 'blogs/logos/bemib.png',
			href: 'https://bemib.io',
		},
		{
			fileName: 'blogs/logos/bempay.png',
			href: 'https://bempay.io',
		},
		{
			fileName: 'blogs/logos/tim.png',
			href: 'https://tim.io',
		},
	];

	return (
		<div id='carousel' className='container my-5 pb-5'>
			<div className='row'>
				<div className=' col-12 px-5'>
					<Slider {...settings}>
						{data.map((d) => (
							<div key={data.indexOf(d)} className='px-2'>
								<Anchor
									// href={d.href}
									content={
										<div
											className={'carousel_carContainer'}>
											<Image
												fileName={d.fileName}
												className={`w-100 carousel_images`}
											/>
										</div>
									}
								/>
							</div>
						))}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default ProjectsCarousel;
