const risks = {
	image: 'risks.jpg',
	path: '/risks',
	name: 'Risks',
	title: 'Risk Management',
	description:
		'As a financial platform that works officially and has a permit...',
	content: (
		<div className='container py-5'>
			<div className='row'>
				<div className='col-12'>
					<h2>General introductions </h2>
					<p>
						As a financial platform that works officially and has a
						permit to do so, The MMP WORLD project strictly follows
						every law in the blockchain field. It uses reliable risk
						management solutions to ensure the security of users'
						investments.
					</p>
					<h2>MMP WORLD risk management</h2>
					<p>
						MMP WORLD uses two main risk management solutions to
						comprehensively optimize the risk management platform
						and protect the fundamental interests of the investors.
					</p>
					<p>
						Open-source smart contracts have been used in the
						financial platform of MMP WORLD. This makes all sections
						of the MMP WORLD platform do their jobs automatically.
						This system guarantees very high speeds and reduces
						human error. It also increases the project's security,
						reducing the risk of hacking to zero; this is one of the
						most serious concerns of financial platforms.
					</p>
					<p>
						Another thing to mention is the implantation of advanced
						grid trading technology, which can reduce the risk of
						trades and withdrawal of capital to a safe and
						controllable degree, based on the assurance of sustained
						profitability,{' '}
					</p>
					<h2>Development plan</h2>
					<p>
						The project's development plan is one of the most
						critical and fundamental topics in managing risks of
						financial and investment systems. A development plan is
						important to the future and stability of any project. A
						plan that can be 100% implemented and has a very low
						risk. The MMP WORLD project uses a unique development
						plan for paying the interests of the investors,
						rewarding its staff, and returning the investments, on
						top of paying rational interests and rewards to active
						people in the project.{' '}
					</p>
					<p>
						The BEM foundation, the parent holding of the MMP WORLD,
						is the creator of a TRC20 token named BEMCHAIN (in
						short, BCN). In the development plan of MMP WORLD, the
						raised funds will be used for market making for BCN.
						This means that the investment directly goes into the
						BCN token market, increasing the token's price.
					</p>
					<p>
						Duo to continued fundraising and gathering of small
						capitals and turning them into one major capital, the
						project will profit from 100% to 150% in each market.
					</p>
					<p>
						The project will offer 70% percent of the earned income
						in blue diamond debit cards, up to 120% in the royal
						diamond debit cards and there is also a reward plan for
						all diamond debit cards. On average 30% of the income
						will belong to the project, which will be considered as
						the company's profit.
					</p>
					<p>
						A vital point to note in the development plan of MMP
						WORLD is that the project owners have full ownership of
						the currencies they do market-making on. Because of this
						facing crypto whales, the changing strategies of crypto
						owners and the sudden changes in market trends will not
						affect the raised fund itself. It is not faced with any
						risk.
					</p>
					<p>
						The same strategy will be used in other projects, which
						will be initialized in the next phases and with more
						funds. The owner of the developing projects is the BEM
						Foundation itself. The raised funds won't be used to
						create the projects, and the funds will not turn into
						idle capital. The funds raised will only be used to
						develop the projects, which have a guaranteed,
						risk-free, short-term return.
					</p>
					<p>
						Financial transparency has always been a major value of
						the MMP WORLD project, and it will remain so.
					</p>
					<p>
						MMP WORLD project is a safe and certain way of investing
						in cryptocurrencies.
					</p>
				</div>
			</div>
		</div>
	),
};

export default risks;
