import React, { Fragment } from 'react';
import Joi from 'joi-browser';
import Form from '../../common/form';

class CustomerSupportForm extends Form {
	state = {
		data: {
			department: '',
			priority: this.props.priority.find((p) => p.checked === true).name,
			title: '',
			content: '',
			securityPassword: '',
		},
		errors: {},
		textAreaChars: 0,
	};

	schema = {
		department: Joi.string()
			.valid(...this.props.department)
			.required()
			.label('Department'),
		priority: Joi.string()
			.valid(...this.props.priority.map((p) => p.name))
			.required()
			.label('Priority'),
		title: Joi.string()
			.min(this.props.titleConfig.min)
			.max(this.props.titleConfig.max)
			.regex(
				/^[a-zA-Z0-9- ]*$/,
				'Alphanumerics, dash and space characters.'
			)
			.required()
			.label('Title'),
		content: Joi.string()
			.min(this.props.contentConfig.min)
			.max(this.props.contentConfig.max)
			.required()
			.label('Content'),
		securityPassword: Joi.string().required().label('Security Password'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	textAreaChanged = ({ currentTarget: text }) => {
		const textAreaChars = text.value.length;
		this.setState({ textAreaChars });
	};

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const {
			isLoading,
			titleConfig,
			department,
			priority,
			contentConfig,
			hasSP,
		} = this.props;

		const { textAreaChars } = this.state;

		return (
			<Fragment>
				<form
					autoComplete='off'
					className='mt-3'
					onSubmit={this.handleSubmit}>
					<div className='row'>
						<div className='col-md-6 offset-md-3 col-12'>
							<small>
								<span className='text-danger'>*</span> Required
								fields
							</small>
							<div className='my-4'>
								{this.renderSelect({
									name: 'department',
									label: (
										<span>
											Choose Department{' '}
											<span className='text-danger'>
												*
											</span>
										</span>
									),
									options: department,
								})}
							</div>
							<div className='mb-4'>
								<div>Select Priority:</div>
								{priority.map((o, key) =>
									this.renderRadioInput({
										formClass: 'form-check-inline',
										key,
										name: 'priority',
										id: o.name,
										label: o.name,
										checked: o.checked,
									})
								)}
							</div>

							<div className='mb-4'>
								{this.renderInput({
									name: 'title',
									label: (
										<span>
											Title{' '}
											<span className='text-danger'>
												*
											</span>
										</span>
									),
									type: 'text',
									formText: `Title must be alphanumeric, minimum ${titleConfig.min} and maximum ${titleConfig.max} in length.`,
								})}
							</div>

							<div className='mb-4'>
								{this.renderTextArea({
									name: 'content',
									label: (
										<span>
											Content{' '}
											<span className='text-danger'>
												*
											</span>
										</span>
									),
									placeholder:
										this.state.data.department ===
										'Crypto Deposit'
											? 'Please provide the transaction hash'
											: '',
									rows: 3,
									formText: (
										<div>
											{textAreaChars} of maximum{' '}
											{contentConfig.max} characters used.
											<br />
											{this.state.data.department ===
												'Crypto Deposit' &&
												'Please provide the transaction hash!'}
										</div>
									),
									extraHandler: this.textAreaChanged,
								})}
							</div>

							{this.renderSubmitBtnWithSPass({
								divClass: 'mt-3',
								hasSP,
								isLoading,
								text: 'Submit',
								loadingText: 'Submitting...',
							})}
						</div>
					</div>
				</form>
			</Fragment>
		);
	}
}

export default CustomerSupportForm;
