import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import http from '../services/httpService';
import useIsMounted from './useIsMounted';

export default function useRankings() {
	const [ranks, setRanks] = useState(null);
	const { pathname } = useLocation();
	const isMounted = useIsMounted();

	useEffect(() => {
		const allowed = ['/dashboard/rankings'];

		async function getRanks() {
			try {
				if (
					!http.getCurrentUser() ||
					ranks ||
					!allowed.includes(pathname)
				)
					return;
				const { data } = await http.getRanking();
				if (isMounted()) setRanks(data);
			} catch (error) {
				console.log('error :>> ', error);
			}
		}
		getRanks();
	}, [isMounted, ranks, pathname]);
	return [ranks, setRanks];
}
