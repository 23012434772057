import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import MainContext from '../../../../context/mainContext';
import http from './../../../../services/httpService';
import Divider from '../../../divider';
import ProfileForm from './profileForm';

const Profile = ({ history, tab }) => {
	const { userData, initData, onUserDataChanged } = useContext(MainContext);
	const [isLoading, setIsLoading] = useState(false);

	const handleProfileUpdate = async (formData) => {
		try {
			const { fullName, country, nationalID, password } = formData;
			setIsLoading(true);
			const { data } = await http.updateProfile({
				password,
				fullName,
				country,
				nationalID,
				phoneNumber:
					String(formData.dial_code) +
					' ' +
					String(formData.phoneNumber),
			});
			toast.success(data.message);
			onUserDataChanged(data.data);
			setTimeout(() => history.replace('/dashboard'), 1000);
		} catch (ex) {
			console.log(ex);

			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Divider />
			<div className='row my-4'>
				<div className='col text-center'>
					<h4 className='fs-1'>Update My Profile</h4>
				</div>
			</div>
			<div className='row'>
				<div className='col-md-6 offset-md-3 col-12'>
					<ProfileForm
						isLoading={isLoading}
						wallet={userData.wallet}
						username={userData.username}
						uid={userData.uid}
						email={userData.email}
						sponsor={userData.sponsor}
						countries={initData.countries}
						onSubmit={handleProfileUpdate}
						hasSP={userData.hasSP}
						securityPassword={''}
						fullName={userData.profile.fullName}
						nationalID={userData.profile.nationalID}
						country={userData.profile.country}
						phoneNumber={
							userData.profile.phoneNumber
								? userData.profile.phoneNumber.split(' ')[1]
								: ''
						}
					/>
				</div>
			</div>
			<Divider file='04' className='mt-5' />
		</>
	);
};

export default Profile;
