import React from 'react';
import useElemSize from '../../hooks/useElemSize';
import Clock from 'react-live-clock';
import BreadcrumbsTabs from '../common/breadcrumbsTabs';
import Image from '../common/image';

const CrumbsComp = ({ crumbs }) => {
	const [ref] = useElemSize('crumbsComp');
	// const rank = userRank(userData, initData);
	const rank = null;

	return (
		<header
			ref={ref}
			className={`card myCards d-none d-md-block crumbsComp`}
			style={{
				backgroundColor: '#edf0f4',
			}}>
			<div className={`card-body`}>
				<div className='d-flex align-items-center'>
					<div className=''>
						<BreadcrumbsTabs root={'Dashboard'} crumbs={crumbs} />
					</div>
					<div className='mx-auto'>
						{rank && (
							<div className='d-flex justify-content-evenly align-items-center'>
								<Image
									fileName={`ranks/${rank.name}.svg`}
									height={'55px'}
									className='me-2'
								/>
								<div>
									<b>{rank.title}</b>
								</div>
							</div>
						)}
					</div>

					<div>
						<Clock
							format={'dddd, MMMM Do, YYYY, h:mm:ss A'}
							ticking={true}
							// timezone={'UTC'}
						/>
					</div>
				</div>
			</div>
		</header>
	);
};

export default CrumbsComp;
