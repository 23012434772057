import { lazy, Suspense } from 'react';
const TimToken = lazy(() => import('./TimToken'));

const TimLogo3d = ({
    width = '100%',
    height = '100%',
    scale = 7,
    zAngle = 0,
    dist = 8,
    fov = 15,
    minVerticalTilt = -30,
    maxVerticalTilt = 10,
    minHorizontalTilt,
    maxHorizontalTilt,
}) => (
    <Suspense fallback={null}>
        <TimToken
            width={width}
            height={height}
            scale={scale}
            zAngle={zAngle}
            dist={dist}
            fov={fov}
            minVerticalTilt={minVerticalTilt}
            maxVerticalTilt={maxVerticalTilt}
            minHorizontalTilt={minHorizontalTilt}
            maxHorizontalTilt={maxHorizontalTilt}
        />
    </Suspense>
);

export default TimLogo3d;
