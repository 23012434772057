import React, { useState, useContext } from 'react';
import Countdown from 'react-countdown';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import MainContext from '../../context/mainContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import http from '../../services/httpService';
import Image from '../common/image';
import Preloader from './../common/preloader';
import RegisterForm from './registerFrom';
import { getQueries } from '../../utils/helpers';
import Divider from '../divider';
import RegisterConfirmForm from './registerConfirmForm';

const Register = (props) => {
	useDocumentTitle('BCN GARDEN - Register');
	const { ref } = getQueries();

	const { initData, onUserDataChanged } = useContext(MainContext);
	const [isLoading, setIsLoading] = useState(false);
	const [isEmailBtnLoading, setIsEmailBtnLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [activation, setActivation] = useState(null);
	const [isEmailAllowed, setIsEmailAllowed] = useState(
		activation
			? Date.now() > activation.lastEmail + initData.emailGap
			: false
	);

	const emailCounterDate = activation
		? activation.lastEmail + initData.emailGap
		: 0;

	const vcCounterDate = activation
		? activation.VC.date + initData.vcTime + 3000
		: 0;

	const handleRegisterActivation = async (formData) => {
		if (formData.password !== formData.password_confirmation)
			return setErrors({
				password_confirmation: 'Passwords do not match',
			});
		if (formData.idStatus === 1)
			return setErrors({
				invitee: 'Press Check button to examine the entered ID.',
			});

		try {
			setIsLoading(true);
			const { data } = await http.registerActivation({
				invitee: formData.invitee.toLowerCase(),
				email: formData.email.trim().toLowerCase(),
				password: formData.password.trim(),
				wallet: formData.wallet,
				username: formData.username,
			});
			setIsLoading(false);
			setErrors({});
			toast.success(data.message);
			setActivation(data.data.activationRequest);
		} catch (ex) {
			console.log(ex);
			setIsLoading(false);
			if (ex.response && ex.response.status === 400) {
				const data = ex.response.data;
				if (typeof data === 'object') setErrors(data);
			}
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	const handleResendBtnClick = async () => {
		try {
			setIsEmailBtnLoading(true);
			const { data } = await http.resendVC({ _id: activation._id });
			setActivation(data.activationRequest);
			setIsEmailAllowed(false);
			toast.info('Eamil sent, please check your inbox');
		} catch (ex) {
			console.log(ex);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsEmailBtnLoading(false);
		}
	};

	const handleRegister = async (formData) => {
		try {
			setIsLoading(true);
			const { data, headers } = await http.register({
				vcCode: formData.vcCode,
				_id: activation._id,
			});
			setErrors({});
			toast.success(data.message);
			onUserDataChanged(data.data);

			setActivation(null);
			http.setJwt(headers['x-auth-token']);
			props.history.replace('/dashboard');
		} catch (ex) {
			console.log(ex);
			if (ex.response && ex.response.status === 403) setActivation(null);
			if (ex.response && ex.response.status === 400) {
				const data = ex.response.data;
				if (typeof data === 'object') setErrors(data);
			}
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		} finally {
			setIsLoading(false);
		}
	};

	if (!initData.emailGap) return <Preloader />;
	if (http.getCurrentUser()) http.removeJwt();

	return (
		<main className='loginPage'>
			<div className='container'>
				<Divider />
				<div className='row'>
					<div
						className='col-md-8 offset-md-2 col-12'
						style={{ paddingTop: '4rem' }}>
						<div className='card loginCard'>
							<div className='card-body'>
								<div className='text-center'>
									<Link to={'/'}>
										<Image
											className={'w-50 my-4 px-5'}
											fileName={'logo.png'}
										/>
									</Link>
									<h1>Register</h1>
								</div>

								{activation ? (
									<>
										<div className='row my-3 text-center'>
											<div className='col-md-4 offset-md-4 col-12'>
												<div>
													The verification code is
													sent to your email, this
													code will expire in:
												</div>
												<Countdown
													className='h4'
													date={vcCounterDate}
													onComplete={() =>
														setActivation(null)
													}
												/>
											</div>
										</div>

										<RegisterConfirmForm
											isLoading={isLoading}
											onSubmit={handleRegister}
											errors={errors}
											isEmailAllowed={isEmailAllowed}
											counterDate={emailCounterDate}
											onRequestTimerCompleted={() =>
												setIsEmailAllowed(true)
											}
											isEmailBtnLoading={
												isEmailBtnLoading
											}
											onEmailRequest={
												handleResendBtnClick
											}
										/>
									</>
								) : (
									<RegisterForm
										errors={errors}
										isLoading={isLoading}
										onSubmit={handleRegisterActivation}
										invitee={ref}
									/>
								)}
								<p className='text-center mt-4'>
									Already have an account? Please{' '}
									<Link to='/login'>login</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default Register;
