import React, { useEffect, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import MainContext from '../../../../context/mainContext';
import http from './../../../../services/httpService';
import Divider from '../../../divider';
import VerifyCodeForm from './verifyCodeForm';
import Setup2faForm from './setup2faForm';
import Disable2faForm from './disable2faForm';
import Enable2faForm from './enable2faForm';
import CopyBtn from './../../../common/copyBtn';

function GoogleAuthenticator({ tab }) {
	const { userData, onUserDataChanged } = useContext(MainContext);
	const [isLoading, setIsLoading] = useState(false);
	const [OTP, setOTP] = useState({ base32: '', auth_url: '' });

	const setup2fa = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.generateOTP(formData);
			setOTP(data);
			setIsLoading(false);
		} catch (ex) {
			console.log('ex :>> ', ex);
			setIsLoading(false);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	const handleSubmitCode = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.verifyOTP(formData);
			onUserDataChanged(data.data);
			setIsLoading(false);
			toast.success(data.message);
		} catch (ex) {
			console.log('ex :>> ', ex);
			setIsLoading(false);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	const disable2fa = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.disableOTP(formData);
			onUserDataChanged(data.data);
			setIsLoading(false);
			toast.success(data.message);
		} catch (ex) {
			console.log('ex :>> ', ex);
			setIsLoading(false);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	const enable2fa = async (formData) => {
		try {
			setIsLoading(true);
			const { data } = await http.enableOTP(formData);
			onUserDataChanged(data.data);
			setIsLoading(false);
			toast.success(data.message);
		} catch (ex) {
			console.log('ex :>> ', ex);
			setIsLoading(false);
			if (ex && ex.response && ex.response.data)
				return toast.error(ex.response.data);
			if (ex && ex.message) return toast.error(ex.message);
		}
	};

	const isNotVerified_noOTP = (
		<div className='col-md-4 offset-md-4 col-12'>
			<p>2FA is not activated for this account.</p>
			<p>Secure your account with TOTP two-factor Authentication.</p>
			<Setup2faForm isLoading={isLoading} onSubmit={setup2fa} />
		</div>
	);

	const isNotVerified_OTP = (
		<>
			<div className='col-md-4 offset-md-4 col-12 mb-5'>
				<div className='fancy'>
					<div className='fancy-numbers'>01</div>
					<div className='fancy-content'>
						<h4 className='mb-4'>
							Configuring Google Authenticator or Authy
						</h4>
						<ul>
							<li>
								Install Google Authenticator (IOS - Androird) or
								Authy (IOS - Androird)
							</li>
							<li>In the authenticator app, select "+" icon.</li>
							<li>
								Select "scan a barcode (or QR code)" and use the
								phone's camera to scan this barcode.
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div className='col-md-4 offset-md-4 col-12 mb-5'>
				<div className='fancy'>
					<div className='fancy-numbers'>02</div>
					<div className='fancy-content'>
						<h4>Scan QR Code</h4>
						<div className='text-center mb-4'>
							<QRCode
								value={OTP.auth_url}
								level={'Q'}
								size={256}
							/>
						</div>
						<h4>Or Enter this Code Into Your App</h4>
						<p>
							Secret Key:{' '}
							<b className='text-link-color text-break'>
								{OTP.base32}
							</b>{' '}
							<CopyBtn content={OTP.base32} /> <br />
							<i>
								You need to keep this secret key in a safe place
								for later use.
							</i>
						</p>
					</div>
				</div>
			</div>

			<div className='col-md-4 offset-md-4 col-12 mb-5'>
				<div className='fancy'>
					<div className='fancy-numbers'>03</div>
					<div className='fancy-content'>
						<h4>Verify Code</h4>

						<VerifyCodeForm
							isLoading={isLoading}
							onSubmit={handleSubmitCode}
						/>
					</div>
				</div>
			</div>
		</>
	);

	const isVerified_isEnabled = (
		<div className='col-md-4 offset-md-4 col-12'>
			<p className='text-center mb-4'>
				<b>2FA is enabled!</b>
			</p>
			<h4 className='text-link-color'>Disable 2FA</h4>
			<hr className='mt-0' />
			<Disable2faForm
				isLoading={isLoading}
				hasSP={userData.hasSP}
				onSubmit={disable2fa}
			/>
		</div>
	);

	const isVerified_isNotEnabled = (
		<div className='col-md-4 offset-md-4 col-12'>
			<p className='text-center mb-4'>
				<b>2FA is disabled!</b>
			</p>
			<h4 className='text-link-color'>Enable 2FA</h4>
			<hr className='mt-0' />
			<Enable2faForm
				isLoading={isLoading}
				hasSP={userData.hasSP}
				onSubmit={enable2fa}
			/>
		</div>
	);

	useEffect(() => {
		const parents = document.querySelectorAll('.fancy');
		const children = document.querySelectorAll('.fancy-content');
		if (parents.length === 0) return;

		for (let i = 0; i < parents.length; i++) {
			const height = getComputedStyle(children[i]).getPropertyValue(
				'height'
			);
			parents[i].style.setProperty('height', height);
		}
	});

	return (
		<>
			<Divider />
			<div className='row my-5'>
				<div className='col text-center'>
					<h4 className='fs-1'>Mobile App Authentication (2FA)</h4>
				</div>
			</div>
			<div className='row'>
				{userData.otp.verified
					? userData.otp.enabled
						? isVerified_isEnabled
						: isVerified_isNotEnabled
					: OTP.auth_url
					? isNotVerified_OTP
					: isNotVerified_noOTP}
			</div>
			<Divider file='04' className='mb-4' />
		</>
	);
}

export default GoogleAuthenticator;
