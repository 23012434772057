import { lazy, Suspense } from 'react';
const BcnToken = lazy(() => import('./BcnToken'));

const BcnLogo3d = ({
    width = '100%',
    height = '100%',
    scale = 10,
    zAngle = 0,
    dist = 3,
    fov = 18,
    minVerticalTilt = -5,
    maxVerticalTilt = 5,
    minHorizontalTilt = -15,
    maxHorizontalTilt = 75,
}) => (
    <Suspense fallback={null}>
        <BcnToken
            width={width}
            height={height}
            scale={scale}
            zAngle={zAngle}
            dist={dist}
            fov={fov}
            minVerticalTilt={minVerticalTilt}
            maxVerticalTilt={maxVerticalTilt}
            minHorizontalTilt={minHorizontalTilt}
            maxHorizontalTilt={maxHorizontalTilt}
        />
    </Suspense>
);

export default BcnLogo3d;
