import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import http from './../../services/httpService';
import MainContext from '../../context/mainContext';
import Accordion from './../common/accordion';
import Image from '../common/image';
import styles from '../../css/dashboard/sideBar.module.css';
import Summary from './summary';

const SideBar = ({ tabs, handleShowOthers, activePath }) => {
	const { UIData } = useContext(MainContext);

	const handleClick = (e) => {
		handleShowOthers(true);
		if (e.target.id === 'exit-tab') return http.logout();

		if (window.matchMedia('(max-width: 992px)').matches) {
			const myCollapse = document.getElementById('sidebarMenu');
			new bootstrap.Collapse(myCollapse, { show: false });
		}
	};

	const tabTitle = (tab) => {
		if (!tab.badge) return tab.name;
		if (tab.badge === 'soon')
			return (
				<>
					{tab.name}{' '}
					<span className='badge bg-danger ms-2'>Soon</span>
				</>
			);
	};

	const generateTabListItem = (tab, key, parent) => {
		const linkTo =
			tab.link || `/dashboard${parent ? '/' + parent.id : ''}/${tab.id}`;
		const path = [];
		if (parent) path.push(parent.name);
		path.push(tab.name);

		if (tab.toBeShown)
			return (
				<li key={key} className='nav-item'>
					<NavLink
						to={linkTo}
						className={`p-2 d-flex align-items-center ${
							styles.myNavLink
						} ${
							tab.id === activePath
								? 'myActiveNavLink active'
								: ''
						}`}
						id={`${tab.id}-tab`}
						onClick={handleClick}>
						{tab.icon ? (
							<i className={`${tab.icon} me-2`}></i>
						) : (
							<Image
								className={'me-2'}
								fileName={`icons/menu/${tab.id}.png`}
								height={'32px'}
							/>
						)}

						{tabTitle(tab)}
					</NavLink>
				</li>
			);
		return null;
	};

	const isLessThanMD = window.matchMedia('(max-width: 767.98px)').matches;

	const height = isLessThanMD
		? UIData.header
			? UIData.header.height + UIData.dashboardSpacerMD
			: 0 + UIData.dashboardSpacerMD
		: UIData.summary
		? UIData.summary.height + UIData.dashboardSpacer
		: 0 + UIData.dashboardSpacer;

	return (
		<nav
			id='sidebarMenu'
			className={`${styles.sidebar} d-md-block collapse card  mb-md-0`}>
			<div className='d-none d-md-block'>
				<Summary />
			</div>
			<div
				className={`${styles.sidebarBody} card-body my-2 me-2`}
				style={{
					overflowX: 'hidden',
					overflowY: 'scroll',
					height: `calc(100vh - ${height}px)`,
				}}>
				<ul className='nav flex-column my-0' id='myTab' role='tablist'>
					{tabs.map((tab, key) => {
						const hasChildren =
							tab.children && tab.children.length > 0;
						if (tab.toBeShown)
							return hasChildren ? (
								<Accordion
									key={key}
									buttonClass={`p-2 d-flex align-items-center ${styles.myNavLink}`}
									data={[
										{
											id: tab.id,
											title: (
												<>
													{tab.icon ? (
														<i
															className={`${tab.icon} me-2`}></i>
													) : (
														<Image
															className={'me-2'}
															fileName={`icons/menu/${tab.id}.png`}
															height={'32px'}
														/>
													)}
													{tabTitle(tab)}
												</>
											),
											content: tab.children.map((t, k) =>
												generateTabListItem(t, k, tab)
											),
										},
									]}
								/>
							) : (
								generateTabListItem(tab, key)
							);
						return null;
					})}
				</ul>
			</div>
		</nav>
	);
};

export default SideBar;
