import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';
import Spinner from '../../../common/spinner';

class Enable2faForm extends Form {
	state = {
		data: {
			password: '',
		},
		errors: {},
	};

	schema = {
		password: Joi.string().required().label('Password'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading } = this.props;
		return (
			<form
				className='text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='mb-3'>
					{this.renderInputGroupBtnEnd({
						name: 'password',
						label: 'Password',
						type: 'password',
						btnClass: `rectBtn`,
						btnText: <i className='fas fa-eye-slash'></i>,
						btnHandler: this.handleEyeClicked,
					})}
				</div>
				<div className='text-center'>
					{this.renderButton(
						<span>Enable 2fa</span>,
						`rectBtn`,
						isLoading && <Spinner content={'Loading...'} />
					)}
				</div>
			</form>
		);
	}
}

export default Enable2faForm;
