import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

function Lottie(props) {
	const animationContainer = useRef(null);

	useEffect(() => {
		const {
			animationData,
			loop = true,
			autoplay = true,
			renderer = 'canvas',
		} = props;

		const animation = lottie.loadAnimation({
			container: animationContainer.current,
			renderer,
			loop,
			autoplay,
			animationData,
		});

		return () => {
			animation.destroy();
		};
	}, [props]);

	return (
		<>
			{props.isLoaded && (
				<div
					className={props.className || ''}
					style={{
						width: `${props.width || '100%'}`,
						height: `${props.height || '100%'}`,
					}}
					ref={animationContainer}
				/>
			)}
		</>
	);
}

export default Lottie;
