import React from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { IdleTimerProvider } from 'react-idle-timer';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import config from './config';
import generateRoutes, { getIsGlobal } from './routes/backOfficeRoutes';
import MainContext from './context/mainContext';
import http from './services/httpService';
import useInitData from './hooks/useInitData';
import useUserData from './hooks/useUserData';
import useAllBlogs from './hooks/useAllBlogs';
import useAllResources from './hooks/useAllResources';
// import ScrollToTopBtn from './components/common/scrollToTopBtn';
import ScrollToTop from './components/common/scrollToTop';
import UnderMaintenance from './components/underMaintenance';
import './App.css';
import NavBar from './components/navbar';
import Footer from './components/footer';

function App() {
	const { protectedRoutesWithProps, protectedRoutes, routes } =
		generateRoutes();

	const location = useLocation();
	const isGlobal = getIsGlobal(location.pathname);

	const [initData, setInitData] = useInitData();
	const [userData, setUserData] = useUserData();
	const [blogs, setBlogs] = useAllBlogs();
	const [resources, setResources] = useAllResources();

	const handleUserDataChanged = (data) =>
		setUserData((prevData) => ({ ...prevData, ...data }));
	const handleInitDataChanged = (data) =>
		setInitData((prevData) => ({ ...prevData, ...data }));
	const handleResetBlogs = () => setBlogs(null);
	const handleRemoveBlog = (_id) =>
		setBlogs(blogs.filter((b) => b._id !== _id));

	const handleResetResources = () => setResources(null);
	const handleRemoveResource = (_id) =>
		setResources(resources.filter((r) => r._id !== _id));

	const handleLogout = () => {
		if (http.getCurrentUser()) http.logout();
	};
	const handleScroll = async (el) => {
		if (window.matchMedia('(max-width: 992px)').matches) {
			const myCollapse = document.getElementById(
				'navbarSupportedContent'
			);
			await new bootstrap.Collapse(myCollapse, { show: false });
		}

		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const myNav = document.getElementById('myNav');
		const yOffset = -myNav.offsetHeight;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
	};

	// console.log('initData :>> ', initData);
	// console.log('userData.blogs :>> ', userData.blogs);

	const handleAction = async (event) => {
		if (!initData.sessionTime || !http.getCurrentUser()) return;
		const { date } = http.getCurrentUser();
		if (!date) http.logout();
		if (Date.now() > date + initData.sessionTime - config.reauthGap)
			try {
				const { headers } = await http.reauth();
				http.setJwt(headers['x-auth-token']);
			} catch (ex) {
				console.log(ex);
			}
	};

	return (
		<MainContext.Provider
			value={{
				initData,
				userData,
				blogs,
				resources,
				onLogout: handleLogout,
				onInitDataChanged: handleInitDataChanged,
				onUserDataChanged: handleUserDataChanged,
				onResetBlogs: handleResetBlogs,
				onRemoveBlog: handleRemoveBlog,
				onResetResources: handleResetResources,
				onRemoveResource: handleRemoveResource,
				onHashLinkClick: handleScroll,
			}}>
			<ToastContainer position='bottom-right' theme='colored' />
			<IdleTimerProvider
				timeout={config.idleTimeout}
				crossTab={true}
				onIdle={handleLogout}
				onAction={handleAction}>
				<div className='App'>
					{/* <ScrollToTopBtn showBelow={250} /> */}
					{initData.underMaintenance === true ? (
						<UnderMaintenance />
					) : (
						<>
							<ScrollToTop />
							<NavBar isGlobal={isGlobal} />
							<Switch>
								{protectedRoutesWithProps}
								{protectedRoutes}
								{routes}
								<Redirect to='/notFound' />
							</Switch>
							<Footer isGlobal={isGlobal} />
						</>
					)}
				</div>
			</IdleTimerProvider>
		</MainContext.Provider>
	);
}

export default App;
