import { useEffect, useState } from 'react';
import http from '../services/httpService';
import socket from '../services/socketService';
import useIsMounted from './useIsMounted';

export default function useUserData() {
	const [userData, setUserData] = useState({});
	const isMounted = useIsMounted();
	useEffect(() => {
		async function getUserData() {
			try {
				if (!http.getCurrentUser()) return;
				const { data } = await http.getMe();
				if (isMounted()) {
					setUserData(data);
					console.log('Calling socket');
					socket.submitSocket(
						data._id,
						data.email,
						data.username,
						data.wallet
					);
				}
			} catch (error) {
				console.log('error :>> ', error);
				http.logout();
			}
		}

		getUserData();
	}, [isMounted]);

	return [userData, setUserData];
}
