import React, { useContext, useState } from 'react';
import MainContext from '../../../context/mainContext';
import { paginate } from '../../../utils/paginate';
import config from '../../../config';
import Divider from './../../divider';
import Image from './../../common/image';
import Pagination from '../../common/pagination';
import ListGroup from '../../common/listGroup';
import NoData from '../ingredients/noData';
import BGTable from './../ingredients/bgTable';

function BGWallet({ tab }) {
	const transactionTypes = [
		{
			_id: 0,
			content: 'All Transactions',
			fn: (txs) => txs,
		},
		{
			_id: 1,
			content: 'TRX Transactions',
			fn: (txs) => txs.filter((u) => u.token === 'trx'),
		},
		{
			_id: 2,
			content: 'BCN Transactions',
			fn: (txs) => txs.filter((u) => u.token === 'bcn'),
		},
		{
			_id: 3,
			content: 'TIM Transactions',
			fn: (txs) => txs.filter((u) => u.token === 'tim'),
		},
		{
			_id: 4,
			content: 'Deposit Transactions',
			fn: (txs) => txs.filter((u) => u.type === 'deposit'),
		},
		{
			_id: 5,
			content: 'Withdraw Transactions',
			fn: (txs) => txs.filter((u) => u.type === 'withdraw'),
		},
	];
	const { userData } = useContext(MainContext);
	const [selectedTransactions, setSelectedTransactions] = useState(
		transactionTypes[0]
	);
	const [currentPage, setCurrentPage] = useState(1);

	const { trx, bcn, tim } = userData;
	const txHistory = [
		...trx.withdrawTransactions.map((t) => ({
			...t,
			...{ token: 'trx', type: 'withdraw' },
		})),
		...trx.depositTransactions.map((t) => ({
			...t,
			...{ token: 'trx', type: 'deposit' },
		})),
		...bcn.withdrawTransactions.map((t) => ({
			...t,
			...{ token: 'bcn', type: 'withdraw' },
		})),
		...bcn.depositTransactions.map((t) => ({
			...t,
			...{ token: 'bcn', type: 'deposit' },
		})),
		...tim.withdrawTransactions.map((t) => ({
			...t,
			...{ token: 'tim', type: 'withdraw' },
		})),
		...tim.depositTransactions.map((t) => ({
			...t,
			...{ token: 'tim', type: 'deposit' },
		})),
	].filter(Boolean);

	const sorted = txHistory.sort((a, b) => (a.date > b.date ? -1 : 1));
	for (const i in sorted) sorted[i].no = Number(i) + 1;
	const filteredTransactions = selectedTransactions.fn(txHistory);

	const data = paginate(
		filteredTransactions,
		currentPage,
		config.tablePageSize
	);

	const handleTypeSelect = (transactionType) => {
		setSelectedTransactions(transactionType);
		setCurrentPage(1);
	};

	return (
		<>
			<Divider />
			<div className='row my-4'>
				<div className='col text-center'>
					<h4 className='fs-1'>BG WALLET</h4>
				</div>
			</div>
			<div className='row my-4'>
				<div className='offset-md-3 col-md-2 col-4 text-center'>
					<div className='d-flex justify-content-center align-items-center'>
						<Image
							className='me-2'
							fileName={'icons/coins/trx.png'}
							height={'30px'}
						/>
						<span className='fs-4 '>
							{userData.trx.available / 1e6} TRX
						</span>
					</div>
				</div>
				<div className='col-md-2 col-4 text-center'>
					<div className='d-flex justify-content-center align-items-center'>
						<Image
							className='me-2'
							fileName={'icons/coins/bcn.png'}
							height={'30px'}
						/>
						<span className='fs-4'>
							{userData.bcn.available / 1e6} BCN
						</span>
					</div>
				</div>
				<div className='col-md-2 col-4 text-center align-items-center'>
					<div className='d-flex justify-content-center align-items-center'>
						<Image
							className='me-2'
							fileName={'icons/coins/tim.png'}
							height={'30px'}
						/>
						<span className='fs-4'>
							{userData.tim.available / 1e6} TIM
						</span>
					</div>
				</div>
			</div>
			{txHistory.length > 0 ? (
				<div className='row my-4'>
					<div className='col-sm-2 mb-3'>
						<h4 className='text-center mb-2'>Filters</h4>
						<ListGroup
							customClass='myListGroupItem'
							activeClass='myListGroupItemActive'
							items={transactionTypes}
							selectedItem={selectedTransactions}
							onItemSelect={handleTypeSelect}
							textProperty='content'
						/>
					</div>
					<div className='col'>
						<BGTable data={data} hashColor='#87c8ff' />
						<Pagination
							customClass='mt-1 myPagination'
							activeClass='myActive'
							totalCount={filteredTransactions.length}
							pageSize={config.tablePageSize}
							currentPage={currentPage}
							onPageChange={setCurrentPage}
						/>
					</div>
				</div>
			) : (
				<NoData />
			)}
			<Divider file='01' />
		</>
	);
}

export default BGWallet;
