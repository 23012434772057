import React from 'react';
import { Link } from 'react-router-dom';
import Image from './common/image';

const ComingSoon = () => {
	return (
		<main className='container'>
			<div className='row my-5'>
				<div className='col-md-8 offset-md-2 col text-center'>
					<Image className='w-50 py-4' fileName={'comingSoon.png'} />

					<Link className='nav-link' to='/'>
						<button type='button' className='rectBtn'>
							Take me back home!
						</button>
					</Link>
				</div>
			</div>
		</main>
	);
};

export default ComingSoon;
