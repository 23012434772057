import React, { Fragment } from 'react';
import HeaderImage from './../ingredients/headerImage';

const TermsAndConditions = ({ tab }) => {
	return (
		<Fragment>
			<HeaderImage fileName={`headers/${tab.id}.jpg`} />
			<div className='row'>
				<div className='col-12'>
					<h1 className='text-center'>Terms and Conditions</h1>
					<p className='mb-4'>
						<b>
							IMPORTANT - PLEASE READ THESE TERMS AND CONDITIONS
							CAREFULLY. BY ACCESSING THIS WEBSITE AND/OR USING
							THE ONLINE SERVICES, YOU AGREE TO BE BOUND BY THE
							FOLLOWING TERMS AND CONDITIONS. IF YOU DO NOT ACCEPT
							ANY OF THESE TERMS OR CONDITIONS, YOU MUST
							IMMEDIATELY DISCONTINUE YOUR ACCESS OF THIS WEBSITE
							AND/OR USE OF THE ONLINE SERVICES.
						</b>
					</p>
					<h3 className='mt-5'>1. Copyright and Trademark Notices</h3>
					<p>
						Except as otherwise expressly stated herein, the
						copyright and all other intellectual property in the
						contents of this website (including, but not limited to,
						all design, text, sound recordings, images or links) are
						the property of BemChain & MMP and/or its holding
						company and/or its subsidiaries and/or the subsidiaries
						of its holding company (together the "MMP ecosystem").
					</p>
					<p>
						As such, they may not be reproduced, transmitted,
						published, performed, broadcast, stored, adapted,
						distributed, displayed, licensed, altered, hyperlinked
						or otherwise used in whole or in part in any manner
						without the prior written consent of the MMP Group. Save
						and except with the MMP Group's prior written consent,
						you may not insert a hyperlink to this website or any
						part thereof on any other website or "mirror" or frame
						this website, any part thereof, or any information or
						materials contained in this website on any other server,
						website or webpage.
					</p>
					<h3 className='mt-5'>2. Disclaimer</h3>
					<p>
						The information and materials contained in or accessed
						through this website are provided on an "as is" and "as
						available" basis and are of a general nature which have
						not been verified, considered or assessed by any member
						of the MMP Group in relation to the making of any
						specific investment, business, financial or commercial
						decision. Such information and materials are provided
						for general information only and you should seek
						professional advice at all times and obtain independent
						verification of the information and materials contained
						herein before making any decision based on any such
						information or materials.
					</p>

					<p>
						The MMP Group does not warrant the truth, accuracy,
						adequacy, completeness or reasonableness of the
						information and materials contained in or accessed
						through this website and expressly disclaims liability
						for any errors in, or omissions from, such information
						and materials. No warranty of any kind, implied, express
						or statutory (including but not limited to, warranties
						of title, merchantability, satisfactory quality,
						non-infringement of third-party intellectual property
						rights, fitness for a particular purpose and freedom
						from computer virus and other malicious code), is given
						in conjunction with such information and materials, or
						this website in general.
					</p>

					<p>
						Under no circumstances shall the MMP Group be liable
						regardless of the form of action for any failure of
						performance, system, server or connection failure,
						error, omission, interruption, breach of security,
						computer virus, malicious code, corruption, delay in
						operation or transmission, transmission error or
						unavailability of access in connection with your
						accessing this website and/or using the online services
						even if the MMP Group had been advised as to the
						possibility.
					</p>

					<p>
						In no event shall the MMP Group be liable to you or any
						other party for any damages, losses, expenses or costs
						whatsoever (including without limitation, any direct,
						indirect, special, incidental or consequential damages,
						loss of profits or loss opportunity) arising in
						connection with your use of this website, or reliance on
						any information, materials or online services provided
						at this website, regardless of the form of action and
						even if the MMP Group had been advised as to the
						possibility of such damages.
					</p>
					<h3 className='mt-5'>
						3. Hyperlinks and Third-party Sites
					</h3>
					<p>
						For your convenience, the MMP Group may include
						hyperlinks to websites on the Internet that are owned or
						operated by third parties. Such linked websites are not
						under the control of the MMP Group and the MMP Group
						cannot accept responsibility for the contents of or the
						consequences of accessing any linked website or any link
						contained in a linked website. Furthermore, the
						hyperlinks provided in this website shall not be
						considered or construed as an endorsement or
						verification of such linked websites or the contents
						therein by the MMP Group. You agree that your access to
						and/or use of such linked websites is entirely at your
						own risk and subject to the Terms and Conditions and/or
						use contained therein.
					</p>
					<h3 className='mt-5'>4. Indemnity</h3>
					<p>
						You hereby agree to indemnify the MMP Group harmless
						against all damages, losses, expenses and costs
						(including legal costs) suffered or incurred by the MMP
						Group in connection with or arising from (1) your access
						of this website and/or use of the online services, or
						(2) any other party's access of this website and/or use
						of the online services using your user id and/or login
						password, or (3) your breach of any of these Terms and
						Conditions, or (4) any other party's breach of any of
						these Terms and Conditions where such party was able to
						access this website and/or use the online services by
						using your user id and/or login password.
					</p>
					<h3 className='mt-5'>5. Miscellaneous</h3>
					<p>
						The information and materials contained in or accessed
						through this website shall not be considered or
						construed as an offer or solicitation to sell, buy,
						give, take, issue, allot or transfer, or as the giving
						of any advice in respect of shares, stocks, bonds,
						notes, interests, unit trusts, mutual funds or other
						securities, investments, loans, advances, credits or
						deposits in any jurisdiction.
					</p>
					<p>
						The information and materials herein are subject to
						change (including, without limitation, modification,
						deletion or replacement thereof) without notice.
					</p>
					<p>
						The MMP Group may terminate your access to this website
						and/or your use of the online services at any time
						without notice and without assigning any reason
						therefore.
					</p>
					<h3 className='mt-5'>6. Governing Law and Jurisdiction</h3>
					<p>
						Nothing herein shall be construed as a representation by
						the MMP Group that the Information and materials
						contained in or accessed through this website is
						appropriate or available for use in your jurisdiction.
						By accessing this website and/or using the online
						services, you agree that such access and/or use, as well
						as these Terms and Conditions shall be governed by, and
						construed in accordance with the law of the land.
					</p>
					<p>
						Any dispute arising out of or in connection with this
						contract, including any question regarding its
						existence, validity or termination, shall be referred to
						and finally resolved by arbitration for the time being
						in force, which rules are deemed to be incorporated by
						reference in this clause.
					</p>
					<p>
						The Tribunal shall consist of three (3) arbitrator(s).
						The language of the arbitration shall be in English.
					</p>
				</div>
			</div>
		</Fragment>
	);
};

export default TermsAndConditions;
