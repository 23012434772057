import React, { useContext, useState } from 'react';
import config from '../../../../config';
import MainContext from '../../../../context/mainContext';
import { paginate } from '../../../../utils/paginate';
import Divider from '../../../divider';
import HistoryTable from '../../ingredients/historyTable';
import Pagination from '../../../common/pagination';
import ListGroup from '../../../common/listGroup';
import NoData from './../../ingredients/noData';

const DepositHistory = ({ tab }) => {
	const transactionTypes = [
		{
			_id: 0,
			content: 'All Transactions',
			fn: (txs) => txs,
		},
		{
			_id: 1,
			content: 'TRX Transactions',
			fn: (txs) => txs.filter((u) => u.token === 'trx'),
		},
		{
			_id: 2,
			content: 'BCN Transactions',
			fn: (txs) => txs.filter((u) => u.token === 'bcn'),
		},
		{
			_id: 3,
			content: 'TIM Transactions',
			fn: (txs) => txs.filter((u) => u.token === 'tim'),
		},
	];
	const { userData } = useContext(MainContext);
	const [selectedTransactions, setSelectedTransactions] = useState(
		transactionTypes[0]
	);
	const [currentPage, setCurrentPage] = useState(1);
	const { paymentHistory } = userData;

	const sorted = paymentHistory.sort((a, b) => (a.date > b.date ? -1 : 1));
	for (const i in sorted) sorted[i].no = Number(i) + 1;
	const filteredTransactions = selectedTransactions.fn(paymentHistory);

	const data = paginate(
		filteredTransactions,
		currentPage,
		config.tablePageSize
	);

	const handleTypeSelect = (transactionType) => {
		setSelectedTransactions(transactionType);
		setCurrentPage(1);
	};

	return (
		<>
			<Divider />
			{paymentHistory.length > 0 ? (
				<>
					<div className='row mb-4'>
						<div className='col text-center'>
							<h4 className='fs-1'>Deposit History</h4>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-2 mb-3'>
							<h4 className='text-center mb-2'>Filters</h4>
							<ListGroup
								customClass='myListGroupItem'
								activeClass='myListGroupItemActive'
								items={transactionTypes}
								selectedItem={selectedTransactions}
								onItemSelect={handleTypeSelect}
								textProperty='content'
							/>
						</div>
						<div className='col'>
							<HistoryTable data={data} hashColor='#87c8ff' />
							<Pagination
								customClass='mt-1 myPagination'
								activeClass='myActive'
								totalCount={filteredTransactions.length}
								pageSize={config.tablePageSize}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</div>
					</div>
				</>
			) : (
				<NoData />
			)}
			<Divider file='07' className='mt-5' />
		</>
	);
};

export default DepositHistory;
