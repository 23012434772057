import React, { useEffect, useRef } from 'react';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.bundle.min.js';

function ToolTip({ children, title, placement, customClass = '' }) {
	let tooltip;
	const tooltipRef = useRef();
	const config = {
		customClass,
		title,
		placement: placement || 'top',
		trigger: 'hover',
		delay: { show: 0, hide: 0 },
		animation: false,
	};
	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		tooltip = new Tooltip(tooltipRef.current, config);
	});

	const handleClick = () => {
		tooltip.hide();
		if (children.props.onClick) children.props.onClick();
	};

	return (
		<>
			{React.cloneElement(children, {
				ref: tooltipRef,
				onClick: handleClick,
			})}
		</>
	);
}

export default ToolTip;
