import about from './about';
import advantages from './advantages';
import crowdfunding from './crowdfunding';
import marketMaker from './marketMaker';
import mmpworld from './mmpworld';
import bcn from './bcn';
import goals from './goals';
import risks from './risk';

const articles = [
	about,
	advantages,
	crowdfunding,
	marketMaker,
	mmpworld,
	bcn,
	goals,
	risks,
];

export default articles;
