import ProjectsCarousel from './projectsCarousel';
const about = {
	path: '/about',
	name: 'About',
	title: 'About BEM Foundation',
	content: (
		<div className='container py-5'>
			<div className='row'>
				<div className='col-12'>
					<h2>The Best Economic Money Foundation</h2>
					<p>
						BEM foundation was established in 2020 as an
						entrepreneur in the blockchain field to develop this
						industry globally. <br />
						The foundation consists of experts in cryptocurrency,
						blockchain programming, and marketing. Each member of
						the foundation has several years of experience in their
						field. Inspired by blockchain principles
						(decentralization) and to increase the security level of
						projects, these individuals' identities remain
						disclosed. BEM foundation was established to create a
						blockchain-based ecosystem and job opportunities in
						blockchain. <br />
						We explore market needs and create projects so that all
						people can quickly understand blockchain projects and
						start working on them.
					</p>
					<h2>History</h2>
					<p>
						BEM Foundation initialized its work by creating and
						offering a token. The first project of the BEM
						Foundation was selling tokens through ICO. Then we
						provided a staking project to generate more financial
						turnover for the tokens. <br />6 months after the launch
						of the first token and after completing the ICO and
						staking project, BemChain launched. It was the Launchpad
						for the BEM foundation. It was a multipurpose project
						which had a very significant role in the development of
						the foundation. Managers of the BEM Foundation initially
						managed and guided the projects in a decentralized
						manner. Still, in 2021 they established their first
						official office in Armenia. <br />
						With the development of other projects and the number of
						BemChain active users reaching over 25 thousand, the
						foundation decided to establish another official office
						in Istanbul, Turkey. The country is ranked 5th in
						trading and reception of the crypto market in the world
						ranking. This way, the foundation would place itself in
						a more competitive space would expand its work globally.{' '}
						<br />
						The next goal of the BEM Foundation is to expand its
						support offices in Dubai until 2024, which will be the
						world's blockchain hub.
					</p>

					<h2>Our Core Values</h2>
					<p>
						Our ecosystem is honored to complete and reveal its
						projects and offer them to market through MMP.WORLD.
					</p>
					<ProjectsCarousel />
				</div>
			</div>
		</div>
	),
};

export default about;
