import React from 'react';
import Joi from 'joi-browser';
import Form from '../../../common/form';
import Spinner from '../../../common/spinner';

class Disable2faForm extends Form {
	state = {
		data: {
			token: '',
			password: '',
		},
		errors: {},
	};

	schema = {
		token: Joi.string()
			.length(6)
			.regex(/^[0-9]*$/, '"Only digits are allowed"')
			.required()
			.label('Verify Code'),
		password: Joi.string().required().label('Password'),
	};

	doSubmit = () => this.props.onSubmit(this.state.data);

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		const { isLoading } = this.props;
		return (
			<form
				className='text-start'
				autoComplete='off'
				onSubmit={this.handleSubmit}>
				<div className='mb-3'>
					{this.renderInput({
						name: 'token',
						label: 'Enter the authentication code',
						type: 'text',
						formText:
							'For changing the setting, please verify the authentication code.',
					})}
				</div>
				<div className='mb-3'>
					{this.renderInputGroupBtnEnd({
						name: 'password',
						label: 'Password',
						type: 'password',
						btnClass: `rectBtn`,
						btnText: <i className='fas fa-eye-slash'></i>,
						btnHandler: this.handleEyeClicked,
					})}
				</div>
				<div className='text-center'>
					{this.renderButton(
						<span>Disable 2FA</span>,
						`rectBtn`,
						isLoading && <Spinner content={'Submitting...'} />
					)}
				</div>
			</form>
		);
	}
}

export default Disable2faForm;
