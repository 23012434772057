import React, { useContext, useState } from 'react';
import MainContext from '../context/mainContext';
import useDocumentTitle from '../hooks/useDocumentTitle';
import Preloader from './common/preloader';
import tabs from './dashboard/tabs.json';
import SideBar from './dashboard/sideBar';
import MainPanel from './dashboard/mainPanel';
import Header from './dashboard/header';

const Dashboard = (props) => {
	useDocumentTitle('BCN GARDEN - User Dashboard');
	const { userData, initData } = useContext(MainContext);
	const [showOthers, setShowOthers] = useState(true);

	if (!initData.emailGap || !userData._id) return <Preloader />;

	const handleTogglerClicked = ({ currentTarget: btn }) => {
		const classes = [...btn.classList];
		setShowOthers(classes.includes('collapsed'));
	};

	const pathArray = props.location.pathname.split('/');
	const currentTab = pathArray[pathArray.length - 1];

	return (
		<div className='dashboardPage'>
			<Header onTogglerClicked={handleTogglerClicked} />
			<div className='container-fluid'>
				<div className='row pt-3'>
					<div className='col-md-4 col-lg-2'>
						<SideBar
							{...props}
							tabs={tabs}
							handleShowOthers={setShowOthers}
							activePath={currentTab}
						/>
					</div>
					<div
						className={`col-md-8 col-lg-10 ${
							showOthers ? '' : 'd-none'
						}`}>
						<MainPanel />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
