import Image from '../common/image';

const advantages = {
	path: '/advantages',
	name: 'Advantages',
	title: 'MMP Advantages',
	content: (
		<div className='container py-5'>
			<div className='row'>
				<div className='col-12'>
					<div id='safe' className='row align-items-center mb-4'>
						<div className='col-sm-3 text-center mb-5'>
							<Image
								height={'120px'}
								fileName={`home/icons/safe.svg`}
							/>
							<h2 className='text-blue'>
								<b>SAFE AND STABLE</b>
							</h2>
						</div>

						<div className='col-sm-9'>
							<p>
								Whether you're an experienced trader or a
								cryptocurrency newcomer, MMP Global Market Maker
								Plan empowers you to chart your financial path.
								MMP WORLD offers:
							</p>
							<ul>
								<li>
									Open-source smart contract technology is
									planned in the MMP WORLD project for safer,
									more stable, and smooth financial
									transactions.
								</li>
								<li>
									Hot and cold wallets, offline private key
									storage, and risk isolation.
								</li>
								<li>
									Email verification, Google verification, and
									remote login risk reminders are provided.
								</li>
								<li>
									Withdrawal automation system with manual
									security check to protect user's money.
								</li>
							</ul>

							<p>
								MMP Global Market Maker Plan offers the best
								financial investment solutions for institutions
								and individuals of all sizes. Everything from
								the cryptocurrency market to personal account
								management and lending is offered, allowing you
								to reach your investment goals quickly and
								confidently.
							</p>
						</div>
					</div>

					<div id='fast' className='row align-items-center mb-4'>
						<div className='col-sm-3 text-center mb-5 d-block d-sm-none'>
							<Image
								height={'120px'}
								fileName={`home/icons/speed.svg`}
							/>
							<h2 className='text-blue'>
								<b>FAST AND EFFICEIENT</b>
							</h2>
						</div>

						<div className='col-sm-9'>
							<p>
								You can make deposits to the project accounts
								and start your investment every day and at any
								hour of the day (24/7). Likewise, you can
								withdraw from project accounts any day and at
								any hour (24/7). The whole system works
								automatically and without interruption.
							</p>
						</div>

						<div className='col-sm-3 text-center mb-5 d-none d-sm-block'>
							<Image
								height={'120px'}
								fileName={`home/icons/speed.svg`}
							/>
							<h2 className='text-blue'>
								<b>FAST AND EFFICEIENT</b>
							</h2>
						</div>
					</div>

					<div id='features' className='row align-items-center mb-4'>
						<div className='col-sm-3 text-center mb-5'>
							<Image
								height={'120px'}
								fileName={`home/icons/features.svg`}
							/>
							<h2 className='text-blue'>
								<b>PERFECT FEATURES</b>
							</h2>
						</div>

						<div className='col-sm-9'>
							<p>
								The MMP WORLD project consists of several
								projects. Investment project, liquidity pool
								project, lending project, NFT collection
								project, store project, and stock market
								project, each of these projects contributes a
								lot to the active community in the field of
								cryptocurrency and is very influential in the
								development of the project. When all these
								projects are activated, the project will
								certainly have a wonderful future ahead.
							</p>
						</div>
					</div>

					<div id='powerfull' className='row align-items-center mb-4'>
						<div className='col-sm-3 text-center mb-5 d-block d-sm-none'>
							<Image
								height={'120px'}
								fileName={`home/icons/powerfull.svg`}
							/>
							<h2 className='text-blue'>
								<b>POWERFULL OPERATING SYSTEM</b>
							</h2>
						</div>

						<div className='col-sm-9'>
							<p>
								MMP Global Market Maker Plan has a very powerful
								support system and a very powerful operating
								system. The decentralized financial system of
								the MMP WORLD project brings financial security
								to investors. It allows the MMP WORLD project to
								continue its activity independently for many
								years. The MMP WORLD project support system is
								constantly updating and improving various parts
								of the project so that the project always
								provides its financial investors with quality
								services.
							</p>
						</div>

						<div className='col-sm-3 text-center mb-5 d-none d-sm-block'>
							<Image
								height={'120px'}
								fileName={`home/icons/powerfull.svg`}
							/>
							<h2 className='text-blue'>
								<b>POWERFULL OPERATING SYSTEM</b>
							</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	),
};

export default advantages;
