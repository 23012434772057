import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import MainContext from '../../../context/mainContext';
import useIsLoaded from '../../../hooks/useIsLoaded';
import animationData from '../../../lottie/dog.json';
import XP from '../../../utils/xp';
import Image from '../../common/image';
import Lottie from '../../common/lottie';
import CopyBtn from './../../common/copyBtn';
import ToolTip from './../../common/toolTip';
import Divider from './../../divider';

const Home = () => {
	const isLoaded = useIsLoaded();
	const { userData, initData } = useContext(MainContext);
	const { totalXP, sprites, uid } = userData;
	const { level, barText, barScale } = XP.getLevel(totalXP);
	const url = window.location.href;
	const address = url.split(/[?]/)[0];
	const referralLink = `${address.replace(
		'dashboard/home',
		'register'
	)}?ref=${uid}`;
	const gardenValue = userData.sprites
		.filter((s) => s.planted && s.alive)
		.map((s) => initData.charsDef[s.name].seedCost)
		.reduce((a, b) => a + b, 0);

	return (
		<>
			<Divider />
			<div className='row d-none d-md-block'>
				<div className='col-sm-12 text-center'>
					<h2>BCN Garden Dashboard</h2>
					<Lottie
						isLoaded={isLoaded}
						animationData={animationData}
						className='w-100'
						height='50px'
					/>
				</div>
			</div>
			<div className='row align-items-center'>
				<div className='col-md-9'>
					<div className='row'>
						<div className='col-12 text-center'>
							<div className='d-flex justify-content-center'>
								<div
									style={{
										width: '250px',
									}}>
									<div className='fs-5'>Level: {level}</div>
									<div
										className='progress'
										style={{
											width: '250px',
											height: '20px',
										}}
										role='progressbar'
										aria-label='XP Progress Bar'
										aria-valuenow={String(barScale * 100)}
										aria-valuemin='0'
										aria-valuemax='100'>
										<div
											className='progress-bar overflow-visible bg-warning text-dark'
											style={{
												width: `${barScale * 100}%`,
											}}>
											{`${(barScale * 100).toFixed(1)}%`}
										</div>
									</div>
									<div className='text-end'>{barText}</div>
								</div>
							</div>

							<Link className='rectBtn mt-3' to='/garden'>
								<span>My Garden</span>
							</Link>
							<Link className='rectBtn ms-4 mt-3' to='/light'>
								<span>Garden (Light)</span>
							</Link>
						</div>
					</div>
					<Divider file='08' className='my-4 mx-0 mx-md-5' full />
					<div className='row'>
						<div className='col-md-4 fs-6 mb-4'>
							<div className='d-flex align-items-center justify-content-center'>
								<Image
									height='20px'
									fileName='leaf.png'
									className='me-2'
								/>
								<div className='fs-3'>Overview</div>
							</div>
							<Divider file='02' className='mx-5 mx-md-0' />
							<div className='text-center'>
								<p>Total XP : {totalXP.toFixed(1)}</p>
								<p>
									Garden Value : {gardenValue} TIM{' '}
									<ToolTip
										title={
											'Total value of your planted, alive seeds'
										}
										placement={'bottom'}>
										<span>
											<i className='fas fa-question-circle'></i>
										</span>
									</ToolTip>{' '}
								</p>
								<p>Total Seeds : {sprites.length}</p>
								<p>
									Total Planted Seeds :{' '}
									{sprites.filter((n) => n.planted).length}
								</p>
							</div>
						</div>
						<div className='col-md-4 fs-6 mb-4'>
							<div className='d-flex align-items-center justify-content-center'>
								<Image
									height='20px'
									fileName='leaf.png'
									className='me-2'
								/>
								<div className='fs-3'>Warehouse</div>
							</div>
							<Divider file='02' className='mx-5 mx-md-0' />
							<div className='text-center'>
								<Image
									className='w-50 mb-2'
									fileName={`buildings/warehouse_${XP.getWarehouseLevel(
										totalXP
									)}.png`}
								/>
								<p>
									Warehouse Level :{' '}
									{XP.getWarehouseLevel(totalXP) + 1}
								</p>
							</div>
						</div>
						<div className='col-md-4 fs-6 mb-4'>
							<div className='d-flex align-items-center justify-content-center'>
								<Image
									height='20px'
									fileName='leaf.png'
									className='me-2'
								/>
								<div className='fs-3'>BG-Wallet</div>
							</div>
							<Divider file='02' className='mx-5 mx-md-0' />
							<div className='d-flex justify-content-center align-items-center mb-3'>
								<Image
									className='me-2'
									fileName={'icons/coins/trx.png'}
									height={'30px'}
								/>
								<span>{userData.trx.available / 1e6} TRX</span>
							</div>
							<div className='d-flex justify-content-center align-items-center mb-3'>
								<Image
									className='me-2'
									fileName={'icons/coins/bcn.png'}
									height={'30px'}
								/>
								<span>{userData.bcn.available / 1e6} BCN</span>
							</div>
							<div className='d-flex justify-content-center align-items-center mb-3'>
								<Image
									className='me-2'
									fileName={'icons/coins/tim.png'}
									height={'30px'}
								/>
								<span>{userData.tim.available / 1e6} TIM</span>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='offset-md-3 col-md-6 col-12 text-center'>
							<div className='cards seedsCard'>
								<div className='card-body'>
									<div className='fs-4 mb-3'>
										My Referral Link:
									</div>
									<div className='text-link-color'>
										<span className=''>{referralLink}</span>
										<CopyBtn content={referralLink} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-md-3 text-center d-none d-md-block'>
					<Image
						className='w-100 bounce-2'
						fileName={'amordad.png'}
					/>
				</div>
			</div>
			<Divider file='07' className='mt-5' />
		</>
	);
};

export default Home;
